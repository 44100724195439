import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import api from '../../provider/api';
import { ROOM_CHAT_MESSAGES_INITIAL_STATE } from '../reducers/chatkitReducer';
import { isNotEmpty } from '../../provider/UtilityFunctions';
import { RESOURCE_MESSAGING } from '../../provider/restProvider';
import { getUser } from '../../provider/authProvider';
import { genericApiErrorHandler } from './genericGetDataAction';
import types from './types';

export function loadChatRooms(notify) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const candidates = get(state.chatkit, 'candidates', {});
      const archived = get(state.chatkit, 'archived', false);

      dispatch({ type: types.chatkit.CHAT_ROOMS_LOADING });
      const chatRoomsResponse = await api.chat.getChatRooms(archived);

      if (chatRoomsResponse.status === 200 && chatRoomsResponse.data.isSuccess) {
        const roomsArr = chatRoomsResponse.data.model;
        const roomsMap = {};
        // Loop for each item and get Candidate details
        const newCandidateIds = [];

        for (let i = 0; i < roomsArr.length; i++) {
          const room = roomsArr[i];
          roomsMap[room.id] = {
            ...room,
            ...ROOM_CHAT_MESSAGES_INITIAL_STATE,
          };
          const candidateId = room.candidateId;
          if (isNotEmpty(candidateId) && candidates[candidateId] === undefined) {
            newCandidateIds.push(candidateId);
          }
        }
        if (newCandidateIds.length > 0) {
          const candidateResponse = await api.candidates.getList({
            pageSize: 1000,
            pageNumber: 1,
            ids: newCandidateIds,
          });
          if (candidateResponse.status === 200 && candidateResponse.data.isSuccess) {
            dispatch({
              type: types.chatkit.CHAT_ROOMS_CANDIDATES_UPDATE,
              data: keyBy(candidateResponse.data.model, 'id'),
            });
          }
        }
        dispatch({ type: types.chatkit.CHAT_ROOMS_LOADED, data: roomsMap });
      }
    } catch (err) {
      genericApiErrorHandler("Couldn't load chats!", types.chatkit.CHAT_ROOMS_ERROR, err, dispatch, notify);
    }
  };
}

export function updateChatCandidates(candidateId, notify) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const candidates = get(state.chatkit, 'candidates', {});

      if (isNotEmpty(candidateId) && candidates[candidateId] === undefined) {
        const candidateResponse = await api.candidates.getCandidate(candidateId);
        if (candidateResponse.status === 200 && candidateResponse.data.isSuccess) {
          const newCandidates = {};
          newCandidates[candidateId] = candidateResponse.data.model;
          dispatch({
            type: types.chatkit.CHAT_ROOMS_CANDIDATES_UPDATE,
            data: newCandidates,
          });
        }
      }
    } catch (err) {
      console.error('updateChatCandidates errored', err);
      if (notify) notify('Sorry, API call failed. Try again!', { type: 'warning' });
    }
  };
}

export function selectChatRoom(selectedRoomId, notify) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.chatkit.CHAT_ROOM_SELECT, selectedRoomId });
      await api.chat.resetUnReadMessages(selectedRoomId);
    } catch (err) {
      console.error('selectChatRoom errored', err);
      if (notify) notify('Sorry, API call failed. Try again!', { type: 'warning' });
    }
  };
}

export function toggleArchivedState() {
  return { type: types.chatkit.CHAT_ROOM_ARCHIVE };
}

export function fetchChatRoomMessages(roomId, notify) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      dispatch({ type: types.chatkit.CHAT_ROOM_LOADING });
      const chatRoomResponse = await api.chat.getChatRoomMessages(roomId);
      if (chatRoomResponse.status === 200 && chatRoomResponse.data.isSuccess) {
        const room = chatRoomResponse.data.model;

        const fieldManagers = get(state.chatkit, 'fieldManagers', {});
        await fetchRoomFieldManagerProfiles(room, fieldManagers, dispatch, notify);

        dispatch({ type: types.chatkit.CHAT_ROOM_LOADED, data: room });
      }
    } catch (err) {
      genericApiErrorHandler("Couldn't load messages!", types.chatkit.CHAT_ROOM_ERROR, err, dispatch, notify);
    }
  };
}

export function archiveChatRoomThenRefetchRooms(room, notify) {
  return async (dispatch) => {
    try {
      const response = room.isArchived
        ? await api.chat.unarchiveChatRoom(room.id)
        : await api.chat.archiveChatRoom(room.id);
      if (response.status === 200) {
        dispatch(loadChatRooms(room.isArchived));
      }
    } catch (err) {
      console.error('archiveChatRoomThenRefetchRooms errored', err);
      if (notify) notify('Sorry, API call failed. Try again!', { type: 'warning' });
    }
  };
}

async function fetchRoomFieldManagerProfiles(room, fieldManagers, dispatch, notify) {
  try {
    const newFieldManagers = {};

    //Collect new Field Managers Id. For each one, profile will be loaded
    room.chatMessages
      .map((msg) => msg.fieldManagerId)
      .filter((fieldManagerId) => {
        return (
          fieldManagerId > 0 &&
          fieldManagers[fieldManagerId] === undefined &&
          newFieldManagers[fieldManagerId] === undefined
        );
      })
      .map((fieldManagerId) => (newFieldManagers[fieldManagerId] = undefined));

    for (const id in newFieldManagers) {
      const response = await api.chat.getFieldManagerProfile(id);
      if (response.status === 200 && response.data.isSuccess) {
        newFieldManagers[id] = response.data.model;
      }
    }

    if (Object.keys(newFieldManagers).length > 0) {
      dispatch({
        type: types.chatkit.CHAT_ROOMS_FIELDMANAGERS_UPDATE,
        data: newFieldManagers,
      });
    }
  } catch (err) {
    genericApiErrorHandler(
      "Couldn't load Field Manager profile!",
      types.chatkit.CHAT_ROOM_ERROR,
      err,
      dispatch,
      notify
    );
  }
}

async function fetchFieldManagerProfile(fieldManagerId, fieldManagers, dispatch, notify) {
  try {
    const newFieldManagers = {};
    if (fieldManagerId > 0 && fieldManagers[fieldManagerId] === undefined) {
      const response = await api.chat.getFieldManagerProfile(fieldManagerId);
      if (response.status === 200 && response.data.isSuccess) {
        newFieldManagers[fieldManagerId] = response.data.model;
        dispatch({
          type: types.chatkit.CHAT_ROOMS_FIELDMANAGERS_UPDATE,
          data: newFieldManagers,
        });
      }
    }
  } catch (err) {
    genericApiErrorHandler(
      "Couldn't load Field Manager profile!",
      types.chatkit.CHAT_ROOM_ERROR,
      err,
      dispatch,
      notify
    );
  }
}

export function receiveMessage(message, notify) {
  return async (dispatch, getState) => {
    const state = getState();
    const roomId = '' + message.chatSessionId;
    let dontUpdateUnReadMessages = false;

    if (state.chatkit.rooms[roomId] === undefined) {
      //Request ChatRoom and Add it to rooms
      const chatRoomResponse = await api.chat.getChatRoomMessages(roomId);

      if (chatRoomResponse.status === 200 && chatRoomResponse.data.isSuccess) {
        const room = chatRoomResponse.data.model;
        delete room['chatMessages'];
        dispatch({
          type: types.chatkit.CHAT_ROOMS_ADD_ROOM,
          room: { ...room, ...ROOM_CHAT_MESSAGES_INITIAL_STATE },
        });
        dontUpdateUnReadMessages = true;
      } else {
        if (notify) notify(`Sorry, Couldn't load Chat Room detail(${message.chatSessionId})!`, { type: 'warning' });
      }
    } else if (window.location.href.includes(RESOURCE_MESSAGING) && roomId === state.chatkit.selectedRoomId) {
      dontUpdateUnReadMessages = true;
      dispatch(selectChatRoom(roomId, notify));
    }

    const user = getUser();
    const myFieldManagerId = user?.fieldManagerId;

    //If Message is my-own message, isEchoMessage becomes true
    const isEchoMessage = message.fieldManagerId && message.fieldManagerId === myFieldManagerId;

    //It is necessary to load profile for first message
    if (message.fieldManagerId) {
      const fieldManagers = get(state.chatkit, 'fieldManagers', {});
      await fetchFieldManagerProfile(message.fieldManagerId, fieldManagers, dispatch, notify);
    }

    const candidates = get(state.chatkit, 'candidates', {});
    if (isNotEmpty(message.candidateId) && candidates[message.candidateId] === undefined) {
      dispatch(updateChatCandidates(message.candidateId, notify));
    }

    dispatch({
      type: types.chatkit.CHAT_ROOM_MESSAGE_RECEIVED,
      message,
      isEchoMessage,
      dontUpdateUnReadMessages,
    });
  };
}
