import { FC, ReactElement, useCallback, ReactNode, ElementType } from 'react';
import { useFormContext } from 'react-hook-form';
import { isFunction } from 'lodash';
import Box from '@mui/material/Box';
import { ListButton, ListButtonProps, SaveButton, Toolbar } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { ReactComponent as CancelIcon } from '../../../assets/Cancel.svg';

export type FooterToolbarProps = {
  cancelButtonProps?: ListButtonProps;
  cancelButtonResource?: string;
  onCancel?: () => void;
  children?: ReactNode;
  CancelButtonElement?: ElementType;
  SaveButtonComponent?: ReactNode;
};

export const FooterToolbar: FC<FooterToolbarProps> = ({
  cancelButtonResource,
  cancelButtonProps,
  onCancel,
  children,
  CancelButtonElement = ListButton,
  SaveButtonComponent = <SaveButton />,
}): ReactElement => {
  const { reset } = useFormContext();

  const handleCancelButtonClick = useCallback(() => {
    reset();
    if (onCancel && isFunction(onCancel)) {
      onCancel();
    }
  }, [reset, onCancel]);

  return (
    <Toolbar>
      <Box sx={commonStyles.footerActionsBox}>
        <Box sx={commonStyles.footerAdditionalActionsBox}>
          {SaveButtonComponent}
          {children}
        </Box>
        <CancelButtonElement
          label="Cancel"
          startIcon={<CancelIcon />}
          onClick={handleCancelButtonClick}
          resource={cancelButtonResource}
          {...cancelButtonProps}
        />
      </Box>
    </Toolbar>
  );
};
