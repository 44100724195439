import * as React from 'react';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { TextInputProps, useInput } from 'react-admin';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';

const SearchInput = (prop) => {
  const { field } = useInput({ source: prop.source });

  const [value, setValue] = React.useState(field.value || '');

  return (
    <span style={{ marginBottom: 5 }}>
      <div>
        <TextField
          variant="outlined"
          // name={props.input.name}
          label={prop.label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    field.onChange(value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={value}
          onChange={(ev) => {
            ev.persist();
            setValue(ev.target.value);
            if (!ev.target.value) {
              field.onChange('');
            }
          }}
          onKeyUp={(e) => {
            e.persist();
            if (e.key === 'Enter') {
              field.onChange(value);
            } else {
              return null;
            }
          }}
        />
      </div>
    </span>
  );
};

const FreeTextSearchInput: React.FC<TextInputProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box display="flex" alignItems="center">
      <SearchInput {...props} />
      <div>
        <Box marginLeft="10px" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} color={grey[600]}>
          <HelpIcon fontSize="medium" />
        </Box>

        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box width="350px" padding="5px">
            <Typography variant="body2" color="textSecondary" align="left">
              Search is case-insensitive <br />
              Combine Candidate/Placement Id(s) etc with text and operators (+, -, *)
              <p />
            </Typography>

            <Typography variant="body2" color="textSecondary" align="center">
              +21361 +Shell -GAS
              <br />
              Excel Can*
            </Typography>

            <Typography component="span" variant="body2" color="textSecondary" align="left">
              The + and — operators are used to indicate that a word must be present or absent in the record. The
              asterisk is appended to the word. Words match if they begin with the word preceding the * operator.
            </Typography>

            <p />

            <Typography component="span" variant="body2" color="textSecondary" align="left">
              Operators
              <ul>
                <li>+ stands for AND</li>
                <li>- stands for NOT</li>
                <li>[no operator] implies OR</li>
                <li>* stands for STARTS WITH (min 3 characters)</li>
              </ul>
            </Typography>
          </Box>
        </Popover>
      </div>
    </Box>
  );
};

export default FreeTextSearchInput;
