import { Form, SelectInput, useListContext } from 'react-admin';
import Box from '@mui/material/Box';
import * as React from 'react';
import { ElementType } from 'react';
import { styles } from '../styles';
import { ALL_2099 } from '../constants';
import { FilterFormTitle } from './FilterFormTitle';

type FormHeaderProps = {
  title: string;
  label: string;
  source: string;
  choices: Array<{ id: string; name: string }>;
  valueSource?: string;
  filterParams?: {
    fieldName?: string;
    fieldValue?: string;
    condition?: string;
  };
  emptyText?: string;
  emptyValue?: string;
  LeftContent?: ElementType;
};

export const FilterForm: React.FC<FormHeaderProps> = ({
  title,
  label,
  source,
  choices,
  valueSource = source,
  filterParams,
  emptyText = 'All',
  emptyValue = ALL_2099,
  LeftContent,
}) => {
  const { filterValues, setFilters } = useListContext();

  const handleChange = (event) => {
    const newFilter = {
      [valueSource]: {
        fieldName: filterParams.fieldName || valueSource,
        fieldValue: filterParams.fieldValue || (event.target.value as string),
        condition: filterParams.condition || '==',
      },
    };
    setFilters({ ...filterValues, ...newFilter });
  };

  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%" alignItems="center">
      <FilterFormTitle>{title}</FilterFormTitle>
      <Form>
        <Box display="flex" alignItems="center">
          {LeftContent && <LeftContent />}
          <SelectInput
            label={label}
            source={source}
            margin="dense"
            choices={choices}
            sx={styles.selectInput}
            defaultValue={filterValues[valueSource]?.fieldValue}
            emptyText={emptyText}
            emptyValue={emptyValue}
            onChange={handleChange}
          />
        </Box>
      </Form>
    </Box>
  );
};
