import {
  Button,
  Datagrid,
  Edit,
  EditProps,
  EmailField,
  Identifier,
  Link,
  ListContextProvider,
  ListView,
  NumberInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useListContext,
  useListController,
  useRecordContext,
} from 'react-admin';
import React, { Fragment } from 'react';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';
import { useFormContext, useWatch } from 'react-hook-form';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';

import AddToContactFavoriteButton from '../Candidate/AddToContactFavoriteButton';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import SendNotificationButton from '../Job/SendNotificationButton';
import AddProspectNotifyWithJobSelectButton from '../Job/AddProspectNotifyWithJobSelectButton';
import { candidateRowStyle } from '../Candidate/CandidateCommon';
import { commonStyles } from '../CommonStyles';
import LinkField from '../LinkField';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';

export interface FavoriteListEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

const CandidateLinkField: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  return (
    <Link to={`/${RESOURCE_CANDIDATES}/${record?.id}`}>
      <TextField source="firstName" sortable={false} />
    </Link>
  );
};

interface RemoveButtonProps {
  onRemove: (id: Identifier) => void;
  label?: string;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove }) => {
  const record = useRecordContext();
  return (
    <Button
      onClick={() => {
        if (record?.id) onRemove(record?.id);
      }}
    >
      <RemoveCircleIcon fontSize="small" />
    </Button>
  );
};

const BulkActionButtons: React.FC = () => {
  const { selectedIds } = useListContext();

  return (
    <Fragment>
      <AddToContactFavoriteButton selectedIds={selectedIds} />
      <SendNotificationButton selectedIds={selectedIds} />
      <AddProspectNotifyWithJobSelectButton selectedIds={selectedIds} />
    </Fragment>
  );
};

const CandidatesList = () => {
  const candidateIds = useWatch({ name: 'candidateIds', defaultValue: [] });
  const { setValue } = useFormContext();

  const controllerProps = useListController({
    resource: RESOURCE_CANDIDATES,
    filter: {
      ids: candidateIds.length > 0 ? candidateIds : [0],
      searchTerm: '',
    },
    perPage: MAX_RECORD_PER_PAGE,
    sort: { field: 'firstName', order: 'ASC' },
  });

  return (
    <ListContextProvider value={controllerProps}>
      <ListView
        sx={{ width: '100%' }}
        title=" "
        empty={
          <Box margin="20px">
            No candidate found. Select candidate(s) fom&nbsp;
            <Link to="/candidates">Candidates page</Link> and, Click &quot;Add To Favorites List&quot; to add a list
          </Box>
        }
      >
        <Datagrid rowClick={false} optimized rowSx={candidateRowStyle} bulkActionButtons={<BulkActionButtons />}>
          <CandidateLinkField source="firstName" />
          <TextField source="lastName" sortable={false} />
          <EmailField source="personalEmail" sortable={false} />
          <EmailField source="workEmail" sortable={false} />
          <LinkField phone source="homePhone">
            <TextField source="homePhone" label="Home Phone" sortable={false} />
          </LinkField>
          <LinkField phone source="mobile">
            <TextField source="mobile" sortable={false} />
          </LinkField>
          <TextField source="availabilityDate" label="Availability" sortable={false} />
          <TextField source="availabilityDateLastUpdated" label="Avail Last Updated" sortable={false} />
          <RemoveButton
            label="Actions"
            onRemove={(id) => {
              setValue(
                'candidateIds',
                candidateIds.filter((itemId) => itemId !== id)
              );
            }}
          />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

const FavoriteListEditTitle = () => {
  const record = useRecordContext();
  return <span>Favorite List {record ? `${record.name}` : ''}</span>;
};

export const FavoriteListEdit: React.FC<FavoriteListEditProps> = ({ syncWithLocation, ...props }) => {
  return (
    <Edit
      {...props}
      title={syncWithLocation === undefined ? <FavoriteListEditTitle /> : ' '}
      actions={false}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<FooterToolbar />} warnWhenUnsavedChanges sx={commonStyles.flexVertical}>
        <Box sx={commonStyles.flexBox}>
          <NumberInput source="id" label="ID" sx={commonStyles.flexBoxItem} readOnly />
          <TextInput source="name" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="fieldManagerCreatedName" label="Created by" disabled sx={commonStyles.flexBoxItem} />
          <TextInput source="fieldManagerUpdatedName" label="Updated by" disabled sx={commonStyles.flexBoxItem} />
          <TextInput source="creationDate" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="lastModifiedDate" sx={commonStyles.flexBoxItem} disabled />
        </Box>
        <CandidatesList />
      </SimpleForm>
    </Edit>
  );
};
