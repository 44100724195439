import types from '../actions/types';

const INITIAL_STATE = {
  // <Resource : object[]>
};

const basketReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return { ...INITIAL_STATE };
  }

  switch (action.type) {
    case types.basket.BASKET_CLEAR: {
      return {
        ...state,
        [action.resource]: [],
      };
    }
    case types.basket.BASKET_ADD_ITEMS: {
      if (state[action.resource]) {
        const newData = [...state[action.resource]];
        const isExist = (id) => Boolean(newData.find((item) => item.id === id));
        action.data.forEach((item) => {
          if (!isExist(item.id)) {
            newData.push(item);
          }
        });
        return {
          ...state,
          [action.resource]: newData,
        };
      } else {
        return {
          ...state,
          [action.resource]: action.data,
        };
      }
    }
    case types.basket.BASKET_REMOVE_ITEMS: {
      if (state[action.resource]) {
        return {
          ...state,
          [action.resource]: state[action.resource].filter((item) => !action.ids.includes(item.id)),
        };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
};

export default basketReducer;
