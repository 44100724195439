export const styles = {
  selectInput: {
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  emptyResultContainer: {
    margin: '20px',
  },
};
