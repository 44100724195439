import {
  AutocompleteInput,
  Edit,
  EditProps,
  email,
  required,
  SimpleForm,
  TextInput,
  useGetList,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { commonStyles } from '../CommonStyles';
import CustomAutoCompleteLookup from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import {
  RESOURCE_CLIENT_CONTACTS,
  RESOURCE_CLIENTS,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
  RESOURCE_PROJECTS,
} from '../../provider/restProvider';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';

export const ClientContactEdit: React.FC<EditProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();

  const { data } = useGetOne(RESOURCE_CLIENT_CONTACTS, {
    id: id || record?.id,
  });

  const location = useLocation();
  const parsedSearch = qs.parse(location.search);
  const projectId = parsedSearch.projectId;

  const [country, setCountry] = useState(get(data, 'addresses[0].country', undefined) || 'NoCountry');
  const [state, setState] = useState(get(data, 'addresses[0].state', undefined) || 'NoState');

  const redirectResource = useMemo(() => {
    return projectId ? RESOURCE_PROJECTS : RESOURCE_CLIENTS;
  }, [projectId]);

  const { data: countryData = [], isLoading } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  useEffect(() => {
    if (data) {
      setCountry(get(data, 'addresses[0].country', undefined) || 'NoCountry');
      setState(get(data, 'addresses[0].state', undefined) || 'NoState');
    }
  }, [data]);

  return (
    <Edit {...props} title="Edit Contact" actions={false} mutationMode="pessimistic" redirect={`/${redirectResource}`}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar cancelButtonResource={redirectResource} />}>
        <Box sx={{ display: 'block' }}>
          <TextInput
            source="firstName"
            label="First Name"
            validate={[required()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="lastName"
            label="Last Name"
            validate={[required()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput source="jobTitle" label="Job Title" sx={commonStyles.formComponentDefaultMargins} />

          <Divider
            style={{
              minWidth: '100%',
              marginTop: '0px',
              marginBottom: '15px',
              height: 0,
            }}
          />

          <TextInput
            source="addresses[0].email"
            label="Email"
            validate={[email()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput source="addresses[0].phone" label="Phone" sx={commonStyles.formComponentDefaultMargins} />

          <TextInput
            source="addresses[0].alternativeEmail"
            label="Alternative Email"
            validate={[email()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="addresses[0].alternativePhone"
            label="Alternative Phone"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="addresses[0].address"
            label="Address"
            sx={commonStyles.formComponentDefaultMargins}
            fullWidth
          />

          <Divider
            style={{
              minWidth: '100%',
              marginTop: '0px',
              marginBottom: '15px',
              height: 0,
            }}
          />

          <AutocompleteInput
            validate={[required()]}
            optionValue="name"
            optionText="name"
            source="addresses[0].country"
            label="Country"
            onChange={(item: any) => setCountry(item)}
            choices={countryData}
            loading={isLoading}
            sx={commonStyles.formComponentDefaultMargins}
          />

          <CustomAutoCompleteLookup
            validate={[required()]}
            lookupResource={RESOURCE_LOOKUP_STATE}
            source="addresses[0].state"
            label="State/Province"
            onChange={(item: any) => setState(item)}
            parentName={country}
            sx={commonStyles.formComponentDefaultMargins}
          />

          <CustomAutoCompleteLookup
            lookupResource={RESOURCE_LOOKUP_CITY}
            source="addresses[0].city"
            label="City/Town"
            parentName={state}
            sx={commonStyles.formComponentDefaultMargins}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
