import React from 'react';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import {
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  ReferenceManyField,
  TabbedForm,
  TextField,
  useRecordContext,
  Identifier,
} from 'react-admin';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { RESOURCE_JOB_COMMENTS, RESOURCE_JOBS_ADDRESSES } from '../../provider/restProvider';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import AddressRowForm from '../Candidate/AddressRowForm';
import { US_COUNTRY_NAME } from '../../provider/constants';
import { commonStyles } from '../CommonStyles';
import { CommentsTab } from '../Comment/CommentsTab';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import JobCandidates from './JobCandidates';
import { CandidateList } from './CandidateList';
import { JobForm } from './JobForm';

export interface JobEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export type AddProspectProps = {
  selectedIds: Identifier[];
  jobId: string;
  resource?: string;
  isRenderContentOnly?: boolean;
  onSuccess?: () => void;
  responseInfo?: any;
};

export const JobEdit: React.FC<JobEditProps> = ({ syncWithLocation, ...props }) => {
  const { id } = useParams();
  const jobRecord = useRecordContext();

  return (
    <Edit {...props} title={syncWithLocation === undefined ? '' : ' '} actions={false} mutationMode="pessimistic">
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        style={{ width: '100%' }}
        syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header" sx={commonStyles.formTabHeader}>
          <JobForm />
        </LazyFormTab>

        <LazyFormTab label="Addresses">
          <ReferenceManyField reference={RESOURCE_JOBS_ADDRESSES} target="addresses" label="" perPage={100}>
            <EditableDatagrid
              rowClick={false}
              bulkActionButtons={false}
              editForm={<AddressRowForm hasAddressType={false} hasUnitNumber={false} hasCity />}
              createForm={
                <AddressRowForm
                  hasAddressType={false}
                  hasUnitNumber={false}
                  hasCity
                  defaultValues={{
                    jobId: id || jobRecord?.id,
                    country: US_COUNTRY_NAME,
                  }}
                />
              }
              noDelete
              className="EditableGridDefaultHeader"
            >
              <TextField source="address" sortable={false} />
              <TextField source="country" sortable={false} />
              <TextField source="state" label="State/Province" sortable={false} />
              <TextField source="city" label="City/Town" sortable={false} />
              <TextField source="zip" sortable={false} />
              <DeleteWithConfirmButton redirect={false} label="" />
            </EditableDatagrid>
          </ReferenceManyField>
        </LazyFormTab>

        <CommentsTab reference={RESOURCE_JOB_COMMENTS} />

        <LazyFormTab label="Candidates">
          <JobCandidates jobId={id || jobRecord?.id} job={jobRecord} />
          <Box width="100%" height={15} />
          <AccordionForm autoClose={false} toolbar={<> </>}>
            <AccordionFormPanel label="Add candidate as prospect">
              <CandidateList jobId={id || jobRecord?.id} />
            </AccordionFormPanel>
          </AccordionForm>
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
