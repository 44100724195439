import {
  BooleanField,
  BooleanInput,
  DeleteWithConfirmButton,
  Filter,
  FilterProps,
  Link,
  List,
  ListProps,
  NumberField,
  RaRecord,
  TextField,
  useRecordContext,
} from 'react-admin';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import { FC } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import moment from 'moment';
import CustomDateInput from '../CustomDateInput';
import { isEmpty } from '../../provider/UtilityFunctions';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { commonStyles } from '../CommonStyles';
import LegendButton from '../LegendButton';
import { PostPagination } from '../PostPagination';
import ActionsColumn from '../ActionsColumn';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import { colors } from '../../theme';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { PlacementEdit } from './PlacementEdit';
import { PlacementFilter } from './PlacementFilter';

const CandidateLinkField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();

  return (
    <Link to={`/${RESOURCE_CANDIDATES}/${record?.candidateId}`}>
      <TextField source={source} sortable={false} />
    </Link>
  );
};

const getBackGroundColor = (record: RaRecord) => {
  if (moment(record.endDate, 'MMM/DD/YYYY').isBefore(moment()) && record.isActive) {
    return colors.indicator.red;
  }
  if (
    isEmpty(record['onboardingComplete']) ||
    isEmpty(record['drugScreenComplete']) ||
    isEmpty(record['physicalComplete']) ||
    isEmpty(record['trainingComplete']) ||
    isEmpty(record['backgroundCheckComplete']) ||
    isEmpty(record['clientTrainingComplete']) === !record['bypassClientTraining'] //only one should be populated
  ) {
    return colors.indicator.peach;
  }

  return undefined;
};

export const placementRowStyle = (record: RaRecord) => {
  return {
    backgroundColor: getBackGroundColor(record),
  };
};

const PlacementActions: FC = () => {
  const excludedColumns = [
    'candidateId',
    'placementGuid',
    'drugScreenComplete',
    'backgroundCheckComplete',
    'physicalComplete',
    'trainingComplete',
    'onboardingComplete',
    'fieldManagerId',
    'isActive',
    'perDiem',
    'msp',
  ];
  return (
    <ListActions showAction={{ selectAutoSave: true, create: false }} showActionProps={{ export: { excludedColumns } }}>
      <LegendButton
        legends={[
          { label: 'Approved', color: colors.surface.white },
          { label: 'Pending', color: colors.indicator.peach },
          { label: 'Expired', color: colors.indicator.red },
        ]}
      />
      <PlacementFilter context="button" />
    </ListActions>
  );
};

export const PlacementsList: FC<ListProps> = (props) => {
  return (
    <List
      title={undefined}
      {...props}
      filters={<PlacementFilter />}
      perPage={50}
      pagination={<PostPagination />}
      filterDefaultValues={{
        IsActive: {
          fieldName: 'IsActive',
          fieldValue: 'true',
          condition: '==',
        },
      }}
      actions={<PlacementActions />}
      sort={{ field: 'endDate', order: 'DESC' }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        rowClick="expand"
        editComponent={<PlacementEdit syncWithLocation={false} />}
        expandSingle
        rowSx={placementRowStyle}
        sx={commonStyles.defaultGridStyle}
        omit={[
          'clientContactName',
          'duration',
          'onboardingComplete',
          'backgroundCheckComplete',
          'physicalComplete',
          'drugScreenComplete',
          'trainingComplete',
          'clientTrainingComplete',
          'bypassClientTraining',
          'perDiem',
          'msp',
          'straightTimeBillRate',
          'overtimeBillRate',
          'doubleTimeBillRate',
          'straightTimePayRate',
          'overtimePayRate',
          'doubleTimePayRate',
          'rotationId',
          'rotationStartDate',
        ]}
      >
        <TextField source="id" label="Placement ID" sortable />
        <CandidateLinkField source="firstName" sortable />
        <CandidateLinkField source="lastName" sortable />
        <TextField source="jobTitle" sortable={false} />
        <TextField source="startDate" label="Start" sortable />
        <TextField source="endDate" label="End" sortable />
        <NumberField source="duration" sortable />
        <TextField source="onboardingComplete" sortable />
        <TextField source="backgroundCheckComplete" sortable />
        <TextField source="physicalComplete" sortable />
        <TextField source="drugScreenComplete" sortable />
        <TextField source="trainingComplete" sortable />
        <TextField source="clientTrainingComplete" sortable />
        <BooleanField source="bypassClientTraining" sortable />
        <NumberField source="perDiem" sortable />
        <TextField source="projectName" sortable />
        <TextField source="clientName" sortable />
        <NumberField source="straightTimeBillRate" sortable />
        <NumberField source="overtimeBillRate" sortable />
        <NumberField source="doubleTimeBillRate" sortable />
        <NumberField source="straightTimePayRate" sortable />
        <NumberField source="overtimePayRate" sortable />
        <NumberField source="doubleTimePayRate" sortable />
        <TextField source="rotationId" sortable />
        <TextField source="rotationStartDate" sortable />
        <TextField source="clientContactName" sortable={false} />
        <TextField source="fieldManagerName" label="Field Manager" sortable />
        <ActionsColumn
          label="Actions"
          actionsSettings={{ show: false }}
          customDeleteButton={
            <DeleteWithConfirmButton
              label=""
              confirmTitle="Archive Placement"
              confirmContent="Are you sure to archive this item?"
              icon={<ArchiveIcon />}
            />
          }
        />
      </DatagridConfigurableRbac>
    </List>
  );
};
