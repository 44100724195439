import Box from '@mui/material/Box';
import React from 'react';
import { BooleanField, ReferenceField, SimpleShowLayout, TextField } from 'react-admin';
import { RESOURCE_CLIENTS, RESOURCE_PROJECTS } from '../../../provider/restProvider';

export const JobInfoTab = () => {
  return (
    <ReferenceField label="" source="jobId" reference="jobs">
      <Box display="flex" sx={{ minWidth: '100%' }}>
        <Box flex={1} mr="0.5em">
          <SimpleShowLayout>
            <TextField label="Job ID" source="id" />
            <TextField label="Job Title" source="jobTitle" />
            <ReferenceField source="clientId" reference={RESOURCE_CLIENTS} link="edit">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="projectId" reference={RESOURCE_PROJECTS} link="edit">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="numberOfPositions" />
            <TextField source="startDate" />
            <TextField source="endDate" />
            <TextField source="perDiem" />
            <TextField source="msp" />
          </SimpleShowLayout>
        </Box>
        <Box flex={1} ml="0.5em">
          <SimpleShowLayout>
            <TextField source="straightTimeBillRate" />
            <TextField source="overtimeBillRate" />
            <TextField source="doubleTimeBillRate" />
            <TextField source="straightTimePayRate" />
            <TextField source="overtimePayRate" />
            <TextField source="doubleTimePayRate" />
            <TextField source="description" />
            <TextField source="region" />
            <BooleanField source="computerRequired" />
            <BooleanField source="emailRequired" />
          </SimpleShowLayout>
        </Box>
      </Box>
    </ReferenceField>
  );
};
