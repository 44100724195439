import { List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { FavoriteListEdit } from './FavoriteListEdit';

const FavoriteActions = () => <ListActions showAction={{ selectAutoSave: true, export: false }} />;

export const FavoriteListList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Favorite Lists"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<FavoriteActions />}
      perPage={50}
      pagination={<PostPagination />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle
        // optimized={true}
        editComponent={<FavoriteListEdit {...props} syncWithLocation={false} />}
        bulkActionButtons={false}
      >
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="lastModifiedDate" label="Last Modified" sortable={false} />
        <TextField source="fieldManagerUpdatedName" label="Updated by" sortable={false} />
        <TextField source="creationDate" label="Created" sortable={false} />
        <TextField source="fieldManagerCreatedName" label="Created by" sortable={false} />

        <ActionsColumn label="Actions" actionsSettings={{ show: false }} />
      </DatagridConfigurableRbac>
    </List>
  );
};
