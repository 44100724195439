export default function genericResponseReducer(
  state,
  action,
  LOADING_ACTION_TYPE,
  LOADED_ACTION_TYPE,
  ERROR_ACTION_TYPE,
  processDataFn = undefined
) {
  switch (action.type) {
    case LOADING_ACTION_TYPE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: '',
      };
    }
    case LOADED_ACTION_TYPE: {
      if (processDataFn) {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: '',
          ...processDataFn(action.data),
        };
      } else {
        return {
          ...state,
          loading: false,
          loaded: true,
          data: action.data,
          error: '',
        };
      }
    }
    case ERROR_ACTION_TYPE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
