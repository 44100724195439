import * as React from 'react';
import { useDispatch } from 'react-redux';
import { receiveMessage } from '../../redux/actions/chatkitActions';
import { ChatMessage } from '../../redux/reducers/initialState';
import { useAblyChatContextProvider } from './ably/AblyChatContextProvider';

export interface ChatContextType {
  isChatConnected: () => boolean;
  sendMessage: (candidateId: number, messageText: string) => Promise<any>;
  connect: () => void;
  disconnect: () => void;
}

export const ChatContext = React.createContext<ChatContextType>({
  sendMessage: () => {
    throw 'Not initialized';
  },
  isChatConnected: () => false,
  connect: () => {
    throw 'Not initialized';
  },
  disconnect: () => {
    throw 'Not Initialized';
  },
});

export function useChatContext() {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
}

export const ChatContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const onMessageReceived = (message: ChatMessage) => {
    // @ts-ignore
    dispatch(receiveMessage(message));
  };

  const chatContext = useAblyChatContextProvider(onMessageReceived);

  return (
    <ChatContext.Provider
      value={{
        ...chatContext,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
