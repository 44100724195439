import * as React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button, Identifier, useNotify, useRefresh } from 'react-admin';
import { AxiosResponse } from 'axios';
import api from '../../provider/api';

export type RemoveButtonProps = {
  selectedIds: Identifier[];
  jobId?: string;
  clearSelection?: () => void;
};

const removeCandidates = async (selectedIds: Identifier[]) => {
  return Promise.all(
    selectedIds.map(async (selectedCandidateId) => {
      try {
        const response: AxiosResponse = await api.jobresponses.delete(selectedCandidateId);
        if (response.status === 200 || response.status === 204) {
          const { data } = response;
          return Promise.resolve({
            data,
          });
        } else {
          return Promise.reject(`Api returned ${response.status}`);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    })
  );
};

const RemoveMultipleButton: React.FC<RemoveButtonProps> = ({ selectedIds = [], clearSelection }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onClicked = async () => {
    removeCandidates(selectedIds).then(() => {
      notify('Prospect Responses Removed', { type: 'info', undoable: false });
      refresh();
      if (clearSelection) clearSelection();
    });
  };

  return (
    <Button label="Remove Prospects" onClick={onClicked}>
      <DeleteIcon />
    </Button>
  );
};

RemoveMultipleButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default RemoveMultipleButton;
