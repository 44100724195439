import React, { ReactElement } from 'react';
import { DatagridConfigurable, useRecordContext } from 'react-admin';
import { useCanAccess } from '@react-admin/ra-rbac';
import { useResourceContext } from 'ra-core';
import { DatagridConfigurableRbacProps } from './types';

interface ExpandWrapperProps {
  expandElement: React.ReactElement;
}

const ExpandWrapper: React.FC<ExpandWrapperProps> = ({ expandElement }): ReactElement => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return React.cloneElement(expandElement, {
    id: record.id,
    record,
    resource,
  });
};

export const DatagridConfigurableRbac: React.FC<DatagridConfigurableRbacProps> = ({
  children,
  editComponent,
  showComponent,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { canAccess: hasResourceEditAccess } = useCanAccess({
    action: 'edit', //FIXME use constant Actions.EDIT (should be added in permissions PR)
    resource: resource,
  });
  const { canAccess: hasResourceShowAccess } = useCanAccess({
    action: 'show', //FIXME use constant Actions.SHOW (should be added in permissions PR)
    resource: resource,
  });

  const expandComponent = hasResourceEditAccess
    ? editComponent
    : hasResourceShowAccess
      ? showComponent && React.cloneElement(showComponent, { syncWithLocation: false })
      : null;

  return (
    <DatagridConfigurable
      bulkActionButtons={false}
      rowClick={false}
      expand={expandComponent && <ExpandWrapper expandElement={expandComponent} />}
      expandSingle
      {...rest}
    >
      {children}
    </DatagridConfigurable>
  );
};
