import Box from '@mui/material/Box';
import React from 'react';
import { BooleanInput, NumberInput, required, TextInput } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import CustomDateInput from '../../CustomDateInput';
import InverseBooleanInput from '../../InverseBooleanInput';
import { ClientContactInput } from '../components/ClientContactInput';
import { FieldManagerInput } from '../components/FieldManagerInput';
import { ProjectContactInput } from '../components/ProjectContactInput';

export const HeaderTab = () => {
  return (
    <>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="id" label="Placement ID" sx={commonStyles.flexBoxItem} readOnly />
        <TextInput source="candidateName" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="jobTitle" sx={commonStyles.flexBoxItem} disabled />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput label="Start Date" source="startDate" sx={commonStyles.flexBoxItem} />
        <CustomDateInput label="End Date" source="endDate" sx={commonStyles.flexBoxItem} />
        <NumberInput source="duration" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput label="Onboarding Complete" source="onboardingComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInput
          label="Background Check Complete"
          source="backgroundCheckComplete"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput label="Physical Complete" source="physicalComplete" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput label="Drug Screen Complete" source="drugScreenComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInput label="Rev1 Training Complete" source="trainingComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInput
          label="Client Training Complete"
          source="clientTrainingComplete"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="straightTimeBillRate" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <NumberInput source="overtimeBillRate" sx={commonStyles.flexBoxItem} />
        <NumberInput source="doubleTimeBillRate" label="Double time bill rate" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="straightTimePayRate" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <NumberInput source="overtimePayRate" sx={commonStyles.flexBoxItem} />
        <NumberInput source="doubleTimePayRate" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="perDiem" sx={commonStyles.flexBoxItem} />
        <TextInput source="rotationId" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="rotationStartDate" sx={commonStyles.flexBoxItem} disabled />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput label="Computer Required" source="computerRequired" sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Email Required" source="emailRequired" sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Bypass Client Training" source="bypassClientTraining" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ClientContactInput sx={commonStyles.flexBoxItem} />
        <ProjectContactInput sx={commonStyles.flexBoxItem} />
        <FieldManagerInput sx={commonStyles.flexBoxItem} />
        <InverseBooleanInput source="isActive" label="Archived" sx={commonStyles.flexBoxItem} />
      </Box>
    </>
  );
};
