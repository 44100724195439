import Box from '@mui/material/Box';
import { AutocompleteInput, Create, CreateProps, required, SimpleForm, TextInput, useGetList } from 'react-admin';
import React, { useState } from 'react';
import { RESOURCE_LOOKUP_CITY, RESOURCE_LOOKUP_COUNTRY, RESOURCE_LOOKUP_STATE } from '../../provider/restProvider';
import { MAX_RECORD_PER_PAGE, US_COUNTRY_NAME } from '../../provider/constants';
import CustomAutoCompleteLookup from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { commonStyles } from '../CommonStyles';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';

export const ClientCreate: React.FC<CreateProps> = (props) => {
  const [country, setCountry] = useState(US_COUNTRY_NAME);
  const [state, setState] = useState('NoState');

  const { data: countryData, isSuccess: countryLoaded } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  return (
    <Create {...props} actions={false}>
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        sx={commonStyles.flexVertical}
        defaultValues={{ addresses: [{ country: US_COUNTRY_NAME }] }}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput source="name" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="addresses[0].phone" label="Phone" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="addresses[0].address" label="Address" sx={commonStyles.flexBoxLongItem} />
          <TextInput source="addresses[0].zip" label="zip" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <AutocompleteInput
            validate={[required()]}
            optionValue="name"
            optionText="name"
            source="addresses[0].country"
            label="Country"
            onChange={(item: any) => setCountry(item)}
            choices={countryLoaded ? countryData : []}
            sx={commonStyles.flexBoxItem}
          />
          <CustomAutoCompleteLookup
            validate={[required()]}
            lookupResource={RESOURCE_LOOKUP_STATE}
            source="addresses[0].state"
            label="State/Province"
            onChange={(item: any) => setState(item)}
            parentName={country}
            sx={commonStyles.flexBoxItem}
          />
          <CustomAutoCompleteLookup
            lookupResource={RESOURCE_LOOKUP_CITY}
            source="addresses[0].city"
            label="City/Town"
            parentName={state}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
