import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Identifier } from 'react-admin';
import NotificationsActive from '@mui/icons-material/NotificationsActive';

import JobSelectionDialog from './JobSelectionDialog';
import AddProspectSendNotificationButton from './AddProspectSendNotificationButton';

export type AddProspectNotifyWithJobSelectButtonProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
};

const AddProspectNotifyWithJobSelectButton: React.FC<AddProspectNotifyWithJobSelectButtonProps> = ({
  selectedIds = [],
  resource,
  onSuccess,
}) => {
  const [isJobSelectionOpen, setIsJobSelectionOpen] = useState(false);
  const [jobId, setJobId] = useState('');

  const onClicked = () => {
    setJobId('');
    setIsJobSelectionOpen(true);
  };

  return (
    <>
      <Button label="Add Prospect And Notify" onClick={onClicked} disabled={selectedIds.length === 0}>
        <NotificationsActive />
      </Button>

      {isJobSelectionOpen && (
        <JobSelectionDialog
          onClose={() => setIsJobSelectionOpen(false)}
          onSelect={(record) => {
            console.log('record', record, 'selectedIds', selectedIds, 'resource', resource);

            if (record?.id) {
              setIsJobSelectionOpen(false);
              setJobId(record.id);
            }
          }}
        />
      )}

      {jobId && (
        <AddProspectSendNotificationButton
          isRenderContentOnly
          jobId={jobId}
          selectedIds={selectedIds}
          resource={resource}
          onSuccess={() => {
            setJobId('');
            setIsJobSelectionOpen(false);
            if (onSuccess) onSuccess();
          }}
        />
      )}
    </>
  );
};

AddProspectNotifyWithJobSelectButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddProspectNotifyWithJobSelectButton;
