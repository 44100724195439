import { FC } from 'react';
import { Edit } from 'react-admin';
import { EditPropsWithLocation } from '../types';
import { UserForm } from './UserForm';

export const UserEdit: FC<EditPropsWithLocation> = ({ title = 'User Edit', ...props }) => {
  return (
    <Edit title={title} actions={false} mutationMode="pessimistic" {...props}>
      <UserForm />
    </Edit>
  );
};
