import { CreateButton, Filter, FilterProps, List, ListProps, NumberField, TextField, TopToolbar } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import ExportButton from '../ExportButton';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { PostPagination } from '../PostPagination';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { ProjectEdit } from './ProjectEdit';

// const defaultColumns: string[] = [
//     'ID',
//     'name',
//     'clientName',
//     'city',
//     'state',
//     'country',
//     'fieldManagedUpdatedName'
// ];

const ClientActions = () => (
  <ListActions showAction={{ selectAutoSave: true }}>
    <ProjectFilter context="button" />
  </ListActions>
);

const ProjectFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search" />
    </Filter>
  );
};

export const ProjectList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Projects"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ProjectFilter />}
      actions={<ClientActions />}
      perPage={50}
      pagination={<PostPagination />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        // optimized={true}
        bulkActionButtons={false}
        editComponent={<ProjectEdit syncWithLocation={false} />}
        expandSingle
      >
        <TextField source="id" label="Project ID" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="clientName" sortable={false} />
        <TextField source="addresses[0].address" label="Address" sortable={false} />
        <TextField source="addresses[0].city" label="City/Town" sortable={false} />
        <TextField source="addresses[0].state" label="State" sortable={false} />
        <TextField source="addresses[0].country" label="Country" sortable={false} />
        <TextField source="fieldManagedUpdatedName" label="Updated by" sortable={false} />

        <ActionsColumn label="Actions" actionsSettings={{ show: false }}></ActionsColumn>
      </DatagridConfigurableRbac>
    </List>
  );
};
