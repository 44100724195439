import { FC } from 'react';
import { ToolbarProps } from 'react-admin';
import ListActions from '../Common/ListActions/ListActions';
import { FilterSidebarButton } from './FilterSidebarButton';

type PermissionListActionsProps = Omit<ToolbarProps, 'children'> & {
  sideFilter?: string;
};

export const PermissionListActions: FC<PermissionListActionsProps> = ({ sideFilter, ...props }) => {
  return (
    <ListActions {...props} showAction={{ select: true, export: false }}>
      <FilterSidebarButton sideFilter={sideFilter} />
    </ListActions>
  );
};
