import React, { ReactElement } from 'react';
import { CreateButton, SelectColumnsButton, TopToolbar, usePermissions, useResourceContext } from 'react-admin';
import { Box } from '@mui/material';
import { canAccess } from '@react-admin/ra-rbac';
import ExportButton, { ExportProps } from '../../ExportButton';
import SelectColumnsButtonAutoSave from '../../SelectColumnsButtonAutoSave';
import { actionPermissions } from '../../Permissions/constants';

type ShowAction = {
  export?: boolean;
  create?: boolean;
  select?: boolean;
  selectAutoSave?: boolean;
};

type ShowActionProps = {
  export?: ExportProps;
  create?: { targetField: string; targetId: string };
  select?: any;
  selectAutoSave?: any;
};

interface ListActionsProps {
  sideFilter?: string;
  defaultFilter?: object;
  children?: ReactElement[] | ReactElement;
  showAction?: ShowAction;
  showActionProps?: ShowActionProps;
  preferenceKey?: string;
  additionalExportButton?: ReactElement;
}

const defaultShowAction = {
  export: true,
  create: true,
  select: false,
  selectAutoSave: false,
};

const ListActions: React.FC<ListActionsProps> = ({
  children,
  showAction: propsShowAction,
  showActionProps,
  preferenceKey,
}): ReactElement => {
  const { permissions } = usePermissions();
  const resource: string = useResourceContext();

  const checkAccess = (action: string) =>
    canAccess({
      permissions,
      action,
      resource,
    });

  const mergedShowAction = { ...defaultShowAction, ...propsShowAction };
  const showAction = {
    export: mergedShowAction.export && checkAccess(actionPermissions.export),
    create: mergedShowAction.create && checkAccess(actionPermissions.create),
    select: mergedShowAction.select,
    selectAutoSave: mergedShowAction.selectAutoSave,
  };

  return (
    <TopToolbar sx={{ flex: '1 0 auto' }}>
      <Box sx={{ flex: '1 0 auto' }} />
      {children}
      {showAction.export && <ExportButton {...showActionProps?.export} />}
      {showAction.select && <SelectColumnsButton className="selectColumnsButton" preferenceKey={preferenceKey} />}
      {showAction.selectAutoSave && <SelectColumnsButtonAutoSave />}
      {showAction.create &&
        ((showActionProps?.create?.targetId && (
          <CreateButton
            className="createButton"
            to={`/${resource}/create?${showActionProps.create.targetField}=${showActionProps.create.targetId}`}
          />
        )) || <CreateButton className="createButton" />)}
    </TopToolbar>
  );
};

export default ListActions;
