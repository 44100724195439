import * as React from 'react';
import { useState } from 'react';
import { FormTab, FormTabProps } from 'react-admin';

export const LazyFormTab = (props: FormTabProps, sx) => {
  const [loaded, setLoaded] = useState(false);

  if (!loaded && (props.intent === 'header' || !props.hidden)) {
    setLoaded(true);
  }

  return loaded ? <FormTab sx={{ display: 'inline-block', ...sx }} {...props} /> : <></>;
};

LazyFormTab.displayName = 'LazyFormTab';
