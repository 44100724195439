import Box from '@mui/material/Box';
import React from 'react';
import { required, SimpleForm } from 'react-admin';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { ActionInput } from './ActionInput';
import { PermissionInput } from './PermissionInput';
import { ResourceInput } from './ResourceInput';
import { RoleInput } from './RoleInput';

export const PermissionForm: React.FC = () => {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar />} sx={commonStyles.flexVertical}>
      <Box sx={commonStyles.flexBox}>
        <RoleInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <PermissionInput validate={[required()]} sx={commonStyles.flexBoxItem} />
        <ActionInput validate={[required()]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ResourceInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
    </SimpleForm>
  );
};
