import { ReferenceInput, SelectInput, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { RESOURCE_PROJECT_CONTACTS } from '../../../provider/restProvider';

type ProjectContactInputProps = {
  sx?: SxProps<Theme>;
};

export const ProjectContactInput: React.FC<ProjectContactInputProps> = ({ sx }) => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      link={false}
      label="Project Contact"
      source="projectContactId"
      reference={RESOURCE_PROJECT_CONTACTS}
      filter={{ projectId: record.projectId }}
    >
      <SelectInput sx={sx} optionText={(record) => `${record.firstName} ${record.lastName}`} label="Project Contact" />
    </ReferenceInput>
  );
};
