import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import {
  Button,
  Datagrid,
  Filter,
  FilterProps,
  List,
  NumberField,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import React from 'react';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import Box from '@mui/material/Box';
import { RESOURCE_JOBS } from '../../provider/restProvider';
import { PostPagination } from '../PostPagination';

export interface JobSelectionDialogProps {
  onClose: () => void;
  onSelect: (record: any) => void;
}

interface SelectButtonProps extends FieldProps {
  onSelect: (record: any) => void;
}

const SelectButton: React.FC<SelectButtonProps> = ({ onSelect }) => {
  const record = useRecordContext();
  return <Button onClick={() => onSelect(record)} label="Select" />;
};

const classes = {
  filter: {
    '& form': {
      minHeight: 70,
    },
  },
};

const JobFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Box sx={classes.filter}>
      <Filter {...props}>
        <TextInput source="searchTerm" label="Search" alwaysOn />
      </Filter>
    </Box>
  );
};

const JobSelectionDialog: React.FC<JobSelectionDialogProps> = ({ onClose, onSelect }) => {
  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogTitle>Select Job</DialogTitle>
      <DialogContent>
        <List
          title=""
          resource={RESOURCE_JOBS}
          sort={{ field: 'jobTitle', order: 'ASC' }}
          filters={<JobFilter />}
          perPage={100}
          pagination={<PostPagination />}
          exporter={false}
          filter={{
            IsActive: {
              fieldName: 'IsActive',
              fieldValue: 'true',
              condition: '==',
            },
          }}
          storeKey="jobSelection"
          disableSyncWithLocation
        >
          <Datagrid rowClick={false} optimized bulkActionButtons={false}>
            <TextField source="id" label="Job ID" sortable />
            <TextField source="clientName" label="Client" sortable={false} />
            <TextField source="jobTitle" sortable />
            <NumberField source="numberOfPositions" label="Number of Pos." sortable />
            <TextField source="startDate" label="Start" sortable />
            <TextField source="endDate" label="End" sortable />
            <SelectButton onSelect={onSelect} />
          </Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobSelectionDialog;
