export const commonStyles = {
  raFilterForm: {
    '& .RaFilter-form': {
      width: '100%',
      alignItems: 'center',
    },
  },
  formComponentDefaultMargins: {
    marginRight: '10px',
    marginTop: '0',
    marginBottom: '0',
    display: 'inline-block',
    // minWidth: '222px',
    '& .MuiInputBase-root': {
      minWidth: '222px',
    },
  },
  flexHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  flexVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  defaultGridStyle: {
    '& .RaDatagrid-headerCell': {
      padding: '10px 5px 10px 5px',
      fontSize: '0.85rem',
      lineHeight: 1,
      textAlign: 'center',
    },
    '& .RaDatagrid-headerRow': {
      borderLeftColor: 'transparent',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    },
    '& .RaDatagrid-row': {
      padding: '6px 8px 6px 8px',
    },
  },
  subGridDefaultStyle: {
    '& .RaDatagrid-headerCell': {
      fontSize: '0.9rem',
      textAlign: 'left',
    },
    '& .RaDatagrid-row': {
      padding: 'unset',
    },
  },

  tabbedFormBoldHeader: {
    '& .Mui-selected.form-tab': {
      fontWeight: 600,
    },
  },

  sideMenuMultiLevelItem: {
    '& .MuiListItemIcon-root.RaMenuItem-menuIcon': {
      minWidth: '40px',
    },
  },

  iconMenuItemCollapsed: {
    '& ': {
      display: 'flex',
    },
    '& .MuiListItem-root': {
      alignItems: 'start',
      width: 'unset',
    },
    '& .MuiListItem-root .RaMenuItemCategory-link': {
      padding: '4px 16px',
    },
  },
  formTabHeader: {
    display: 'flex',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '15px',
  },
  flexBoxItem: {
    width: '225px',
    marginRight: '10px',
    marginTop: '8px',
  },
  flexBoxLongItem: {
    width: '475px',
    marginRight: '10px',
    marginTop: '8px',
  },
  columnActionsBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      minWidth: '34px',
      height: '34px',
      '& > span': {
        margin: 0,
      },
    },
  },
  footerActionsBox: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    gap: '10px',
  },
  footerAdditionalActionsBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  formTabDatagrid: {
    minWidth: '360px',
    '& .MuiTableRow-root td:last-of-type': {
      minWidth: '130px',
      '& > *': {
        visibility: 'visible',
      },
    },
  },
  formTabSimpleDatagrid: {
    width: '100%',
    flex: 1,
    '& .MuiTableRow-root td:last-of-type': {
      width: '50px',
      padding: '0 12px',
      '& > *': {
        visibility: 'visible',
      },
    },
  },
};
