import { FC } from 'react';
import { email, required, SaveButton, SimpleForm, SimpleFormProps, TextInput } from 'react-admin';
import { Box } from '@mui/material';
import { commonStyles } from '../CommonStyles';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { RolesReferenceArrayInput } from './RolesReferenceArrayInput';
import { ClientsReferenceArrayInput } from './ClientsReferenceArrayInput';
import { UserCreateType } from './types';

type UserFormProps = {
  createMode?: boolean;
};

export const UserForm: FC<UserFormProps> = ({ createMode = false }) => {
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={<FooterToolbar SaveButtonComponent={<SaveButton type="button" transform={transformUserObject} />} />}
      sx={commonStyles.flexVertical}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput source="title" label="Title" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <TextInput source="firstName" label="First Name" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <TextInput source="lastName" label="Last Name" validate={[required()]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={[required(), email()]}
          readOnly={!createMode}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="phone"
          label="Phone Number"
          type="tel"
          validate={[required()]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ClientsReferenceArrayInput sx={commonStyles.flexBoxLongItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <RolesReferenceArrayInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
    </SimpleForm>
  );
};

function transformUserObject(data: UserCreateType) {
  return {
    ...data,
    fieldManagerRecord: {
      ...data?.fieldManagerRecord,
      name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}`.trim(),
      phone: data?.phone,
      email: data?.email,
    },
  };
}
