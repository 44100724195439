import { FC } from 'react';
import { DeleteWithConfirmButton, DeleteWithConfirmButtonProps, useRecordContext } from 'react-admin';

type DeleteUserButtonProps = Omit<DeleteWithConfirmButtonProps, 'confirmTitle' | 'label' | 'children'>;

export const DeleteUserButton: FC<DeleteUserButtonProps> = (props) => {
  const record = useRecordContext();

  return (
    <DeleteWithConfirmButton
      confirmTitle={`Delete user ${record?.firstName} ${record?.lastName}`}
      label=""
      {...props}
    />
  );
};
