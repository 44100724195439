import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Identifier } from 'ra-core';
import React from 'react';
import { Button, ReferenceManyField, TextField, useNotify, useRecordContext, useRefresh } from 'react-admin';
import api from '../../../provider/api';
import { US_COUNTRY_NAME } from '../../../provider/constants';
import { RESOURCE_CANDIDATE_ADDRESSES } from '../../../provider/restProvider';
import { ID_SEPARATOR } from '../../../provider/restProviderHelper';
import ActionsColumn from '../../ActionsColumn';
import { commonStyles } from '../../CommonStyles';
import AddressRowForm from '../AddressRowForm';

const addressRowStyle = (record) => {
  if (record && record.isDefault) {
    return {
      borderLeftColor: 'black',
      borderLeftWidth: 8,
      borderLeftStyle: 'solid',
    };
  } else {
    return {
      borderLeftWidth: 8,
    };
  }
};

const SetDefaultAddress = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const parts = ('' + record?.id).split(ID_SEPARATOR);
  const addressId = parseInt(parts[0]);
  const candidateId = parseInt(parts[1]);

  return (
    <Button
      label="Default"
      variant="text"
      disabled={record?.isDefault}
      onClick={() => {
        api.candidates
          .setDefaultAddress(candidateId, addressId)
          .then((response) => {
            if (response.status === 200) {
              refresh();
            } else {
              notify('Try again, please', { type: 'warning', undoable: false });
            }
          })
          .catch(() => {
            notify('Try again, please', { type: 'warning', undoable: false });
          });
      }}
    />
  );
};

type AddressesTabProps = {
  candidateId: Identifier;
};

export const AddressesTab: React.FC<AddressesTabProps> = ({ candidateId }) => {
  return (
    <ReferenceManyField reference={RESOURCE_CANDIDATE_ADDRESSES} target="addresses" label="" perPage={100}>
      <EditableDatagrid
        actions={
          <ActionsColumn actionsSettings={{ edit: false, show: false }}>
            <SetDefaultAddress />
          </ActionsColumn>
        }
        bulkActionButtons={false}
        editForm={<AddressRowForm hasAddressType hasUnitNumber hasCity />}
        createForm={
          <AddressRowForm
            hasAddressType
            hasUnitNumber
            hasCity
            defaultValues={{
              candidateId,
              country: US_COUNTRY_NAME,
            }}
          />
        }
        rowSx={addressRowStyle}
        sx={commonStyles.formTabDatagrid}
        rowClick={false}
      >
        <TextField source="addressType" sortable={false} />
        <TextField source="address" sortable={false} />
        <TextField source="unitNumber" sortable={false} />
        <TextField source="country" sortable={false} />
        <TextField source="state" label="State/Province" sortable={false} />
        <TextField source="city" label="City/Town" sortable={false} />
        <TextField source="zip" sortable={false} />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};
