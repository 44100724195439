import { SxProps } from '@mui/material';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import * as React from 'react';
import { useRecordContext } from 'react-admin';
import DoneIcon from '@mui/icons-material/Done';

interface BooleanProps extends FieldProps {
  isReversed?: boolean;
  sx?: SxProps;
}

const CustomBooleanField: React.FC<BooleanProps> = ({ source, sx, isReversed, ...rest }) => {
  const record = useRecordContext();

  if (isReversed) {
    return record?.[source] ? null : <DoneIcon sx={{ justifyContent: 'center' }} />;
  } else {
    return record?.[source] ? <DoneIcon sx={{ justifyContent: 'center' }} /> : null;
  }
};

export default CustomBooleanField;
