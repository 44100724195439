import { FieldProps } from 'ra-ui-materialui/src/field/types';
import * as React from 'react';
import { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { Link as LinkMaterial } from '@mui/material';

interface Props extends FieldProps {
  children: ReactElement;
  phone?: boolean;
  email?: boolean;
  source?: string;
}

// @ts-ignore
const LinkField: React.FC<Props> = ({ children, resource, phone, email, source = id, ...rest }) => {
  const record = useRecordContext();

  if (phone) {
    return <LinkMaterial href={`tel:${record?.[source]}`}>{children}</LinkMaterial>;
  } else if (email) {
    return <LinkMaterial href={`mailto:${record?.[source]}`}>{children}</LinkMaterial>;
  } else {
    return <Link to={`/${resource}/${record?.[source]}`}>{children}</Link>;
  }
};

export default LinkField;
