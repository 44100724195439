import { FC } from 'react';
import { Show, ShowProps } from 'react-admin';
import ShowActions, { ShowActionsProps } from '../ShowActions/ShowActions';

export type ShowComponentProps = ShowProps & {
  withoutActions?: boolean;
  syncWithLocation?: boolean;
  showActionsProps?: ShowActionsProps;
};

const ShowComponent: FC<ShowComponentProps> = ({ children, showActionsProps, withoutActions, ...rest }) => {
  return (
    <Show {...rest} actions={withoutActions ? false : <ShowActions {...showActionsProps} />}>
      {children}
    </Show>
  );
};

export default ShowComponent;
