import { AuthProvider } from 'react-admin';
import { LOCAL_STORE_VERSION, LOCAL_STORE_VERSION_KEY } from '../App';
import api, { setToken } from './api';
import { DEFAULT_AVATAR, ROLE_ADMIN, ROLE_CANDIDATE, ROLE_FIELD_MANAGER } from './constants';
import { getAPIErrorMessage, hasRole, isEmpty, tryJsonParse } from './UtilityFunctions';

export const AUTH_KEY = 'userV1';
let isPostLoginCalled = false;
let cachedUser = undefined;

export function getUser() {
  if (cachedUser) return cachedUser;
  if (window.location?.hash.includes('#/reset-password')) {
    localStorage.removeItem(AUTH_KEY);
    return undefined;
  }

  try {
    const tokenStr = localStorage.getItem(AUTH_KEY);
    const user = tokenStr ? JSON.parse(localStorage.getItem(AUTH_KEY)) : undefined;
    if (user) {
      cachedUser = user;
      return user;
    }
    // eslint-disable-next-line no-empty
  } catch (_) {
    cachedUser = undefined;
  }
  return undefined;
}

export function setUser(user) {
  localStorage.setItem(AUTH_KEY, JSON.stringify(user));
  cachedUser = undefined;
}

export function getToken() {
  const user = getUser();
  if (user) {
    return user.jwtToken;
  }
  return undefined;
}

const onPostLoggedIn = async (userData) => {
  if (!isPostLoginCalled) {
    isPostLoginCalled = true;
    setToken();

    //Sync userSettings
    //console.log(" onPostLoggedIn userData",userData)
    if (userData && userData?.userSettings) {
      const userSettings = tryJsonParse(userData.userSettings);
      if (
        isEmpty(userSettings[LOCAL_STORE_VERSION_KEY]) ||
        userSettings[LOCAL_STORE_VERSION_KEY] === LOCAL_STORE_VERSION
      ) {
        Object.keys(userSettings).forEach((key) => {
          //console.log("Setting Local St ",key, userSettings[key], typeof userSettings[key]);
          if (typeof userSettings[key] !== 'string') {
            localStorage.setItem(key, JSON.stringify(userSettings[key]));
          } else {
            localStorage.setItem(key, userSettings[key]);
          }
        });
      } else {
        console.log(
          'User Settings version keys are different, discarded',
          userSettings[LOCAL_STORE_VERSION_KEY],
          LOCAL_STORE_VERSION
        );
      }
    }
  }
};

const onPostLoggedOut = async () => {
  isPostLoginCalled = false;
  cachedUser = undefined;
  setToken();
};

export const updateProfilePicture = async (record) => {
  if (record.fieldManagerId && record.avatar) {
    const formData = new FormData();
    formData.append('File', record.avatar.rawFile);
    const response = await api.users.uploadImage(record.fieldManagerId, formData);
    if (response.status === 200) {
      const responseUserDetails = await api.auth.getCurrentUserDetails(getToken());
      if (responseUserDetails.status === 200) {
        localStorage.setItem(AUTH_KEY, JSON.stringify({ ...getUser(), ...responseUserDetails.data }));
        cachedUser = undefined;
        return true;
      }
    }
  }
  return false;
};

export const updatePhoneNumber = async (record) => {
  if (record.fieldManagerId && record.phoneNumber) {
    const responsePhone = await api.users.updateFieldManagerPhone(record.fieldManagerId, {
      phoneNumber: record.phoneNumber,
    });
    if (responsePhone.status === 200) {
      const responseUserDetails = await api.auth.getCurrentUserDetails(getToken());
      if (responseUserDetails.status === 200) {
        localStorage.setItem(AUTH_KEY, JSON.stringify({ ...getUser(), ...responseUserDetails.data }));
        cachedUser = undefined;
        return true;
      }
    }
  }
  return false;
};
export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const loginResponse = await api.auth.login({ email: username, password });
      const { data } = loginResponse;
      if (loginResponse.status === 200) {
        const responseUserDetails = await api.auth.getCurrentUserDetails(data.jwtToken);
        if (responseUserDetails.status === 200) {
          if (hasRole(responseUserDetails.data.roles, ROLE_CANDIDATE)) {
            return Promise.reject(`Your role is ${ROLE_CANDIDATE}. Please use Rev1 Mobile app`);
          }
          const userData = { ...data, ...responseUserDetails.data };
          localStorage.setItem(AUTH_KEY, JSON.stringify(userData));
          await onPostLoggedIn(userData);
          return Promise.resolve();
        }
      }
    } catch (e) {
      console.log(e);
      return getAPIErrorMessage(e, 'Try again');
    }
  },
  logout: async () => {
    localStorage.removeItem(AUTH_KEY);
    await onPostLoggedOut();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: async (err) => {
    if (typeof err == 'string') {
      if (err.toLowerCase().includes('unauthorized') || err.toLowerCase().includes('denied')) {
        localStorage.removeItem(AUTH_KEY);
        await onPostLoggedOut();
        return Promise.reject('Please logout and login');
      }
    } else {
      console.error('AuthProvider.checkError called with', err);
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const user = getUser();
    if (user && user.jwtToken) {
      await onPostLoggedIn(undefined);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const user = getUser();
    if (!user) {
      return Promise.reject('Login first, please!');
    }
    return Promise.resolve(user.effectivePermissions);
  },
  getRoles: () => Promise.reject('Not supported yet!'),
  getIdentity: () => {
    const user = getUser();
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        avatar: user.avatarURL || DEFAULT_AVATAR,
        fieldManagerId: user.fieldManagerId,
      });
    }
    return Promise.reject("Couldn't get user details");
  },
};
