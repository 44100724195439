import { BooleanInput, Create, CreateProps, email, required, SimpleForm, TextInput } from 'react-admin';
import Box from '@mui/material/Box';
import React from 'react';
import { commonStyles } from '../CommonStyles';
import CustomDateInput from '../CustomDateInput';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';

export const CandidateCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} actions={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar />} sx={commonStyles.flexVertical}>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="title" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="firstName" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="lastName" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="nickName" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="personalEmail"
            type="email"
            validate={[email(), required()]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput source="workEmail" type="email" validate={[email()]} sx={commonStyles.flexBoxItem} />
          <TextInput source="mobile" sx={commonStyles.flexBoxItem} />
          <TextInput source="homePhone" sx={commonStyles.flexBoxItem} />
          <TextInput source="otherPhone" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="birthday" sx={commonStyles.flexBoxItem} />
          <BooleanInput source="doNotHire" sx={commonStyles.flexBoxItem} />
          <BooleanInput source="hasMilitaryExperience" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="referredBy" sx={commonStyles.flexBoxItem} />
          <CustomDateInput label="Availability Date" source="availabilityDate" sx={commonStyles.flexBoxItem} />

          <BooleanInput source="sendResetEmail" label="Send Password Reset Email" sx={commonStyles.flexBoxItem} />
        </Box>
      </SimpleForm>
    </Create>
  );
};
