import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Identifier, useNotify, useRefresh, useUnselectAll, useUpdate } from 'react-admin';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import uniq from 'lodash/uniq';
import { get } from 'lodash';
import { RESOURCE_CANDIDATES, RESOURCE_FAVORITE_LIST } from '../../../provider/restProvider';
import FavoriteListSelectionDialog from './FavoriteListSelectionDialog';

export type AddToFavoriteListButtonProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
};

const AddToFavoriteListButton: React.FC<AddToFavoriteListButtonProps> = ({ selectedIds = [], onSuccess }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATES);
  const [isOpen, setIsOpen] = useState(false);

  const onClicked = () => {
    setIsOpen(true);
  };

  const [update] = useUpdate();

  return (
    <>
      <Button label="Add to Favorite List" onClick={onClicked} disabled={selectedIds.length === 0}>
        <PlaylistAddIcon />
      </Button>

      {isOpen && (
        <FavoriteListSelectionDialog
          onClose={() => setIsOpen(false)}
          onSelect={(record) => {
            update(
              RESOURCE_FAVORITE_LIST,
              {
                id: record.id,
                data: {
                  id: record.id,
                  name: record.name,
                  candidateIds: uniq([...get(record, 'candidateIds', []), ...selectedIds]),
                },
              },
              {
                onSuccess: (data) => {
                  notify(`Added to "${record.name}" list`, {
                    type: 'info',
                    undoable: false,
                  });
                  unselectAll();
                  if (onSuccess) onSuccess();
                  refresh();
                },
                onError: (error) => {
                  console.log('Error', error);
                  notify('Try again, please', {
                    type: 'warning',
                    undoable: false,
                  });
                },
              }
            ).then();

            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

AddToFavoriteListButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddToFavoriteListButton;
