import { DataProvider, GetListParams, GetManyReferenceParams } from 'ra-core/src/types';
// @ts-ignore
import { combineDataProviders, RecordType } from 'react-admin';
import { AxiosResponse } from 'axios';
import simpleRestProvider from 'ra-data-simple-rest';
import api, { GetListV2Filter, GetListV2Params, httpClient, REACT_APP_REVONE_API_URL } from './api';
import { encodeId, getDocuments, getDocumentUrl, ID_SEPARATOR, sanitizeEmptyFields } from './restProviderHelper';
import { capitalizeFirstLetter, getAPIErrorMessage, isNotEmpty } from './UtilityFunctions';
import { DEFAULT_AVATAR, MAX_RECORD_PER_PAGE } from './constants';
import { getUser } from './authProvider';

export const RESOURCE_CANDIDATES: string = 'candidates';
export const RESOURCE_JOBS: string = 'jobs';
export const RESOURCE_PLACEMENTS: string = 'placements';
export const RESOURCE_MESSAGING: string = 'messaging';
export const RESOURCE_REPORTS = 'reports';

export const RESOURCE_CANDIDATE_COMMENTS: string = 'comments/candidate';
export const RESOURCE_CANDIDATE_DOCUMENTS: string = 'documents/candidate';
export const RESOURCE_CANDIDATE_ADDRESSES: string = 'addresses/candidate';
export const RESOURCE_JOBS_ADDRESSES: string = 'addresses/job';
export const RESOURCE_CANDIDATE_MEMBERSHIPS: string = 'memberships';
export const RESOURCE_CANDIDATE_CLEARANCES: string = 'clearances';
export const RESOURCE_CANDIDATE_SKILLS: string = 'skills';
export const RESOURCE_CANDIDATE_SUBINDUSTRIES: string = 'subindustries';
export const RESOURCE_CANDIDATE_PROJECT_EXPERIENCE: string = 'projectexperiences';
export const RESOURCE_CANDIDATE_PLACEMENTS: string = 'placements/candidate';
export const RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE: string = 'oemexperience-type';
export const RESOURCE_CANDIDATE_OEM_EXPERIENCE: string = 'oemexperience';
export const RESOURCE_CANDIDATE_WORK_EXPERIENCE: string = 'workexperiences';
export const RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE: string = 'primaryworkscopeexperience';

export const RESOURCE_CANDIDATE_CLIENT_EXPERIENCE: string = 'clientexperiences';

export const RESOURCE_CLIENTS: string = 'clients';
export const RESOURCE_CLIENT_COMMENTS: string = 'comments/client';
export const RESOURCE_CLIENT_DOCUMENTS: string = 'documents/client';
export const RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES: string = 'clients/highlevelindustry';
export const RESOURCE_CLIENT_CONTACTS = 'clientcontacts';
export const RESOURCE_CLIENT_CONTACT_CANDIDATES = 'clientcontact/candidates';

export const RESOURCE_PROJECTS: string = 'projects';
export const REF_CLIENT_PROJECTS = 'client/projects';
export const REF_PROJECT_JOBS = 'project/jobs';
export const RESOURCE_PROJECT_DOCUMENTS: string = 'documents/project';
export const RESOURCE_PROJECT_COMMENTS: string = 'comments/project';
export const RESOURCE_PROJECT_CONTACTS = 'projectcontacts';

export const RESOURCE_LOOKUP_FIELDMANAGER: string = 'lookup/fieldmanager';
export const RESOURCE_LOOKUP_MEMBERSHIPS: string = 'lookup/affiliationmembership';
export const RESOURCE_LOOKUP_CLEARANCE: string = 'lookup/clearance';
export const RESOURCE_LOOKUP_SKILL: string = 'lookup/skill';
export const RESOURCE_LOOKUP_SUBINDUSTRY: string = 'lookup/subindustry';
export const RESOURCE_LOOKUP_JOBTITLE: string = 'lookup/jobtitle';
export const RESOURCE_LOOKUP_CLIENT_EXPERIENCE: string = 'lookup/clientexperience';
export const RESOURCE_LOOKUP_WORK_EXPERIENCE: string = 'lookup/workexperiencelevel';

export const RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE: string = 'lookup/primaryworkscopeexperience';

export const RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE: string = 'lookup/oemexperiencetype';
export const RESOURCE_LOOKUP_OEM_EXPERIENCE: string = 'lookup/oemexperience';
export const RESOURCE_LOOKUP_PROJECT_EXPERIENCE: string = 'lookup/primaryprojectexperience';
export const RESOURCE_LOOKUP_COUNTRY: string = 'lookup/country';
export const RESOURCE_LOOKUP_STATE: string = 'lookup/state';
export const RESOURCE_LOOKUP_CITY: string = 'lookup/city';

export const RESOURCE_HIGH_LEVEL_INDUSTRY: string = 'lookup/highlevelindustry';
export const RESOURCE_PROJECT_SUB_INDUSTRIES: string = 'project/subindustry';

export const RESOURCE_JOB_COMMENTS: string = 'comments/job';

export const RESOURCE_PLACEMENT_COMMENTS: string = 'comments/placement';
export const RESOURCE_PLACEMENT_DOCUMENTS: string = 'placements/documents';

export const RESOURCE_JOB_RESPONSES: string = 'jobresponses';

export const RESOURCE_USERS = 'users';

export const RESOURCE_FAVORITE_LIST = 'favorite-list';

export const RESOURCE_USERS_RA = 'user';
export const RESOURCE_ROLES_RA = 'role';
export const RESOURCE_PERMISSIONS_RA = 'permissionrole';
export const RESOURCE_CLIENTS_RA = 'client';

function convertToFreeTextSearch(searchTerm) {
  let updatedSearchTerm = searchTerm;
  updatedSearchTerm = searchTerm.trim();
  if (!updatedSearchTerm.includes(' ') && !updatedSearchTerm.endsWith('*')) {
    updatedSearchTerm += '*';
  }
  return updatedSearchTerm;
}

const convertManyReferenceParamsToListParams = (manyRefParam: GetManyReferenceParams): GetListV2Params => {
  let searchTerm = manyRefParam.filter?.searchTerm;
  const sortBy = capitalizeFirstLetter(manyRefParam?.sort?.field);
  const sortDirection = manyRefParam?.sort?.order;
  const fieldFilters: GetListV2Filter[] = [];

  if (manyRefParam.filter) {
    Object.keys(manyRefParam.filter)
      .filter(
        (filterName) =>
          !['searchTerm', 'ids', 'includeFields'].includes(filterName) &&
          typeof manyRefParam.filter[filterName] == 'object'
      )
      .forEach((filterName) => fieldFilters.push(manyRefParam.filter[filterName]));
  }
  if (searchTerm) {
    searchTerm = convertToFreeTextSearch(searchTerm);
  }

  return {
    pageNumber: manyRefParam.pagination.page,
    pageSize: manyRefParam.pagination.perPage,
    searchTerm,
    sortBy,
    sortDirection,
    fieldFilters,
    ids: Array.isArray(manyRefParam.filter['ids']) ? manyRefParam.filter['ids'] : undefined,
    includeFields: Array.isArray(manyRefParam.filter['includeFields'])
      ? manyRefParam.filter['includeFields']
      : undefined,
  };
};

export interface GetListParamsMeta {
  useSmallModel: boolean;
}

const dataProvider: DataProvider = {
  getList: async (resource, params: GetListParams) => {
    let additionalFields = {};

    switch (resource) {
      case RESOURCE_CANDIDATES: {
        try {
          let searchTerm = params.filter?.searchTerm;
          const jobTitleSearchTerm = params.filter?.jobTitleSearchTerm;
          const sortBy = capitalizeFirstLetter(params?.sort?.field);
          const sortDirection = params?.sort?.order;
          const fieldFilters: GetListV2Filter[] = [];
          if (params.filter?.availabilityStart) {
            fieldFilters.push({
              fieldName: 'availabilityDate',
              fieldValue: params.filter?.availabilityStart,
              condition: '>=',
            });
          }
          if (params.filter?.availabilityEnd) {
            fieldFilters.push({
              fieldName: 'availabilityDate',
              fieldValue: params.filter?.availabilityEnd,
              condition: '<=',
            });
          }
          if (params.filter) {
            Object.keys(params.filter)
              .filter(
                (filterName) =>
                  ![
                    'searchTerm',
                    'jobTitleSearchTerm',
                    'availabilityStart',
                    'availabilityEnd',
                    'ids',
                    'includeFields',
                    'sideFilters',
                  ].includes(filterName) && typeof params.filter[filterName] == 'object'
              )
              .forEach((filterName) => fieldFilters.push(params.filter[filterName]));
          }
          if (searchTerm) {
            searchTerm = convertToFreeTextSearch(searchTerm);
          }

          let finalSearchTerm = searchTerm;
          if (jobTitleSearchTerm) {
            finalSearchTerm = finalSearchTerm ? `${finalSearchTerm} AND ${jobTitleSearchTerm}` : jobTitleSearchTerm;
          }
          const useSmallModel = params.meta ? (params.meta as GetListParamsMeta)?.useSmallModel : false;
          const response: AxiosResponse = await api.candidates.getList({
            pageNumber: params.pagination.page,
            pageSize: params.pagination.perPage,
            searchTerm: finalSearchTerm,
            sortBy,
            sortDirection,
            fieldFilters,
            sideFilters: params.filter.sideFilters
              ? params.filter.sideFilters.filter((item) => (item !== null ? item : false))
              : undefined,
            ids: Array.isArray(params.filter['ids']) ? params.filter['ids'] : undefined,
            includeFields: Array.isArray(params.filter['includeFields']) ? params.filter['includeFields'] : undefined,
            useSmallModel,
          });

          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model,
              total: data.totalRecords || data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOB_RESPONSES: {
        try {
          const searchTerm = params.filter?.searchTerm;
          const sortBy = capitalizeFirstLetter(params?.sort?.field);
          const sortDirection = params?.sort?.order;
          const fieldFilters: GetListV2Filter[] = [];
          if (params.filter) {
            Object.keys(params.filter)
              .filter(
                (filterName) =>
                  !['searchTerm', 'ids'].includes(filterName) && typeof params.filter[filterName] == 'object'
              )
              .forEach((filterName) => fieldFilters.push(params.filter[filterName]));
          }

          const response: AxiosResponse = await api.jobresponses.getList({
            pageNumber: params.pagination.page,
            pageSize: params.pagination.perPage,
            searchTerm,
            sortBy,
            sortDirection,
            fieldFilters,
          });

          if (response.status === 200) {
            const { data } = response;
            //Get connected candidates and add doNotHire,availabilityDate fields for Row Coloring
            const responseCandidate = await api.candidates.getList({
              pageNumber: 1,
              pageSize: MAX_RECORD_PER_PAGE,
              ids: data.model.filter((jRes) => jRes.candidateId).map((jRes) => jRes.candidateId),
            });

            let updatedData = data.model;

            if (responseCandidate.status === 200) {
              updatedData = data.model.map((jRes) => {
                const newJobResponse = { ...jRes };
                const candidate = responseCandidate.data.model.find((cand) => cand.id === jRes.candidateId);
                newJobResponse.doNotHire = candidate?.doNotHire;
                newJobResponse.availabilityDate = candidate?.availabilityDate;
                newJobResponse.candidate = candidate;
                return newJobResponse;
              });
            }

            //if there is projectId, related clientContact will be fetched for Candidates
            // This clientContact is displayed at Jobs Page/Candidate Tab
            if (isNotEmpty(params.filter?.projectId)) {
              //Request Project's clientContacts and match with Candidate
              const projectContactsResponse: AxiosResponse = await api.projects.getContacts(params.filter?.projectId);
              if (projectContactsResponse.status == 200) {
                updatedData.forEach((item) => {
                  const foundContact = projectContactsResponse.data.model.find((prjContact) =>
                    prjContact.candidateIds.includes(item.candidateId)
                  );
                  if (foundContact) {
                    item.clientContactId = foundContact.id;
                    item.clientContactName = `${foundContact.firstName} ${foundContact.lastName}`;
                  }
                });
              }
            }

            //GET RTR document download URLs
            for (let i = 0; i < updatedData.length; i++) {
              const item = updatedData[i];
              if (isNotEmpty(item.rtrDocumentId) && item.rtrDocumentId) {
                try {
                  const url = await getDocumentUrl(item.rtrDocumentId);
                  item.rtrDocumentUrl = url;
                } catch (err) {
                  console.error('Error occurred on getDocumentUrl()', item, err);
                }
              }
            }

            return Promise.resolve({
              data: updatedData,
              total: updatedData.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_USERS:
      case RESOURCE_CLIENTS:
      case RESOURCE_CLIENT_CONTACTS:
      case RESOURCE_PROJECTS:
      case RESOURCE_JOBS:
      case RESOURCE_PLACEMENTS: {
        try {
          let searchTerm = params.filter?.searchTerm;
          const sortBy = capitalizeFirstLetter(params?.sort?.field);
          const sortDirection = params?.sort?.order;
          const fieldFilters: GetListV2Filter[] = [];
          if (params.filter) {
            Object.keys(params.filter)
              .filter(
                (filterName) =>
                  !['searchTerm', 'ids'].includes(filterName) && typeof params.filter[filterName] == 'object'
              )
              .forEach((filterName) => fieldFilters.push(params.filter[filterName]));
          }
          const useSmallModel = params.meta ? (params.meta as GetListParamsMeta)?.useSmallModel : false;

          let apiFn = undefined;
          switch (resource) {
            case RESOURCE_USERS:
              apiFn = api.users.getList;
              if (params.filter['fieldManagersOnly']) {
                additionalFields = {
                  fieldManagersOnly: params.filter['fieldManagersOnly'],
                };
              }

              break;
            case RESOURCE_CLIENTS:
              if (searchTerm) {
                searchTerm = convertToFreeTextSearch(searchTerm);
              }
              apiFn = api.clients.getList;
              break;
            case RESOURCE_CLIENT_CONTACTS:
              apiFn = api.clientContacts.getList;
              break;
            case RESOURCE_PROJECTS:
              if (searchTerm) {
                searchTerm = convertToFreeTextSearch(searchTerm);
              }
              apiFn = api.projects.getList;
              break;
            case RESOURCE_PLACEMENTS:
              apiFn = api.placements.getList;
              if (typeof params.filter?.startDate == 'string') {
                fieldFilters.push({
                  fieldName: 'startDate',
                  fieldValue: params.filter?.startDate,
                  condition: '>=',
                });
              }
              if (typeof params.filter?.endDate == 'string') {
                fieldFilters.push({
                  fieldName: 'endDate',
                  fieldValue: params.filter?.endDate,
                  condition: '<=',
                });
              }
              if (typeof params.filter?.isPending == 'boolean') {
                fieldFilters.push({
                  fieldName: 'IsPending',
                  fieldValue: params.filter.isPending.toString(),
                  condition: '==',
                });
              }
              if (params.filter?.IsArchived !== undefined) {
                const isActiveFilter = fieldFilters.find((f) => f.fieldName === 'IsActive');
                isActiveFilter.fieldValue = '' + !params.filter?.IsArchived;
              }
              if (searchTerm) {
                searchTerm = convertToFreeTextSearch(searchTerm);
              }
              break;
            case RESOURCE_JOBS:
              apiFn = api.jobs.getList;
              if (params.filter?.IsArchived !== undefined) {
                const isActiveFilter = fieldFilters.find((f) => f.fieldName === 'IsActive');
                isActiveFilter.fieldValue = '' + !params.filter?.IsArchived;
              }
              if (searchTerm) {
                searchTerm = convertToFreeTextSearch(searchTerm);
              }
              break;
          }
          const response: AxiosResponse = await apiFn({
            pageNumber: params.pagination.page,
            pageSize: params.pagination.perPage,
            searchTerm,
            sortBy,
            sortDirection,
            fieldFilters,
            useSmallModel,
            ...additionalFields,
          });
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model,
              total: data.totalRecords || data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_LOOKUP_CLIENT_EXPERIENCE: {
        try {
          const response: AxiosResponse = await api.clients.getList({
            pageNumber: 1,
            pageSize: MAX_RECORD_PER_PAGE,
            sortBy: 'Name',
            sortDirection: 'asc',
          });
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model.map((item) => {
                return { id: parseInt(item.id), name: item.name };
              }),
              total: data.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }
      case RESOURCE_LOOKUP_JOBTITLE: {
        try {
          const response: AxiosResponse = await api.lookup.getData(
            resource,
            params?.filter?.parentId,
            params?.filter?.parentName,
            params?.filter?.keyword
          );
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.map((item) => {
                return { id: parseInt(item.value), name: item.text };
              }),
              total: data.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_HIGH_LEVEL_INDUSTRY:
      case RESOURCE_LOOKUP_COUNTRY:
      case RESOURCE_LOOKUP_STATE:
      case RESOURCE_LOOKUP_CITY:
      case RESOURCE_LOOKUP_FIELDMANAGER:
      case RESOURCE_LOOKUP_MEMBERSHIPS:
      case RESOURCE_LOOKUP_CLEARANCE:
      case RESOURCE_LOOKUP_SKILL:
      case RESOURCE_LOOKUP_SUBINDUSTRY:
      case RESOURCE_LOOKUP_WORK_EXPERIENCE:
      case RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE:
      case RESOURCE_LOOKUP_OEM_EXPERIENCE:
      case RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE:
      case RESOURCE_LOOKUP_PROJECT_EXPERIENCE: {
        try {
          const response: AxiosResponse = await api.lookup.getData(
            resource,
            params?.filter?.parentId,
            params?.filter?.parentName
          );
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.map((item) => {
                return { id: parseInt(item.value), name: item.text };
              }),
              total: data.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_FAVORITE_LIST: {
        try {
          const response: AxiosResponse = await api.favorites.getList();
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model,
              total: data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_REPORTS: {
        try {
          const response: AxiosResponse = await api.reports.getList();
          if (response.status === 200) {
            const { data } = response;
            data.model.forEach((report) => {
              report.id = report.reportId;
            });
            return Promise.resolve({
              data: data.model,
              total: data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_PROJECT_CONTACTS:
        try {
          const response: AxiosResponse = await api.projects.getContacts(params.filter.projectId);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model,
              total: data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      default:
        throw `Not supported Resource: ${resource}`;
    }
  },
  getOne: async (resource, params) => {
    switch (resource) {
      case RESOURCE_CANDIDATE_ADDRESSES: {
        try {
          const parts = ('' + params.id).split(ID_SEPARATOR);
          const addressId = parseInt(parts[0]);
          const candidateId = parseInt(parts[1]);

          const response: AxiosResponse = await api.candidates.getAddresses(candidateId);
          if (response.status === 200) {
            const { data } = response;
            const foundAddress = data.model.find((item) => item.id === addressId);
            if (foundAddress) {
              return Promise.resolve({
                data: encodeId('candidateId', foundAddress),
              });
            } else {
              return Promise.reject(`Address not found ${addressId}`);
            }
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOBS_ADDRESSES: {
        try {
          const parts = ('' + params.id).split(ID_SEPARATOR);
          const addressId = parseInt(parts[0]);
          const jobId = parseInt(parts[1]);

          const response: AxiosResponse = await api.jobs.getAddresses(jobId);
          if (response.status === 200) {
            const { data } = response;
            const foundAddress = data.model.find((item) => item.id === addressId);
            if (foundAddress) {
              return Promise.resolve({
                data: encodeId('jobId', foundAddress),
              });
            } else {
              return Promise.reject(`Address not found ${addressId}`);
            }
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
    }

    let apiFunc = undefined;
    const apiParams = params.id;

    switch (resource) {
      case RESOURCE_PROJECTS:
        apiFunc = api.projects.getProject;
        break;
      case RESOURCE_CLIENTS:
        apiFunc = api.clients.getClient;
        break;
      case RESOURCE_CLIENT_CONTACTS:
        apiFunc = api.clientContacts.getContact;
        break;
      case RESOURCE_CANDIDATES:
        apiFunc = api.candidates.getCandidate;
        break;
      case RESOURCE_JOBS:
        apiFunc = api.jobs.getJob;
        break;
      case RESOURCE_PLACEMENTS:
        apiFunc = api.placements.getPlacement;
        break;
      case RESOURCE_USERS:
        apiFunc = api.users.getUser;
        break;
      case RESOURCE_FAVORITE_LIST:
        apiFunc = api.favorites.getOne;
        break;
      case RESOURCE_REPORTS:
        apiFunc = api.reports.getOne;
        break;
      default:
        throw `Not supported Resource: ${resource}`;
    }

    try {
      const response: AxiosResponse = Array.isArray(apiParams)
        ? await apiFunc.apply(this, apiParams)
        : await apiFunc(apiParams);
      if (response.status === 200) {
        const { data } = response;

        if (resource === RESOURCE_REPORTS) {
          data.model.id = data.model.reportId;
        }

        return Promise.resolve({ data: data.model });
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return getAPIErrorMessage(err);
    }
  },
  getMany: async (resource, params) => {
    switch (resource) {
      case RESOURCE_LOOKUP_FIELDMANAGER:
      case RESOURCE_LOOKUP_STATE:
      case RESOURCE_LOOKUP_CITY:
      case RESOURCE_LOOKUP_COUNTRY: {
        try {
          const response: AxiosResponse = await api.lookup.getData(resource);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.map((item) => {
                return { id: parseInt(item.value), name: item.text };
              }),
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
    }

    let apiFn = undefined;
    switch (resource) {
      case RESOURCE_PROJECT_CONTACTS:
      case RESOURCE_CLIENT_CONTACTS:
        apiFn = api.clientContacts.getList;
        break;
      case RESOURCE_CLIENTS:
        apiFn = api.clients.getList;
        break;
      case RESOURCE_CANDIDATES:
        apiFn = api.candidates.getList;
        break;
      case RESOURCE_JOBS:
        apiFn = api.jobs.getList;
        break;
      case RESOURCE_PROJECTS:
        apiFn = api.projects.getList;
        break;
      default:
        throw `Not supported Resource: ${resource}`;
    }

    try {
      const response: AxiosResponse = await apiFn({
        pageNumber: 1,
        pageSize: MAX_RECORD_PER_PAGE,
        ids: params.ids,
      });
      if (response.status === 200) {
        const { data } = response;
        return Promise.resolve({ data: data.model });
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return getAPIErrorMessage(err);
    }
  },
  getManyReference: async (resource, params) => {
    switch (resource) {
      case RESOURCE_CANDIDATE_DOCUMENTS: {
        return getDocuments(api.candidates.getCandidateDocuments, params.id);
      }
      case RESOURCE_CLIENT_DOCUMENTS: {
        return getDocuments(api.clients.getDocuments, params.id);
      }
      case RESOURCE_PROJECT_DOCUMENTS: {
        return getDocuments(api.projects.getDocuments, params.id);
      }
      case RESOURCE_PLACEMENT_DOCUMENTS: {
        return getDocuments(api.placements.getDocuments, params.id);
      }
      case RESOURCE_CANDIDATE_ADDRESSES: {
        try {
          const response: AxiosResponse = await api.candidates.getAddresses(params.id);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: encodeId('candidateId', data.model),
              total: data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOBS_ADDRESSES: {
        try {
          const response: AxiosResponse = await api.jobs.getAddresses(params.id);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: encodeId('jobId', data.model),
              total: data.model.length,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_CLIENT_CONTACT_CANDIDATES: {
        try {
          const response: AxiosResponse = await api.clientContacts.getFavoriteCandidates(params.id);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: data.model,
              total: data.totalRecords,
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOBS: {
        if (params.target === REF_PROJECT_JOBS) {
          try {
            const response: AxiosResponse = await api.jobs.getList(convertManyReferenceParamsToListParams(params));
            if (response.status === 200) {
              const { data } = response;
              return Promise.resolve({
                data: data.model,
                total: data.totalRecords,
              });
            } else {
              return Promise.reject(`Api returned ${response.status}`);
            }
          } catch (err) {
            return getAPIErrorMessage(err);
          }
        } else {
          return Promise.reject(`Target ${REF_PROJECT_JOBS} not supported!`);
        }
      }
      case RESOURCE_CANDIDATES: {
        if (params.target === RESOURCE_CANDIDATE_PLACEMENTS) {
          try {
            const response: AxiosResponse = await api.placements.getList({
              pageNumber: 1,
              pageSize: 1000,
              sortBy: 'Id',
              sortDirection: 'Asc',
              searchTerm: '',
              fieldFilters: [
                {
                  fieldName: 'CandidateId',
                  fieldValue: `${params.id}`,
                  condition: '==',
                },
              ],
            });
            if (response.status === 200) {
              const { data } = response;
              return Promise.resolve({
                data: data.model,
                total: data.totalRecords,
              });
            } else {
              return Promise.reject(`Api returned ${response.status}`);
            }
          } catch (err) {
            return getAPIErrorMessage(err);
          }
        } else {
          return Promise.reject(`Target ${RESOURCE_CANDIDATE_PLACEMENTS} not supported!`);
        }
      }
    }

    let apiFunc = undefined;
    const apiParams = params.id;
    let apiPostProcessFunc = undefined;

    switch (resource) {
      case RESOURCE_CANDIDATE_COMMENTS:
        apiFunc = api.candidates.getComments;
        break;
      case RESOURCE_CANDIDATE_MEMBERSHIPS:
        apiFunc = api.candidates.getMemberships;
        break;
      case RESOURCE_CANDIDATE_CLEARANCES:
        apiFunc = api.candidates.getClearances;
        break;
      case RESOURCE_CANDIDATE_SKILLS:
        apiFunc = api.candidates.getSkills;
        break;
      case RESOURCE_CANDIDATE_SUBINDUSTRIES:
        apiFunc = api.candidates.getSubindustries;
        break;
      case RESOURCE_CANDIDATE_PROJECT_EXPERIENCE:
        apiFunc = api.candidates.getProjectExperiences;
        break;
      case RESOURCE_CANDIDATE_WORK_EXPERIENCE:
        apiFunc = api.candidates.getWorkExperiences;
        break;
      case RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE:
        apiFunc = api.candidates.getPrimaryWorkExperiences;
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE:
        apiFunc = api.candidates.getOemExperience;
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE:
        apiFunc = api.candidates.getOemExperienceType;
        break;
      case RESOURCE_CANDIDATE_CLIENT_EXPERIENCE:
        apiFunc = api.candidates.getClientExperiences;
        break;
      case RESOURCE_JOB_COMMENTS:
        apiFunc = api.jobs.getComments;
        break;
      case RESOURCE_PLACEMENT_COMMENTS:
        apiFunc = api.placements.getComments;
        break;
      case RESOURCE_PLACEMENT_DOCUMENTS:
        apiFunc = api.placements.getDocuments;
        break;
      case RESOURCE_CLIENT_COMMENTS:
        apiFunc = api.clients.getComments;
        break;
      case RESOURCE_PROJECT_COMMENTS:
        apiFunc = api.projects.getComments;
        break;
      case RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES:
        apiFunc = api.clients.getHighLevelIndustries;
        break;
      case RESOURCE_PROJECT_SUB_INDUSTRIES:
        apiFunc = api.projects.getSubIndustries;
        break;
      case RESOURCE_PROJECT_CONTACTS:
        apiFunc = api.projects.getContacts;
        //Add projectId field
        apiPostProcessFunc = (data) => {
          return data.map((item) => {
            return { ...item, projectId: params.id };
          });
        };
        break;
      default:
        throw `Not supported Resource: ${resource}`;
    }
    try {
      const response: AxiosResponse = Array.isArray(apiParams)
        ? await apiFunc.apply(this, apiParams)
        : await apiFunc(apiParams);
      if (response.status === 200) {
        const { data } = response;
        if (apiPostProcessFunc) {
          const processedData = apiPostProcessFunc(data.model);
          return Promise.resolve({
            data: processedData,
            total: processedData.length,
          });
        } else {
          return Promise.resolve({
            data: data.model,
            total: data.model.length,
          });
        }
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return getAPIErrorMessage(err);
    }
  },
  create: async (resource, params) => {
    switch (resource) {
      case RESOURCE_CANDIDATE_DOCUMENTS:
      case RESOURCE_CLIENT_DOCUMENTS:
      case RESOURCE_PROJECT_DOCUMENTS:
        try {
          const formData = new FormData();
          if (params.data.candidateId) {
            formData.append('CandidateId', params.data.candidateId);
          } else if (params.data.clientId) {
            formData.append('ClientId', params.data.clientId);
          } else if (params.data.projectId) {
            formData.append('ProjectId', params.data.projectId);
          }
          formData.append('Name', params.data.Name);
          formData.append('DocumentType', params.data.DocumentType);
          formData.append('FileType', params.data.FileType);
          formData.append('File', params.data.File.rawFile);

          const response: AxiosResponse = await api.documents.upload(formData);
          if (response.status === 200) {
            return Promise.resolve({ data: { id: params.data.candidateId } });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      case RESOURCE_CANDIDATE_ADDRESSES:
        try {
          const { candidateId } = params.data;

          const response: AxiosResponse = await api.candidates.createAddress(candidateId, params.data);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: encodeId('candidateId', data.model),
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      case RESOURCE_JOBS_ADDRESSES:
        try {
          const { jobId } = params.data;

          const response: AxiosResponse = await api.jobs.createAddress(jobId, params.data);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({
              data: encodeId('jobId', data.model),
            });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      case RESOURCE_CLIENT_CONTACTS:
        try {
          const response: AxiosResponse = await api.clientContacts.create(params.data);
          if (response.status === 200) {
            const { data } = response;
            if (data) {
              return Promise.resolve({ data: data.model });
            }
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
        break;
      case RESOURCE_CANDIDATES:
        try {
          const { sendResetEmail, ...rest } = params.data;
          const registrationPayload = {
            title: params.data.title,
            firstName: params.data.firstName,
            lastName: params.data.lastName,
            email: params.data.personalEmail,
            acceptTerms: true,
            sendResetEmail,
          };
          const registrationResponse = await api.auth.registerCandidate(registrationPayload);

          if (registrationResponse.status === 200) {
            const response: AxiosResponse = await api.candidates.create({
              ...rest,
              userId: registrationResponse.data,
            });

            if (response.status == 200) {
              const { data } = response;
              return Promise.resolve({ data: data.model });
            } else {
              return Promise.reject(`Candidate creation api returned ${response.status}`);
            }
          } else {
            return Promise.reject(`Candidate registration api returned ${registrationResponse.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
    }

    let apiFunc = undefined;
    let apiParams = params.data;

    switch (resource) {
      case RESOURCE_CLIENTS:
        apiFunc = api.clients.create;
        break;
      case RESOURCE_PROJECTS:
        apiFunc = api.projects.create;
        break;
      case RESOURCE_JOBS:
        apiFunc = api.jobs.create;
        break;
      case RESOURCE_CANDIDATE_COMMENTS:
        apiFunc = api.candidates.createComment;
        break;
      case RESOURCE_JOB_COMMENTS:
        apiFunc = api.jobs.createComment;
        break;
      case RESOURCE_CLIENT_COMMENTS:
        apiFunc = api.clients.createComment;
        break;
      case RESOURCE_PROJECT_COMMENTS:
        apiFunc = api.projects.createComment;
        break;
      case RESOURCE_PLACEMENT_COMMENTS:
        apiFunc = api.placements.createComment;
        break;
      case RESOURCE_CANDIDATE_MEMBERSHIPS:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createMembership;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_CLEARANCES:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createClearance;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_SKILLS:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createSkill;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_SUBINDUSTRIES:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createSubindustry;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_PROJECT_EXPERIENCE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createProjectExperience;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_WORK_EXPERIENCE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createWorkExperience;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createPrimaryWorkExperience;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createOemExperience;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createOemExperienceType;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CANDIDATE_CLIENT_EXPERIENCE:
        {
          const { candidateId } = params.data;
          apiFunc = api.candidates.createClientExperience;
          apiParams = [candidateId, params.data];
        }
        break;
      case RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES:
        {
          const { clientId } = params.data;
          apiFunc = api.clients.createHighLevelIndustry;
          apiParams = [clientId, params.data];
        }
        break;
      case RESOURCE_PROJECT_SUB_INDUSTRIES:
        {
          const { projectId } = params.data;
          apiFunc = api.projects.createSubIndustry;
          apiParams = [projectId, params.data];
        }
        break;
      case RESOURCE_USERS:
        apiFunc = api.users.createUser;
        break;
      case RESOURCE_FAVORITE_LIST:
        apiFunc = api.favorites.create;
        break;
      default:
        throw `Not supported Resource: ${resource}`;
    }

    try {
      const response: AxiosResponse = Array.isArray(apiParams)
        ? await apiFunc.apply(this, apiParams)
        : await apiFunc(apiParams);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        if (data) {
          return Promise.resolve({ data: data.model });
        } else {
          console.error("Api needs a change. Didn't return any data after creation!", apiFunc, apiParams);
          return Promise.resolve({ data: { id: 'unknown' } });
        }
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return getAPIErrorMessage(err);
    }
  },
  update: async (resource, params) => {
    switch (resource) {
      case RESOURCE_CANDIDATES: {
        try {
          const response: AxiosResponse = await api.candidates.update(params);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_CANDIDATE_ADDRESSES: {
        try {
          const { id, ...rest } = params.data;
          const parts = ('' + id).split(ID_SEPARATOR);
          const addressId = parseInt(parts[0]);
          const candidateId = parseInt(parts[1]);

          const response: AxiosResponse = await api.candidates.updateAddress(candidateId, {
            ...rest,
            id: addressId,
            candidateId,
          });
          if (response.status === 200) {
            return Promise.resolve({ data: params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOBS_ADDRESSES: {
        try {
          const { id, ...rest } = params.data;
          const parts = ('' + id).split(ID_SEPARATOR);
          const addressId = parseInt(parts[0]);
          const jobId = parseInt(parts[1]);

          const response: AxiosResponse = await api.jobs.updateAddress(jobId, {
            ...rest,
            id: addressId,
            jobId,
          });
          if (response.status === 200) {
            return Promise.resolve({ data: params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOB_RESPONSES: {
        try {
          const response: AxiosResponse = await api.jobresponses.update(params);
          if (response.status === 200 || response.status === 204) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_JOBS: {
        try {
          const response: AxiosResponse = await api.jobs.update(params);
          if (response.status === 200 || response.status === 204) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_CLIENTS: {
        try {
          const response: AxiosResponse = await api.clients.update(params);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_CLIENT_CONTACTS: {
        try {
          const response: AxiosResponse = await api.clientContacts.update(params);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_PROJECTS: {
        try {
          const response: AxiosResponse = await api.projects.update(params);
          if (response.status === 200 || response.status === 204) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_PLACEMENTS: {
        try {
          const payload = sanitizeEmptyFields(params.data, ['rotationId', 'clientContactId', 'fieldManagerId']);
          const response: AxiosResponse = await api.placements.update(payload);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      case RESOURCE_FAVORITE_LIST: {
        try {
          const response: AxiosResponse = await api.favorites.update(params);
          if (response.status === 200) {
            const { data } = response;
            return Promise.resolve({ data: data?.model || params.data });
          } else {
            return Promise.reject(`Api returned ${response.status}`);
          }
        } catch (err) {
          return getAPIErrorMessage(err);
        }
      }
      default:
        throw `Not supported Resource: ${resource}`;
    }
  },
  updateMany: (resource, params) => Promise.reject(`Not supported Resource: ${resource}`),
  delete: async (resource, params) => {
    let apiFunc = undefined;
    let apiParams = params.id;
    switch (resource) {
      case RESOURCE_CANDIDATE_COMMENTS:
        apiFunc = api.candidates.deleteComment;
        break;
      case RESOURCE_CANDIDATES:
        apiFunc = api.candidates.delete;
        break;
      case RESOURCE_CANDIDATE_DOCUMENTS:
      case RESOURCE_PROJECT_DOCUMENTS:
      case RESOURCE_CLIENT_DOCUMENTS:
        apiFunc = api.documents.delete;
        break;
      case RESOURCE_CANDIDATE_ADDRESSES:
        //Decode combined Ids
        {
          const combinedId = '' + params.id;
          const parts = combinedId.split(ID_SEPARATOR);
          apiFunc = api.candidates.deleteAddress;
          // @ts-ignore
          apiParams = [parseInt(parts[1]), parseInt(parts[0])];
        }
        break;
      case RESOURCE_JOBS_ADDRESSES:
        {
          const combinedId = '' + params.id;
          const parts = combinedId.split(ID_SEPARATOR);
          apiFunc = api.jobs.deleteAddress;
          // @ts-ignore
          apiParams = [parseInt(parts[1]), parseInt(parts[0])];
        }
        break;
      case RESOURCE_CANDIDATE_MEMBERSHIPS:
        apiFunc = api.candidates.deleteMembership;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_CLEARANCES:
        apiFunc = api.candidates.deleteClearance;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_SKILLS:
        apiFunc = api.candidates.deleteSkill;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_SUBINDUSTRIES:
        apiFunc = api.candidates.deleteSubindustry;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_PROJECT_EXPERIENCE:
        apiFunc = api.candidates.deleteProjectExperience;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_WORK_EXPERIENCE:
        apiFunc = api.candidates.deleteWorkExperience;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE:
        apiFunc = api.candidates.deletePrimaryWorkExperience;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE:
        apiFunc = api.candidates.deleteOemExperience;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE:
        apiFunc = api.candidates.deleteOemExperienceType;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_CANDIDATE_CLIENT_EXPERIENCE:
        apiFunc = api.candidates.deleteClientExperience;
        // @ts-ignore
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_JOB_RESPONSES:
        apiFunc = api.jobresponses.delete;
        break;
      case RESOURCE_JOB_COMMENTS:
        apiFunc = api.jobs.deleteComment;
        break;
      case RESOURCE_CLIENT_COMMENTS:
        apiFunc = api.clients.deleteComment;
        break;
      case RESOURCE_PROJECT_COMMENTS:
        apiFunc = api.projects.deleteComment;
        break;
      case RESOURCE_PROJECTS:
        apiFunc = api.projects.delete;
        break;
      case RESOURCE_CLIENTS:
        apiFunc = api.clients.delete;
        break;
      case RESOURCE_CLIENT_CONTACTS:
        apiFunc = api.clientContacts.delete;
        break;
      case RESOURCE_JOBS:
        apiFunc = api.jobs.delete;
        break;
      case RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES:
        apiFunc = api.clients.deleteHighLevelIndustry;
        // @ts-ignore
        apiParams = [params.previousData.clientId, params.id];
        break;
      case RESOURCE_PROJECT_SUB_INDUSTRIES:
        apiFunc = api.projects.deleteSubIndustry;
        // @ts-ignore
        apiParams = [params.previousData.projectId, params.id];
        break;
      case RESOURCE_CLIENT_CONTACT_CANDIDATES:
        apiFunc = api.clientContacts.removeFavoriteCandidate;
        break;
      case RESOURCE_PROJECT_CONTACTS:
        apiFunc = api.projects.removeContact;
        // @ts-ignore
        apiParams = [params.id, params.previousData.projectId];
        break;
      case RESOURCE_PLACEMENT_COMMENTS:
        apiFunc = api.placements.deleteComment;
        break;
      case RESOURCE_PLACEMENTS:
        apiFunc = api.placements.delete;
        break;
      case RESOURCE_FAVORITE_LIST:
        apiFunc = api.favorites.delete;
        break;
      case RESOURCE_USERS:
        apiFunc = api.users.deactivateUser;
        break;
      default:
        throw `Not supported Resource: ${resource}`;
    }
    try {
      const response: AxiosResponse = Array.isArray(apiParams)
        ? await apiFunc.apply(this, apiParams)
        : await apiFunc(apiParams);
      if (response.status === 200 || response.status === 204) {
        const data: RecordType = { id: params.id, ...params.previousData };
        return Promise.resolve({ data });
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return getAPIErrorMessage(err);
    }
  },
  deleteMany: (resource, params) => Promise.reject(`Not supported Resource: ${resource}`),
  getUserProfile: (params) => {
    const user = getUser();
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        avatar: DEFAULT_AVATAR,
      });
    }
    return Promise.reject("Couldn't get user details");
  },
  // updateUserProfile: (resource, params) => {
  //   console.log("updateUserProfile params", params);
  //
  //   const user = getUser();
  //   setUser({...user, ...params.data});
  //   return Promise.resolve({data: params.data})
  //
  //
  // },
};

export function getDataProvider(): DataProvider {
  return combineDataProviders((resource) => {
    switch (resource) {
      // For /ra/ endpoints
      case RESOURCE_USERS_RA:
      case RESOURCE_ROLES_RA:
      case RESOURCE_PERMISSIONS_RA:
      case RESOURCE_CLIENTS_RA:
        return simpleRestProvider(`${REACT_APP_REVONE_API_URL}/ra`, httpClient);
      // For old endpoints
      default:
        return dataProvider;
    }
  });
}
