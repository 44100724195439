import React from 'react';
import { RaRecord } from 'react-admin';
import ToggleableTextInput from '../../CustomInputs/ToggleableTextInput';
import { billingInformation, generalTermsAndConditions, mobilizationAndDemobilazation } from '../constants';

const AdditionalInputs = ({ fields }) => {
  return fields.map((field, index) => <ToggleableTextInput {...field} key={index} />);
};

type AdditionalInfoTabProps = {
  record: RaRecord;
};

export const AdditionalInfoTab: React.FC<AdditionalInfoTabProps> = ({ record }) => {
  const generateFieldArray = (fields) => {
    return Object.keys(fields).map((key) => {
      if (fields[key] instanceof Array) {
        if (record?.[key] !== null) {
          return {
            fieldName: key,
            enabled: true,
            defaultValue: record?.[key],
            choices: fields[key],
          };
        } else {
          // Take first choice as default if multiple options exist
          return {
            fieldName: key,
            enabled: false,
            defaultValue: fields[key][0],
            choices: fields[key],
          };
        }
      }
      // Otherwise just return the string default
      if (record?.[key] !== null) {
        return { fieldName: key, enabled: true, defaultValue: record?.[key] };
      } else {
        return { fieldName: key, enabled: false, defaultValue: fields[key] };
      }
    });
  };

  return (
    <>
      <h3>General Terms And Conditions</h3>
      <AdditionalInputs fields={generateFieldArray(generalTermsAndConditions)} />
      <h3>Mobiliazation and Demobiliazation</h3>
      <AdditionalInputs fields={generateFieldArray(mobilizationAndDemobilazation)} />
      <h3>Billing Information</h3>
      <AdditionalInputs fields={generateFieldArray(billingInformation)} />
    </>
  );
};
