import Box from '@mui/material/Box';
import React from 'react';
import { maxLength, required, TextInput } from 'react-admin';
import { commonStyles } from '../CommonStyles';

export const RoleForm = () => {
  return (
    <Box sx={commonStyles.flexBox}>
      <TextInput source="name" label="Role" validate={[required(), maxLength(35)]} sx={commonStyles.flexBoxLongItem} />
    </Box>
  );
};
