import { FC } from 'react';
import { Box } from '@mui/material';
import { EmailField, Labeled, ReferenceArrayField, SingleFieldList, TabbedShowLayout, TextField } from 'react-admin';
import { RESOURCE_CLIENTS_RA, RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';
import { ShowPropsWithLocation } from '../types';
import Show from '../Common/ShowComponent/ShowComponent';

export const UserShow: FC<ShowPropsWithLocation> = ({ title = 'User', syncWithLocation, ...props }) => {
  return (
    <Show title={title} actions={false} {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab syncWithLocation={syncWithLocation} label="User">
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="title" label="Title" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="firstName" label="First Name" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="lastName" label="Last Name" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <EmailField source="email" label="Email" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="phone" label="Phone Number" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <ReferenceArrayField source="clients" reference={RESOURCE_CLIENTS_RA} label="Clients">
                <SingleFieldList linkType={false} />
              </ReferenceArrayField>
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <ReferenceArrayField source="roles" reference={RESOURCE_ROLES_RA} label="Roles">
                <SingleFieldList linkType="show" />
              </ReferenceArrayField>
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
