import { AutocompleteInput, required, TextInput, useGetList, useRecordContext } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { useEffect, useState } from 'react';
import { RESOURCE_LOOKUP_CITY, RESOURCE_LOOKUP_COUNTRY, RESOURCE_LOOKUP_STATE } from '../../provider/restProvider';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import CustomAutoCompleteLookup, { CreateItem } from '../CustomAutoComplete/CustomAutoCompleteLookup';

const AddressRowForm = ({ hasUnitNumber, hasCity, hasAddressType, ...rest }) => {
  const record = useRecordContext();

  const [country, setCountry] = useState(record?.country || rest?.defaultValues?.country || 'NoCountry');
  const [state, setState] = useState(record?.state || 'NoState');

  const { data: countryData, isSuccess: countryLoaded } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  const [addressTypes, setAddressTypes] = useState([
    { id: 'Home', name: 'Home' },
    {
      id: 'Work',
      name: 'Work',
    },
    { id: 'Default', name: 'Default' },
  ]);
  useEffect(() => {
    if (record?.addressType) {
      const found = addressTypes.find((add) => add.id === record?.addressType);
      if (found === undefined) {
        setAddressTypes([{ id: record?.addressType, name: record?.addressType }, ...addressTypes]);
      }
    }
  }, [record?.addressType]);

  // @ts-ignore
  return (
    <RowForm {...rest}>
      {hasAddressType && (
        <AutocompleteInput
          variant="outlined"
          optionValue="name"
          optionText="name"
          source="addressType"
          choices={addressTypes}
          create={
            <CreateItem
              onItemCreate={(item) => {
                setAddressTypes([...addressTypes, { id: item, name: item }]);
              }}
            />
          }
        />
      )}

      <TextInput source="address" variant="outlined" validate={[required()]} fullWidth />
      {hasUnitNumber && <TextInput source="unitNumber" variant="outlined" />}

      <AutocompleteInput
        validate={[required()]}
        variant="outlined"
        optionValue="name"
        optionText="name"
        source="country"
        onChange={(item: any) => setCountry(item)}
        choices={countryLoaded ? countryData : []}
      />

      <CustomAutoCompleteLookup
        validate={[required()]}
        lookupResource={RESOURCE_LOOKUP_STATE}
        source="state"
        label="State/Province"
        onChange={(item: any) => setState(item)}
        parentName={country}
      />

      {hasCity && (
        <CustomAutoCompleteLookup
          validate={[required()]}
          lookupResource={RESOURCE_LOOKUP_CITY}
          source="city"
          label="City/Town"
          parentName={state}
        />
      )}

      <TextInput source="zip" variant="outlined" validate={[required()]} label="zip" />
    </RowForm>
  );
};

export default AddressRowForm;
