import { FC } from 'react';
import { Edit } from 'react-admin';
import { EditPropsWithLocation } from '../types';
import { PermissionForm } from './PermissionForm';

export const PermissionEdit: FC<EditPropsWithLocation> = (props) => {
  return (
    <Edit
      title={props.syncWithLocation === undefined ? 'Role Permission Edit' : ' '}
      actions={false}
      mutationMode="pessimistic"
      {...props}
    >
      <PermissionForm />
    </Edit>
  );
};
