import * as React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import LinkField from '../LinkField';
import CandidateMessageButton from '../Candidate/CandidateMessageButton';
import { FilterFormTitle } from './components/FilterFormTitle';
import { DEFAULT_ROWS_PER_PAGE } from './constants';
import { Empty } from './components/Empty';

const Header = () => {
  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%">
      <FilterFormTitle>New Candidates</FilterFormTitle>
    </Box>
  );
};

const defaultFilter = {
  notMessaged: {
    fieldName: 'NotMessaged',
    fieldValue: 'NotMessaged',
    condition: '==',
  },
  deviceId: {
    fieldName: 'DeviceId',
    fieldValue: null,
    condition: '!=',
  },
};

export const NewCandidates = () => {
  return (
    <List
      title=" "
      resource={RESOURCE_CANDIDATES}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'availabilityDate', order: 'ASC' }}
      perPage={DEFAULT_ROWS_PER_PAGE}
      queryOptions={{ meta: { useSmallModel: true } }}
      disableSyncWithLocation
      actions={<Header />}
    >
      <Datagrid
        rowClick={false}
        resource={RESOURCE_CANDIDATES}
        bulkActionButtons={false}
        empty={<Empty resourceName="candidates" />}
      >
        <LinkField source="id" label="Candidate" resource={RESOURCE_CANDIDATES}>
          <TextField source="candidateName" sortable={false} />
        </LinkField>
        <CandidateMessageButton />
      </Datagrid>
    </List>
  );
};
