import * as React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import { ListControllerResult } from 'ra-core/dist/cjs/controller/list/useListController';
import { RESOURCE_CANDIDATES, RESOURCE_PLACEMENTS } from '../../provider/restProvider';
import LinkField from '../LinkField';
import { convertDateToStr } from '../../provider/UtilityFunctions';
import { ALL_2099, DEFAULT_ROWS_PER_PAGE, featureChoices } from './constants';
import { FilterForm } from './components/FilterForm';
import { Empty } from './components/Empty';

export const PlacementEnding: React.FC = () => {
  const today = convertDateToStr(new Date());
  const defaultFilter = {
    startDate: {
      fieldName: 'startDate',
      fieldValue: today,
      condition: '<',
    },
    endDateInitial: {
      fieldName: 'endDate',
      fieldValue: today,
      condition: '>=',
    },
    endDate: {
      fieldName: 'endDate',
      fieldValue: ALL_2099,
      condition: '<=',
    },
    isActive: {
      fieldName: 'IsActive',
      fieldValue: 'true',
      condition: '==',
    },
  };

  return (
    <List
      title=" "
      resource={RESOURCE_PLACEMENTS}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'endDate', order: 'ASC' }}
      perPage={DEFAULT_ROWS_PER_PAGE}
      queryOptions={{ meta: { useSmallModel: true } }}
      disableSyncWithLocation
      actions={
        <FilterForm
          title="Placements Ending Soon"
          label="Ending within"
          source="endDate"
          choices={featureChoices}
          filterParams={{
            fieldName: 'endDate',
            condition: '<=',
          }}
        />
      }
    >
      <Datagrid
        rowClick={false}
        resource={RESOURCE_CANDIDATES}
        bulkActionButtons={false}
        empty={<Empty resourceName="placements" />}
      >
        <LinkField source="candidateId" label="Candidate" resource={RESOURCE_CANDIDATES}>
          <TextField source="candidateName" sortable={false} />
        </LinkField>
        <LinkField source="id" label="Job Title" resource={RESOURCE_PLACEMENTS}>
          <TextField source="jobTitle" sortable={false} />
        </LinkField>
        <TextField source="startDate" sortable={false} />
        <TextField source="endDate" sortable={false} />
      </Datagrid>
    </List>
  );
};
