import { BooleanInput, required, TextInput } from 'react-admin';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext, useWatch } from 'react-hook-form';

/**
 * RA text input component that can be disabled with a toggle switch
 * also has optional autocomplete property choices
 */

interface ToggleableTextInputProps {
  fieldName: string;
  defaultValue: string;
  enabled: boolean;
  choices?: Array<string>;
}

const ToggleableTextInput = ({ fieldName, defaultValue, enabled, choices }: ToggleableTextInputProps) => {
  const { setValue } = useFormContext();
  const toggleSource = fieldName + '-enabled';
  const toggleEnabled: boolean = useWatch({ name: toggleSource, defaultValue: enabled });
  const fieldValue: string = useWatch({ name: fieldName });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& div:last-child': { width: '100%' },
      }}
    >
      <BooleanInput
        source={toggleSource}
        defaultValue={enabled}
        label={false}
        onClick={() => setValue(fieldName, defaultValue)}
      />
      {choices ? (
        <Autocomplete
          options={choices}
          freeSolo
          disabled={!toggleEnabled}
          value={fieldValue || defaultValue}
          onChange={(_, value) => setValue(fieldName, value)}
          renderInput={(params) => (
            <TextInput {...params} source={fieldName} validate={toggleEnabled ? [required()] : []} />
          )}
        />
      ) : (
        <TextInput
          source={fieldName}
          disabled={!toggleEnabled}
          parse={(value) => (toggleEnabled ? value : null)}
          validate={toggleEnabled ? [required()] : []}
        />
      )}
    </Box>
  );
};

export default ToggleableTextInput;
