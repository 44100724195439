import { Button, useNotify } from 'react-admin';
import React, { useEffect } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import useClipboard from 'react-use-clipboard';
import { isEmpty } from '../../provider/UtilityFunctions';
import api from '../../provider/api';

export const PasswordResetButton = (props) => {
  const notify = useNotify();
  const [pwdResetUrl, setPwdResetUrl] = React.useState('');
  const [copied, setCopied] = useClipboard(pwdResetUrl);

  useEffect(() => {
    if (copied) {
      notify('Password reset link copied to clipboard', {
        type: 'info',
        undoable: false,
      });
    }
  }, [copied]);

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: '20px' }}
        label="Send Pwd reset link"
        variant="text"
        disabled={!props?.record?.userId}
        onClick={async () => {
          try {
            const response = await api.auth.forgotPasswordCandidate(props.record.userId);

            if (response.status === 200) {
              const {
                data: { message },
              } = response;
              if (message && message.includes('https')) {
                setPwdResetUrl(message);
              }

              notify('Password reset link has been sent', {
                type: 'info',
                undoable: false,
              });
            } else {
              notify(`Request failed with ${response.status}`, {
                type: 'warning',
                undoable: false,
              });
            }
          } catch (e) {
            notify('Request failed, try again!', {
              type: 'warning',
              undoable: false,
            });
          }
        }}
      >
        <LockIcon />
      </Button>
      <Button
        style={{ marginLeft: '20px' }}
        label="Copy link"
        variant="text"
        disabled={isEmpty(pwdResetUrl)}
        onClick={setCopied}
      >
        <FileCopyOutlinedIcon />
      </Button>
    </React.Fragment>
  );
};
