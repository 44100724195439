import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { Button } from 'react-admin';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { RootState } from '../../redux/reducers/initialState';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import CandidateBasketDialog from './CandidateBasketDialog';

const CandidateBasketButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useSelector((state: RootState) => state.basket[RESOURCE_CANDIDATES]);

  return (
    <>
      <Button label="Candidates" onClick={() => setIsOpen(true)} disabled={data === undefined || data.length === 0}>
        <Badge style={{ marginRight: '10px' }} badgeContent={data ? data.length : 0} color="secondary">
          <ShoppingBasketIcon fontSize="small" />
        </Badge>
      </Button>

      {isOpen && <CandidateBasketDialog onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default CandidateBasketButton;
