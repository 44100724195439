import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { theme } from '../../theme';

const classes = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 400,
    fontSize: 13,
    color: 'gray !important',
    whiteSpace: 'nowrap',
  },
};

type DividerWithTextProps = {
  children: ReactNode;
};

const DividerWithText: FC<DividerWithTextProps> = ({ children }) => {
  return (
    <Box sx={classes.container}>
      <Box sx={classes.border} />
      <Box sx={classes.content}>{children}</Box>
      <Box sx={classes.border} />
    </Box>
  );
};

export default DividerWithText;
