import { FC, Fragment, Key, useEffect, useState } from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useGetList,
} from 'react-admin';
import { Box, Divider, TextField } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { validateMaxDate, validateMinDate } from '../../utils/validators';
import { commonStyles } from '../CommonStyles';
import InverseBooleanInput from '../InverseBooleanInput';
import { RESOURCE_CLIENTS, RESOURCE_LOOKUP_FIELDMANAGER, RESOURCE_PROJECTS } from '../../provider/restProvider';
import CustomNumberInput from '../Common/CustomNumberInput/CustomNumberInput';
import CustomDateInput from '../CustomDateInput';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';

type JobFormProps = {
  createMode?: boolean;
  key?: Key;
};

export const JobForm: FC<JobFormProps> = ({ createMode = false, ...rest }) => {
  const [clientField, setClientField] = useState('');

  const { data: clientData = [] } = useGetList(RESOURCE_CLIENTS, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
    sort: { field: 'name', order: 'ASC' },
  });

  const { data: projectData = [] } = useGetList(RESOURCE_PROJECTS, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
    sort: { field: 'name', order: 'ASC' },
    filter: {
      clientId: {
        fieldName: 'clientId',
        fieldValue: clientField.toString(),
        condition: '==',
      },
    },
  });

  function handleChangeClientField(e: any) {
    setClientField(e.target.value);
  }

  return (
    <Fragment {...rest}>
      <Box sx={commonStyles.flexBox}>
        {!createMode && <NumberInput source="id" label="Job ID" sx={commonStyles.flexBoxItem} readOnly />}
        <SelectInput
          source="clientId"
          choices={clientData}
          validate={[required()]}
          onChange={handleChangeClientField}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInput source="projectId" choices={projectData} validate={[required()]} sx={commonStyles.flexBoxItem} />
        {!createMode && <TextInput source="projectName" disabled sx={commonStyles.flexBoxItem} />}
      </Box>

      <Box sx={commonStyles.flexBox}>
        <ReferenceInput source="fieldManagerAssignedToId" reference={RESOURCE_LOOKUP_FIELDMANAGER}>
          <SelectInput label="Field Manager" validate={[required()]} sx={commonStyles.flexBoxItem} />
        </ReferenceInput>
        <TextInput source="jobTitle" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="numberOfPositions" validate={[required()]} sx={commonStyles.flexBoxItem} />
      </Box>

      <Box sx={commonStyles.flexBox}>
        <FormDataConsumer<{ startDate: string; endDate: string }>>
          {({ formData }) => (
            <>
              <CustomDateInput
                label="Start Date"
                source="startDate"
                validate={[required(), validateMaxDate(formData.endDate)]}
                sx={commonStyles.flexBoxItem}
              />
              <CustomDateInput
                label="End Date"
                source="endDate"
                validate={[required(), validateMinDate(formData.startDate)]}
                sx={commonStyles.flexBoxItem}
              />
            </>
          )}
        </FormDataConsumer>
        {!createMode && <CustomDateInput label="Date Added" source="dateAdded" sx={commonStyles.flexBoxItem} />}
      </Box>

      <Box sx={commonStyles.flexBox}>
        <TextInput source="region" validate={[required()]} sx={commonStyles.flexBoxItem} />

        {!createMode && (
          <Box sx={commonStyles.flexBox}>
            <TextInput source="createdByName" label="Created by" disabled sx={commonStyles.flexBoxItem} />
            <TextInput source="updatedByName" label="Updated by" disabled sx={commonStyles.flexBoxItem} />
          </Box>
        )}
      </Box>

      <Box sx={commonStyles.flexVertical}>
        <InverseBooleanInput source="isActive" label="Archived" />
        {!createMode && <BooleanInput label="Computer Required" source="computerRequired" />}
        {!createMode && <BooleanInput label="Email Required" source="emailRequired" />}
      </Box>

      <Divider style={{ minWidth: '100%' }} />

      <h3 style={{ minWidth: '100%', marginBottom: '3rem', height: 0 }}>Payments & Billing</h3>

      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput source="perDiem" label="Per Diem" sx={commonStyles.flexBoxItem} />
        <CustomNumberInput
          source="msp"
          max={100}
          label="MSP (%)"
          sx={commonStyles.flexBoxItem}
          fractionDigits={3}
          step={0.001}
          defaultValue={0}
        />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput source="straightTimeBillRate" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="overtimeBillRate" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="doubleTimeBillRate" sx={commonStyles.flexBoxItem} />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox}>
        <RenderNetBillRates />
      </Box>

      <Divider
        style={{
          minWidth: '100%',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />

      <Box sx={commonStyles.flexBox}>
        <RenderStraitPayRates />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox}>
        <TextInput multiline source="description" validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
    </Fragment>
  );
};

const RenderNetBillRates = () => {
  const msp = useWatch({ name: 'msp', defaultValue: 0 });
  const straightTimeBillRate = useWatch({
    name: 'straightTimeBillRate',
    defaultValue: 0,
  });
  const overtimeBillRate = useWatch({
    name: 'overtimeBillRate',
    defaultValue: 0,
  });
  const doubleTimeBillRate = useWatch({
    name: 'doubleTimeBillRate',
    defaultValue: 0,
  });

  return (
    <>
      <TextField
        label="Net ST Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(straightTimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
      <TextField
        label="Net OT Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(overtimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
      <TextField
        label="Net DT Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(doubleTimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
    </>
  );
};

const RenderStraitPayRates = () => {
  const straightTimePayRate = useWatch({
    name: 'straightTimePayRate',
    defaultValue: 0,
  });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('overtimePayRate', straightTimePayRate * 1.5);
    setValue('doubleTimePayRate', straightTimePayRate * 2);
  }, [straightTimePayRate]);

  return (
    <>
      <CustomNumberInput source="straightTimePayRate" sx={commonStyles.flexBoxItem} />
      <CustomNumberInput source="overtimePayRate" disabled sx={commonStyles.flexBoxItem} />
      <CustomNumberInput source="doubleTimePayRate" sx={commonStyles.flexBoxItem} />
    </>
  );
};

function calculateRateWithMsp(rate: number, msp: number) {
  return rate * (1 - msp / 100);
}
