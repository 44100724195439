import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect } from 'react';
import { Button, Edit, EditProps, TabbedForm, useNotify, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import useClipboard from 'react-use-clipboard';
import api from '../../provider/api';
import { RESOURCE_CANDIDATE_COMMENTS } from '../../provider/restProvider';
import { isEmpty } from '../../provider/UtilityFunctions';
import { CommentsTab } from '../Comment/CommentsTab';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import CandidateMessageButton from './CandidateMessageButton';
import { AddressesTab } from './tabs/AddressesTab';
import { ClearanceMembershipsTab } from './tabs/ClearanceMembershipsTab';
import { DocumentsTab } from './tabs/DocumentsTab';
import { ExperienceTab } from './tabs/ExperienceTab';
import { HeaderTab } from './tabs/HeaderTab';
import { PlacementsTab } from './tabs/PlacementsTab';
import { SkillsIndustriesTab } from './tabs/SkillsIndustriesTab';
import '../CommonCss.css';

const PasswordResetButton = () => {
  const notify = useNotify();
  const [pwdResetUrl, setPwdResetUrl] = React.useState('');
  const [copied, setCopied] = useClipboard(pwdResetUrl);
  const record = useRecordContext();
  useEffect(() => {
    if (copied) {
      notify('Password reset link copied to clipboard', {
        type: 'info',
        undoable: false,
      });
    }
  }, [copied]);

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: '20px' }}
        label="Send Pwd reset link"
        variant="text"
        disabled={!record?.userId}
        onClick={async () => {
          try {
            const response = await api.auth.forgotPasswordCandidate(record?.userId);

            if (response.status === 200) {
              const {
                data: { message },
              } = response;
              if (message && message.includes('https')) {
                setPwdResetUrl(message);
              }

              notify('Password reset link has been sent', {
                type: 'info',
                undoable: false,
              });
            } else {
              notify(`Request failed with ${response.status}`, {
                type: 'warning',
                undoable: false,
              });
            }
          } catch (e) {
            notify('Request failed, try again!', {
              type: 'warning',
              undoable: false,
            });
          }
        }}
      >
        <LockIcon />
      </Button>
      <Button
        style={{ marginLeft: '20px' }}
        label="Copy link"
        variant="text"
        disabled={isEmpty(pwdResetUrl)}
        onClick={setCopied}
      >
        <FileCopyOutlinedIcon />
      </Button>
    </React.Fragment>
  );
};

const EditToolbar = (props) => (
  <FooterToolbar {...props}>
    <CandidateMessageButton label="Start chat" />
    <PasswordResetButton />
  </FooterToolbar>
);

export interface CandidateEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export const CandidateEdit: React.FC<CandidateEditProps> = ({ syncWithLocation, ...props }) => {
  const { id } = useParams();
  const record = useRecordContext();
  const candidateId = id || record?.id;

  return (
    <Edit {...props} title={syncWithLocation === undefined ? '' : ' '} actions={false} mutationMode="pessimistic">
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<EditToolbar />}
        syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header" sx={commonStyles.formTabHeader}>
          <HeaderTab />
        </LazyFormTab>

        <CommentsTab reference={RESOURCE_CANDIDATE_COMMENTS} />

        <LazyFormTab label="Documents">
          <DocumentsTab candidateId={candidateId} />
        </LazyFormTab>
        <LazyFormTab label="Addresses">
          <AddressesTab candidateId={candidateId} />
        </LazyFormTab>
        <LazyFormTab label="Experience" sx={{ display: 'flex' }}>
          <ExperienceTab candidateId={candidateId} />
        </LazyFormTab>
        <LazyFormTab label="Clearance/Memberships" sx={{ display: 'flex' }}>
          <ClearanceMembershipsTab candidateId={candidateId} />
        </LazyFormTab>
        <LazyFormTab label="Skills/Industries" sx={{ display: 'flex' }}>
          <SkillsIndustriesTab candidateId={candidateId} />
        </LazyFormTab>
        <LazyFormTab label="Placements">
          <PlacementsTab />
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
