import DashboardIcon from '@mui/icons-material/Dashboard';
import React, { ReactElement } from 'react';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import Work from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListIcon from '@mui/icons-material/List';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  RESOURCE_CANDIDATES,
  RESOURCE_CLIENTS,
  RESOURCE_FAVORITE_LIST,
  RESOURCE_JOBS,
  RESOURCE_MESSAGING,
  RESOURCE_PLACEMENTS,
  RESOURCE_PROJECTS,
  RESOURCE_REPORTS,
  RESOURCE_ROLES_RA,
  RESOURCE_USERS_RA,
  RESOURCE_PERMISSIONS_RA,
} from '../../provider/restProvider';
import MessagingMenuBadge from '../Messaging/MessagingMenu';

export interface ResourceMenuItemData {
  primaryText: string;
  id: string;
  resource: string;
  leftIcon?: ReactElement;
}

export interface ParentMenuItemData {
  id: string;
  name: string;
  label: string;
  icon: ReactElement;
  items: Array<ResourceMenuItemData>;
}

export const menuItems: Array<ResourceMenuItemData | ParentMenuItemData> = [
  {
    primaryText: 'Dashboard',
    id: 'dashboard_menu',
    resource: '',
    leftIcon: <DashboardIcon />,
  },
  {
    primaryText: 'Messages',
    resource: RESOURCE_MESSAGING,
    id: 'messages_menu',
    leftIcon: <MessagingMenuBadge />,
  },
  {
    primaryText: 'Candidates',
    resource: RESOURCE_CANDIDATES,
    id: 'candidates_menu',
    leftIcon: <PeopleAlt />,
  },
  {
    primaryText: 'Clients',
    resource: RESOURCE_CLIENTS,
    id: 'clients_menu',
    leftIcon: <BusinessIcon />,
  },
  {
    primaryText: 'Projects',
    resource: RESOURCE_PROJECTS,
    id: 'projects_menu',
    leftIcon: <ShopTwoIcon />,
  },
  {
    primaryText: 'Jobs',
    resource: RESOURCE_JOBS,
    id: 'jobs_menu',
    leftIcon: <Work />,
  },
  {
    primaryText: 'Placements',
    resource: RESOURCE_PLACEMENTS,
    id: 'placements_menu',
    leftIcon: <AssignmentTurnedInIcon />,
  },
  {
    primaryText: 'Favorite Lists',
    resource: RESOURCE_FAVORITE_LIST,
    id: 'favorite_menu',
    leftIcon: <ListIcon />,
  },
  {
    primaryText: 'Reports',
    resource: RESOURCE_REPORTS,
    id: 'reports_menu',
    leftIcon: <AssessmentOutlinedIcon />,
  },
  {
    name: 'global_settings_menu',
    label: 'Global Settings',
    icon: <ManageAccountsIcon />,
    id: 'global_settings_menu',
    items: [
      {
        primaryText: 'Users',
        resource: RESOURCE_USERS_RA,
        id: 'users_settings_menu',
      },
      {
        primaryText: 'Roles',
        resource: RESOURCE_ROLES_RA,
        id: 'roles_settings_menu',
      },
      {
        primaryText: 'Role permissions',
        resource: RESOURCE_PERMISSIONS_RA,
        id: 'role_permissions_settings_menu',
      },
    ],
  },
];
