export const styles = {
  tabContentContainer: {
    width: '100%',
    display: 'flex',
    gap: 3,
  },
  tabContentChildContainer: {
    display: 'flex',
    flex: '1',
    gap: 3,
  },
};
