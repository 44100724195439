import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  NumberField,
  NumberInput,
  ReferenceManyField,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useGetList,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { SimpleDatagrid } from '../Common/SimpleDatagrid/SimpleDatagrid';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import {
  REF_PROJECT_JOBS,
  RESOURCE_CLIENTS,
  RESOURCE_JOBS,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
  RESOURCE_LOOKUP_SUBINDUSTRY,
  RESOURCE_PROJECT_COMMENTS,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_PROJECT_SUB_INDUSTRIES,
  RESOURCE_PROJECTS,
} from '../../provider/restProvider';
import { UploadDocument } from '../Candidate/UploadDocument';
import DownloadButton from '../Candidate/DownloadButton';
import ActionsColumn from '../ActionsColumn';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { ClientContactList } from '../ClientContacts/ClientContactList';
import LinkField from '../LinkField';
import CustomAutoCompleteLookup from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { commonStyles } from '../CommonStyles';
import { CommentsTab } from '../Comment/CommentsTab';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import '../CommonCss.css';

export interface ProjectEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export const ProjectEdit: React.FC<ProjectEditProps> = ({ syncWithLocation, ...props }) => {
  const { id } = useParams();
  const record = useRecordContext();

  const [projectId, setProjectId] = useState(id || record?.id);
  const { data } = useGetOne(RESOURCE_PROJECTS, { id: id || record?.id });

  const [country, setCountry] = useState(get(data, 'addresses[0].country', undefined) || 'NoCountry');
  const [state, setState] = useState(get(data, 'addresses[0].state', undefined) || 'NoState');

  useEffect(() => {
    if (data) {
      setCountry(get(data, 'addresses[0].country', undefined) || 'NoCountry');
      setState(get(data, 'addresses[0].state', undefined) || 'NoState');
      setProjectId(data.id);
    }
  }, [data]);

  const { data: countryData, isSuccess: countryLoaded } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  const { data: clientData, isSuccess: clientLoaded } = useGetList(RESOURCE_CLIENTS, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
    sort: { field: 'name', order: 'ASC' },
  });

  // @ts-ignore
  return (
    <Edit {...props} title={syncWithLocation === undefined ? '' : ' '} actions={false} mutationMode="pessimistic">
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        style={{ width: '100%' }}
        syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header" sx={commonStyles.formTabHeader}>
          <Box sx={commonStyles.flexBox}>
            <NumberInput source="id" label="Project ID" sx={commonStyles.flexBoxItem} readOnly />
            <TextInput source="name" validate={[required()]} sx={commonStyles.flexBoxItem} />
            <SelectInput
              source="clientId"
              validate={[required()]}
              choices={clientLoaded ? clientData : []}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="description"
              fullWidth
              validate={[required()]}
              multiline
              sx={commonStyles.flexBoxLongItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="addresses[0].address" label="Address" fullWidth sx={commonStyles.flexBoxLongItem} />
            <TextInput source="addresses[0].zip" label="zip" sx={commonStyles.flexBoxItem} />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <AutocompleteInput
              validate={[required()]}
              optionValue="name"
              optionText="name"
              source="addresses[0].country"
              label="Country"
              onChange={(item: any) => setCountry(item)}
              choices={countryLoaded ? countryData : []}
              sx={commonStyles.flexBoxItem}
            />
            <CustomAutoCompleteLookup
              validate={[required()]}
              lookupResource={RESOURCE_LOOKUP_STATE}
              source="addresses[0].state"
              label="State/Province"
              onChange={(item: any) => setState(item)}
              parentName={country}
              sx={commonStyles.flexBoxItem}
            />
            <CustomAutoCompleteLookup
              lookupResource={RESOURCE_LOOKUP_CITY}
              source="addresses[0].city"
              label="City/Town"
              parentName={state}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="fieldManagerCreatedName" label="Created by" disabled sx={commonStyles.flexBoxItem} />
            <TextInput source="fieldManagedUpdatedName" label="Updated by" disabled sx={commonStyles.flexBoxItem} />
          </Box>
        </LazyFormTab>
        <LazyFormTab label="Jobs">
          <ReferenceManyField
            label=""
            reference={RESOURCE_JOBS}
            target={REF_PROJECT_JOBS}
            perPage={1000}
            sort={{ field: 'jobTitle', order: 'ASC' }}
            filter={{
              projectId: {
                fieldName: 'projectId',
                fieldValue: '' + projectId,
                condition: '==',
              },
              IsActive: {
                fieldName: 'IsActive',
                fieldValue: 'true',
                condition: '==',
              },
            }}
          >
            <Datagrid rowClick={false} bulkActionButtons={false} empty={<div> No job found! </div>}>
              <LinkField source="id" label="Job ID" resource={RESOURCE_JOBS}>
                <TextField source="id" label="Job ID" sortable />
              </LinkField>
              <TextField source="jobTitle" sortable />
              <NumberField source="numberOfPositions" label="Number Of Pos" sortable />
              <TextField source="startDate" label="Start" sortable />
              <TextField source="endDate" label="End" sortable />
              <TextField source="region" sortable />
              <TextField source="clientName" label="Client" />
              <ActionsColumn actionsSettings={{ show: false }} />
            </Datagrid>
          </ReferenceManyField>
        </LazyFormTab>
        <LazyFormTab label="Contacts">{projectId && <ClientContactList projectId={projectId} />}</LazyFormTab>

        <CommentsTab reference={RESOURCE_PROJECT_COMMENTS} />

        <LazyFormTab label="Documents">
          <ReferenceManyField
            reference={RESOURCE_PROJECT_DOCUMENTS}
            target="documents"
            label=""
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <Datagrid
              rowClick={false}
              bulkActionButtons={false}
              sx={{ ...commonStyles.subGridDefaultStyle, width: '100%' }}
            >
              <TextField source="documentType" label="Type" sx={{ textTransform: 'capitalize' }} />
              <TextField source="name" label="File Name" />
              <DateField source="creationDate" />
              <ActionsColumn actionsSettings={{ show: false, edit: false }}>
                <DownloadButton source="url" label="" />
              </ActionsColumn>
            </Datagrid>
          </ReferenceManyField>

          <UploadDocument
            idField="projectId"
            idValue={projectId}
            resource={RESOURCE_PROJECT_DOCUMENTS}
            documentTypes={[{ id: 'general', name: 'General' }]}
          />
        </LazyFormTab>
        <LazyFormTab label="Industries" sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%' }}>
            <SimpleDatagrid
              resource={RESOURCE_PROJECT_SUB_INDUSTRIES}
              target="subindustry"
              lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
              label="Industry"
              defaultValues={{ projectId }}
            />
          </Box>
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
