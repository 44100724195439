import { ReferenceInput, SelectInput, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { RESOURCE_CLIENT_CONTACTS } from '../../../provider/restProvider';

type ClientContactInputProps = {
  sx?: SxProps<Theme>;
};

export const ClientContactInput: React.FC<ClientContactInputProps> = ({ sx }) => {
  const record = useRecordContext();

  const filter = {
    clientId: {
      fieldName: 'clientId',
      fieldValue: '' + record?.clientId,
      condition: '==',
    },
  };

  return (
    <ReferenceInput
      link={false}
      label="Client Contact"
      source="clientContactId"
      reference={RESOURCE_CLIENT_CONTACTS}
      filter={filter}
    >
      <SelectInput sx={sx} optionText={(record) => `${record.firstName} ${record.lastName}`} label="Client Contact" />
    </ReferenceInput>
  );
};
