import { FC } from 'react';
import { AutocompleteArrayInput, AutocompleteArrayInputProps, ReferenceArrayInput } from 'react-admin';
import { RESOURCE_CLIENTS_RA } from '../../provider/restProvider';

type ClientsReferenceArrayInputProps = Omit<
  AutocompleteArrayInputProps,
  'children' | 'source' | 'optionText' | 'optionValue'
>;

export const ClientsReferenceArrayInput: FC<ClientsReferenceArrayInputProps> = (props) => {
  return (
    <ReferenceArrayInput source="clients" reference={RESOURCE_CLIENTS_RA}>
      <AutocompleteArrayInput label="Clients" {...props} />
    </ReferenceArrayInput>
  );
};
