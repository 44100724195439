import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import chatkit from './reducers/chatkitReducer';
import basket from './reducers/basketReducer';

export let store = undefined;

export default () => {
  const reducer = combineReducers({
    chatkit,
    basket,
  });

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  store = createStore(
    reducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        thunk
        // add your own middlewares here
      )
      // add your own enhancers here
    )
  );
  return store;
};

export function getStore() {
  return store;
}
