import { FC } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  Button,
  Datagrid,
  EmailField,
  Filter,
  FilterProps,
  ReferenceField,
  TextField,
  TextInput,
  useRecordContext,
  List,
} from 'react-admin';
import IconClose from '@mui/icons-material/Close';
import { RESOURCE_CLIENT_CONTACTS, RESOURCE_CLIENTS } from '../../provider/restProvider';
import { PostPagination } from '../PostPagination';

export interface ClientContactSelectionDialogProps {
  onClose: () => void;
  onSelect: (record: any) => void;
}

interface SelectButtonProps {
  onSelect: (record: any) => void;
}

const SelectButton: FC<SelectButtonProps> = ({ onSelect }) => {
  const record = useRecordContext();
  return <Button onClick={() => onSelect(record)} label="Select" />;
};

const classes = {
  filter: {
    '& form': {
      minHeight: 70,
    },
  },
};

const ClientFilter: FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Box sx={classes.filter}>
      <Filter {...props}>
        <TextInput source="searchTerm" label="Search" alwaysOn />
      </Filter>
    </Box>
  );
};

const ClientContactSelectionDialog: FC<ClientContactSelectionDialogProps> = ({ onClose, onSelect }) => {
  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
      <DialogTitle>Select Contact</DialogTitle>
      <DialogContent style={{ height: '60dvh' }}>
        <List
          title={false}
          resource={RESOURCE_CLIENT_CONTACTS}
          sort={{ field: 'firstName', order: 'ASC' }}
          pagination={<PostPagination />}
          filters={<ClientFilter />}
          perPage={100}
          exporter={false}
          disableSyncWithLocation
        >
          <Datagrid rowClick={false} optimized bulkActionButtons={false}>
            <ReferenceField label="Client" source="clientId" reference={RESOURCE_CLIENTS} link={false} sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="firstName" label="First Name" sortable={false} />
            <TextField source="lastName" label="Last Name" sortable={false} />
            <EmailField source="addresses[0].email" label="Email" sortable={false} />
            <SelectButton onSelect={onSelect} />
          </Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientContactSelectionDialog;
