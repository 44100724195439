import {
  Create,
  FileField,
  FileInput,
  Identifier,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRefresh,
} from 'react-admin';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { commonStyles } from '../CommonStyles';
import { isEmpty, isNotEmpty } from '../../provider/UtilityFunctions';

const ACCEPTED_FILE_FORMATS = '.doc, .docx, .htm, .html, .pdf, .rtf, .txt';
const ACCEPTED_FILE_FORMATS_VALUES = ACCEPTED_FILE_FORMATS.split(',');

const Actions = ({ ...rest }) => {
  const { reset } = useFormContext();
  const refresh = useRefresh();

  return (
    <Toolbar {...rest}>
      <SaveButton
        icon={<CloudUploadIcon />}
        label="Upload Document"
        variant="text"
        type="button"
        mutationOptions={{
          onSuccess: () => {
            reset();
            refresh();
          },
        }}
      />
    </Toolbar>
  );
};

interface SelectChoice {
  id: Identifier;
  name: string;
}

export type UploadDocumentProps = {
  idField: 'candidateId' | 'clientId' | 'projectId';
  idValue: number | string;
  resource: string;
  documentTypes?: SelectChoice[];
};

const FileInputField = () => {
  const form = useFormContext();
  const notify = useNotify();

  return (
    <FileInput
      source="File"
      label=""
      accept={{ 'application/pdf': ACCEPTED_FILE_FORMATS_VALUES }}
      maxSize={10000000}
      multiple={false}
      validate={[required('Drag&Drop a file or click to browse')]}
      onChange={(file) => {
        if (file) {
          if (isNotEmpty(file.name) && isEmpty(form.getValues('Name'))) {
            console.log('Calling file name on change', file.name);
            form.setValue('Name', file.name.split('.').slice(0, -1).join('.'));
          }
          // file === null - user removed selected file
          // file === undefined - user selected file with unsupported format
        } else if (file === undefined) {
          notify(`Supported files: ${ACCEPTED_FILE_FORMATS}`, {
            type: 'warning',
            undoable: false,
          });
        }
      }}
      fullWidth
    >
      <FileField source="File" title="title" />
    </FileInput>
  );
};

export const UploadDocument: React.FC<UploadDocumentProps> = ({ idField, idValue, resource, documentTypes }) => {
  return (
    <Create title=" " resource={resource} actions={false}>
      <SimpleForm
        toolbar={<Actions />}
        defaultValues={{
          [idField]: idValue,
          FileType: [1],
        }}
      >
        <Box sx={commonStyles.flexHorizontal}>
          <SelectInput
            label="Document Type"
            source="DocumentType"
            validate={[required()]}
            choices={
              documentTypes
                ? documentTypes
                : [
                    { id: 'cv', name: 'CV' },
                    {
                      id: 'certificate',
                      name: 'Certificate',
                    },
                  ]
            }
            defaultValue={documentTypes ? documentTypes[0].id : 'cv'}
            sx={{
              ...commonStyles.formComponentDefaultMargins,
              '& div': {
                minWidth: '150px',
              },
            }}
          />
          <TextInput
            label="File Name"
            source="Name"
            isRequired
            validate={[required()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
        </Box>

        <FileInputField />
      </SimpleForm>
    </Create>
  );
};
