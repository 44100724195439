import { FC, useState } from 'react';
import {
  Button,
  RaRecord,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import { commonStyles } from '../CommonStyles';
import { PROSPECT_CANDIDATE_ACCEPTED } from '../../provider/constants';
import { RESOURCE_JOB_RESPONSES } from '../../provider/restProvider';
import { colors, shadows } from '../../theme';

export interface JobResponseUpdateButtonProps {
  jobRecord: RaRecord;
}

const style = {
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: shadows.main,
  p: 4,
  '& h2': {
    color: colors.text.main,
    fontSize: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
};

const renderInformationBox = (jobResponseRecord: RaRecord, prospectStatusId: number, jobRecord: RaRecord) => {
  if (!jobResponseRecord || jobResponseRecord.prospectStatusId === prospectStatusId) {
    return;
  }

  if (prospectStatusId === PROSPECT_CANDIDATE_ACCEPTED) {
    return (
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Changing candidate prospect status to Candidate Accepted will create a placement for{' '}
          <b>{jobResponseRecord.candidateName}</b> as&nbsp;
          <b>{jobResponseRecord.jobTitle}</b> and reduce the number of positions on this job.
          {jobRecord.numberOfPositions === 1 && (
            <>
              <br />
              <br />
              As this is the final position available this job will be archived.
            </>
          )}
          <br />
          <br />
          Are you sure you want to continue ?
        </Typography>
      </Box>
    );
  } else if (jobResponseRecord.prospectStatusId === PROSPECT_CANDIDATE_ACCEPTED) {
    return (
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Changing the candidate status from accepted will delete&nbsp;
          <b>{jobResponseRecord.candidateName}</b> placement as&nbsp;
          <b>{jobResponseRecord.jobTitle}</b>, and add a position to this job&nbsp;
          {jobRecord.numberOfPositions === 0 && (
            <>
              <br />
              <br />
              This job will be unarchived.
            </>
          )}
          <br />
          <br />
          Are you sure you want to continue ?
        </Typography>
      </Box>
    );
  } else {
    return <></>;
  }
};

export const JobResponseUpdateButton: FC<JobResponseUpdateButtonProps> = ({ jobRecord }) => {
  const notify = useNotify();
  const jobResponseRecord = useRecordContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [prospectStatusId, setProspectStatusId] = useState<number>(jobResponseRecord?.prospectStatusId);
  const refresh = useRefresh();

  const [update] = useUpdate(
    RESOURCE_JOB_RESPONSES,
    {
      id: jobResponseRecord?.id,
      data: { ...jobResponseRecord, prospectStatusId },
      previousData: jobResponseRecord,
    },
    {
      onSuccess: (data) => {
        refresh();
        setDialogOpen(false);
      },
      onError: (error) => {
        notify('Sorry, failed, try again!', {
          type: 'warning',
          undoable: false,
        });
      },
    }
  );

  const onDialogClose = () => setDialogOpen(false);
  const onSave = () => {
    update();
  };

  return (
    <>
      <Button
        onClick={() => {
          setDialogOpen(true);
          setProspectStatusId(jobResponseRecord?.prospectStatusId);
        }}
        color="primary"
        label=""
      >
        <EditIcon />
      </Button>

      <Dialog maxWidth="lg" onClose={onDialogClose} open={dialogOpen}>
        <DialogTitle>Job Response Update</DialogTitle>
        <DialogContent>
          <SimpleForm record={jobResponseRecord} toolbar={<></>}>
            <Box display="flex" alignItems="center">
              <TextInput source="candidateName" disabled sx={commonStyles.formComponentDefaultMargins} />
              <SelectInput
                variant="outlined"
                source="prospectStatusId"
                choices={[
                  { id: 1, name: 'Selected as Prospect' },
                  { id: 2, name: 'Notified by Recruiter' },
                  { id: 3, name: 'Not Interested' },
                  { id: 4, name: 'Interested - Not Yet Signed RTR Form' },
                  { id: 5, name: 'Interested - RTR Form Signed' },
                  { id: 6, name: 'Candidate Submitted' },
                  { id: 7, name: 'Candidate Declined' },
                  { id: 8, name: 'Candidate Accepted' },
                ]}
                onChange={(e) => setProspectStatusId(e.target.value)}
                defaultValue={jobResponseRecord?.prospectStatusId}
              />
            </Box>
            {jobResponseRecord && renderInformationBox(jobResponseRecord, prospectStatusId, jobRecord)}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!jobResponseRecord || prospectStatusId === jobResponseRecord.prospectStatusId}
            onClick={onSave}
            color="primary"
            label="Save"
          >
            <SaveIcon />
          </Button>

          <Button onClick={onDialogClose} color="primary" label="Close">
            <IconClose />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
