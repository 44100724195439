export const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  lettersInfoContainer: {
    minWidth: '50%',
    minHeight: '100px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '> h2': {
      marginBottom: '0',
    },
  },
  previousLettersContainer: {
    minWidth: '100%',
    minHeight: '100px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    borderTop: '1px solid grey',
    marginTop: '10px',
    '> h2': {
      marginBottom: '0',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  datagrid: {
    width: '100%',
    '& th': {
      textAlign: 'left !important',
      padding: '6px 16px !important',
    },
  },
};
