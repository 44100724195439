import Box from '@mui/material/Box';
import { Identifier } from 'ra-core';
import React from 'react';
import {
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_LOOKUP_CLEARANCE,
  RESOURCE_LOOKUP_MEMBERSHIPS,
} from '../../../provider/restProvider';
import { styles } from '../styles';
import { SimpleDatagrid } from '../../Common/SimpleDatagrid/SimpleDatagrid';

type ClearanceMembershipsTabProps = {
  candidateId: Identifier;
};

export const ClearanceMembershipsTab: React.FC<ClearanceMembershipsTabProps> = ({ candidateId }) => {
  return (
    <Box sx={styles.tabContentContainer}>
      <SimpleDatagrid
        resource={RESOURCE_CANDIDATE_CLEARANCES}
        lookupResource={RESOURCE_LOOKUP_CLEARANCE}
        label="Clearence"
        defaultValues={{ candidateId }}
      />
      <SimpleDatagrid
        resource={RESOURCE_CANDIDATE_MEMBERSHIPS}
        lookupResource={RESOURCE_LOOKUP_MEMBERSHIPS}
        label="Membership"
        defaultValues={{ candidateId }}
      />
    </Box>
  );
};
