import Box from '@mui/material/Box';
import React from 'react';
import { Datagrid, Link, NumberField, ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import {
  RESOURCE_CANDIDATE_PLACEMENTS,
  RESOURCE_CANDIDATES,
  RESOURCE_PLACEMENTS,
} from '../../../provider/restProvider';
import { commonStyles } from '../../CommonStyles';
import CustomBooleanField from '../../CustomBooleanField';
import { placementRowStyle } from '../../Placements/PlacementsList';

type PlacementLinkFieldProps = {
  label?: string;
};

const PlacementLinkField: React.FC<PlacementLinkFieldProps> = ({ label = 'Placement ID' }) => {
  const record = useRecordContext();

  return (
    <Link to={`/${RESOURCE_PLACEMENTS}/${record?.id}`} target="_blank">
      <TextField key="id" source="id" label={label} sortable={false} />
    </Link>
  );
};

export const PlacementsTab = () => {
  return (
    <Box display="flex" sx={{ minWidth: '100%' }}>
      <Box flex={1} mr="0.5em">
        <ReferenceManyField
          reference={RESOURCE_CANDIDATES}
          target={RESOURCE_CANDIDATE_PLACEMENTS}
          label=""
          perPage={100}
        >
          <Datagrid
            bulkActionButtons={false}
            optimized
            rowClick={false}
            rowSx={placementRowStyle}
            sx={commonStyles.defaultGridStyle}
            empty={<div>No Placements to display</div>}
          >
            <PlacementLinkField label="ID" />
            <TextField key="jobTitle" source="jobTitle" sortable={false} />
            <TextField key="client" source="clientName" sortable={false} />
            <TextField key="project" source="projectName" sortable={false} />
            <TextField key="startDate" source="startDate" label="Start" sortable={false} />
            <TextField key="endDate" source="endDate" label="End" sortable={false} />
            <NumberField key="payRate" source="straightTimePayRate" label="Straight time pay Rate" sortable={false} />
            <NumberField
              key="billRate"
              source="straightTimeBillRate"
              label="Straight time bill rate"
              sortable={false}
            />
            <NumberField key="perDiem" source="perDiem" label="Per Diem" sortable={false} />
            <NumberField key="duration" source="duration" sortable={false} />
            <CustomBooleanField isReversed label="Archived" source="isActive" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Box>
    </Box>
  );
};
