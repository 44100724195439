import { usePermissions } from 'react-admin';
import { canAccess, Permissions } from '@react-admin/ra-rbac';
import { ParentMenuItemData, ResourceMenuItemData } from './menuItems';

const filterItems = (menuItems: Array<ResourceMenuItemData | ParentMenuItemData>, permissions: Permissions) => {
  return menuItems.reduce((acc, menuItem) => {
    if ('resource' in menuItem) {
      if (
        !menuItem.resource ||
        canAccess({
          permissions,
          action: 'list', //FIXME use constant Actions.LIST (should be added in permissions PR)
          resource: menuItem.resource,
        })
      ) {
        acc.push(menuItem);
      }
    }
    if ('items' in menuItem) {
      const filteredChildren = filterItems(menuItem.items, permissions);
      if (filteredChildren?.length > 0) {
        acc.push({
          ...menuItem,
          items: filteredChildren,
        });
      }
    }
    return acc;
  }, []);
};

const useFilteredMenuItems = (menuItems: Array<ResourceMenuItemData | ParentMenuItemData>) => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return [];
  }

  return filterItems(menuItems, permissions);
};

export default useFilteredMenuItems;
