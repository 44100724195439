import React from 'react';
import { Edit, EditProps, SaveButton, TabbedForm, useRecordContext } from 'react-admin';
import { RESOURCE_PLACEMENT_COMMENTS } from '../../provider/restProvider';
import { CommentsTab } from '../Comment/CommentsTab';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { billingInformation, generalTermsAndConditions, mobilizationAndDemobilazation } from './constants';
import { AdditionalInfoTab } from './tabs/AdditionalInfoTab';
import { DocumentsTab } from './tabs/DocumentsTab/DocumentsTab';
import { HeaderTab } from './tabs/HeaderTab';
import { JobInfoTab } from './tabs/JobInfoTab';

export interface PlacementEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export const PlacementEdit: React.FC<PlacementEditProps> = ({ syncWithLocation, ...props }) => {
  return (
    <Edit
      {...props}
      title={syncWithLocation === undefined ? '' : ' '}
      actions={false}
      mutationMode="pessimistic"
      transform={(data) => {
        [
          ...Object.keys(generalTermsAndConditions),
          ...Object.keys(mobilizationAndDemobilazation),
          ...Object.keys(billingInformation),
        ].forEach((key) => {
          if (data[key + '-enabled'] === false) {
            data[key] = null;
          }
        });
        return data;
      }}
    >
      <EditForm syncWithLocation={syncWithLocation} />
    </Edit>
  );
};

const EditForm = ({ syncWithLocation }) => {
  const record = useRecordContext();

  return (
    <TabbedForm
      warnWhenUnsavedChanges
      toolbar={<FooterToolbar SaveButtonComponent={<SaveButton label="Save" variant="text" />} />}
      style={{ width: '100%' }}
      syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
      sx={commonStyles.tabbedFormBoldHeader}
    >
      <LazyFormTab label="Header" sx={commonStyles.formTabHeader}>
        <HeaderTab />
      </LazyFormTab>

      <CommentsTab reference={RESOURCE_PLACEMENT_COMMENTS} />

      <LazyFormTab label="Job Info">
        <JobInfoTab />
      </LazyFormTab>
      <LazyFormTab label="Additional Info" sx={{ '> div': { width: '100%' } }}>
        <AdditionalInfoTab record={record} />
      </LazyFormTab>
      <LazyFormTab label="Documents">
        <DocumentsTab recordId={record.id} />
      </LazyFormTab>
    </TabbedForm>
  );
};
