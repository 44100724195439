import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { SelectInput, SimpleForm, TextInput, useCreateSuggestionContext } from 'react-admin';
import api from '../../provider/api';

interface subindustry {
  value: string;
  text: string;
  parentValue: null;
  parentText: null;
}
const CreateNewSkillDialog = ({ onSubmit }) => {
  const { filter, onCancel } = useCreateSuggestionContext();
  const [value, setValue] = useState(filter || '');
  const [subinstries, setSubindustries] = useState([]);
  const handleSubmit = (data) => {
    const newSkill = {
      name: data.newSkillName,
      parentValue: data.subindustry,
    };
    onSubmit(newSkill);
    onCancel();
  };
  const getSubindustries = async () => {
    try {
      const response: AxiosResponse<Array<object>> = await api.subIndustries.getAllSubindustries();
      if (response.status === 200) {
        const data: Array<object> = response.data;
        return Promise.resolve({
          data,
        });
      } else {
        return Promise.reject(`Api returned ${response.status}`);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };
  useEffect(() => {
    getSubindustries().then((response) => {
      setSubindustries(
        response.data.map((item: subindustry) => {
          return {
            id: item.value,
            name: item.text,
          };
        })
      );
    });
  }, []);
  return (
    <Dialog open onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>Create a new skill</DialogTitle>
      <SimpleForm onSubmit={handleSubmit} toolbar={false}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <TextInput
            source="newSkillName"
            label="New skill name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            sx={{
              '& div': {
                minWidth: '400px',
              },
            }}
          />
          <SelectInput
            source="subindustry"
            choices={subinstries}
            label="Select a subindustry for your new skill"
            sx={{
              minWidth: '400px',
              '& p': {
                display: 'none',
              },
              '& div': {
                overflow: 'hidden',
                boxSizing: 'border-box',
                paddingRight: '18px',
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ width: '100%', boxSizing: ' border-box' }}>
          <Button type="submit">Submit</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </SimpleForm>
    </Dialog>
  );
};

export default CreateNewSkillDialog;
