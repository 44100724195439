import React from 'react';
import Typography from '@mui/material/Typography';
import { styles } from '../styles';

type EmptyProps = {
  resourceName: string;
};

export const Empty: React.FC<EmptyProps> = ({ resourceName }) => {
  return (
    <div style={styles.emptyResultContainer}>
      <Typography variant="h5">No {resourceName} found</Typography>
    </div>
  );
};
