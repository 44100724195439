import { Datagrid, Filter, FilterProps, ListContextProvider, ListView, TextField, useGetList } from 'react-admin';
import React, { useState } from 'react';
import { Identifier } from 'ra-core';
import Box from '@mui/material/Box';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import FreeTextSearchInput from '../FreeTextSearchInput';
import FilterTextInput from '../FilterTextInput';
import SendNotificationButton from './SendNotificationButton';
import AddProspectSentNotificationButton from './AddProspectSendNotificationButton';
import AddProspectButton from './AddProspectButton';
import { AddProspectProps } from './JobEdit';

const CandidateFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search" />
      <FilterTextInput source="firstName" label="First Name" alwaysOn />
      <FilterTextInput source="lastName" label="Last Name" alwaysOn />
    </Filter>
  );
};

const CandidateBulkActionButtons = (props: AddProspectProps) => {
  return (
    <>
      <SendNotificationButton {...props} resource={RESOURCE_CANDIDATES} />
      <AddProspectSentNotificationButton {...props} />
      <AddProspectButton {...props} />
    </>
  );
};

export const CandidateList = ({ jobId }) => {
  const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);
  const [filter, setFilter] = useState(undefined);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = { field: 'firstName', order: 'ASC' as const };
  const { data, total } = useGetList(RESOURCE_CANDIDATES, {
    filter,
    pagination: { page, perPage },
    sort,
  });

  const setFilters = (newFilters) => {
    setFilter({ ...newFilters });
  };

  const onSelect = (val) => {
    setSelectedIds(val);
  };

  const onToggleItem = (item) => {
    if (selectedIds.includes(item)) {
      //Remove
      setSelectedIds(selectedIds.filter((id) => id !== item));
    } else {
      //Insert
      setSelectedIds([...selectedIds, item]);
    }
  };
  const onUnselectItems = () => {
    setSelectedIds([]);
  };

  // @ts-ignore
  const listCtx: ListControllerResult = {
    data,
    total,
    page,
    perPage,
    setPerPage,
    setPage,
    filterValues: filter,
    setFilters,
    sort,
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  };

  return (
    <Box
      sx={{
        minWidth: '800px',
        '&.list-page': {
          width: '100%',
        },
      }}
    >
      <ListContextProvider value={listCtx}>
        <ListView
          title=" "
          actions={<></>}
          filters={<CandidateFilter />}
          sx={{
            '& .RaFilter-form': {
              width: '100%',
              display: 'contents',
            },
          }}
        >
          <Datagrid bulkActionButtons={<CandidateBulkActionButtons jobId={jobId} selectedIds={selectedIds} />}>
            <TextField source="firstName" sortable={false} />
            <TextField source="lastName" sortable={false} />
            <TextField source="personalEmail" sortable={false} />
            <TextField source="homePhone" sortable={false} />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    </Box>
  );
};
