import * as React from 'react';
import { Button, useRecordContext } from 'react-admin';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

type ParseCVButtonProps = {
  onClick: Function;
  label: string;
  source: string;
  className?: string;
};

const ParseCVButton: React.FC<ParseCVButtonProps> = ({ source, label, onClick, className }) => {
  const record = useRecordContext();
  return record && record[source] && record.documentType === 'cv' ? (
    <Button label={label} onClick={() => onClick({ ...record, fileType: 1 })} className={className}>
      <DocumentScannerIcon />
    </Button>
  ) : null;
};

export default ParseCVButton;
