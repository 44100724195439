import {
  BooleanInput,
  DeleteWithConfirmButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  NumberField,
  TextField,
  useRecordContext,
} from 'react-admin';
import ArchiveIcon from '@mui/icons-material/Archive';
import React from 'react';
import { RESOURCE_JOBS } from '../../provider/restProvider';
import ActionsColumn from '../ActionsColumn';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { JobEdit } from './JobEdit';

// const defaultColumns: string[] = [
//     'ID',
//     'client',
//     'jobTitle',
//     'numberOfPositions',
//     'startDate',
//     'endDate',
//     'region',
//     'updatedByName'
// ];

const rowStyle = (record) => {
  const style = {};
  if (!record) {
    return style;
  }
  return {
    ...style,
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

const JobFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search" />
      <BooleanInput source="IsArchived" label="Archived" alwaysOn />
    </Filter>
  );
};

const JobActions = () => (
  <ListActions showAction={{ selectAutoSave: true }}>
    <JobFilter context="button" />
  </ListActions>
);

const JobDetail = () => {
  const record = useRecordContext();

  return <JobEdit syncWithLocation={false} resource={RESOURCE_JOBS} id={record?.id} />;
};

export const JobsList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Jobs"
      {...props}
      sort={{ field: 'jobTitle', order: 'ASC' }}
      filters={<JobFilter />}
      actions={<JobActions />}
      pagination={<PostPagination />}
      perPage={50}
      filterDefaultValues={{
        IsActive: {
          fieldName: 'IsActive',
          fieldValue: 'true',
          condition: '==',
        },
      }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle
        // optimized={true}
        editComponent={<JobEdit syncWithLocation={false} />}
        rowSx={rowStyle}
        bulkActionButtons={false}
        sx={commonStyles.defaultGridStyle}
        omit={[
          'perDiem',
          'msp',
          'straightTimeBillRate',
          'overtimeBillRate',
          'doubleTimeBillRate',
          'straightTimePayRate',
          'overtimePayRate',
          'doubleTimePayRate',
          'createdByName',
          'updatedByName',
        ]}
      >
        <TextField key="id" source="id" label="Job ID" sortable />
        <TextField key="clientName" source="clientName" label="Client" sortable={false} />
        <TextField key="jobTitle" source="jobTitle" sortable />
        <NumberField key="numberOfPositions" source="numberOfPositions" label="Number of Pos." sortable />
        <TextField key="startDate" source="startDate" label="Start" sortable />
        <TextField key="endDate" source="endDate" label="End" sortable />
        <TextField key="dateAdded" source="dateAdded" sortable />
        <TextField key="region" source="region" sortable />
        <NumberField key="straightTimeBillRate" source="straightTimeBillRate" sortable />
        <NumberField key="overtimeBillRate" source="overtimeBillRate" sortable />
        <NumberField key="perDiem" source="perDiem" sortable />
        <NumberField key="msp" source="msp" sortable={false} />
        <NumberField key="doubleTimeBillRate" source="doubleTimeBillRate" sortable />
        <NumberField key="straightTimePayRate" source="straightTimePayRate" sortable />
        <NumberField key="overtimePayRate" source="overtimePayRate" sortable />
        <NumberField key="doubleTimePayRate" source="doubleTimePayRate" sortable />
        <TextField key="assignedToName" source="assignedToName" label="Assigned To" sortable={false} />
        <TextField key="createdByName" source="createdByName" label="Created By" sortable={false} />
        <TextField key="updatedByName" source="updatedByName" label="Updated By" sortable={false} />

        <ActionsColumn
          label="Actions"
          actionsSettings={{ show: false }}
          customDeleteButton={
            <DeleteWithConfirmButton
              label=""
              confirmTitle="Archive Job"
              confirmContent="Are you sure you want to archive this item?"
              icon={<ArchiveIcon />}
            />
          }
        />
      </DatagridConfigurableRbac>
    </List>
  );
};
