const CHAT_ROOMS_LOADING = 'chat/rooms/loading';
const CHAT_ROOMS_LOADED = 'chat/rooms/loaded';
const CHAT_ROOMS_ERROR = 'chat/rooms/error';
const CHAT_ROOMS_ADD_ROOM = 'chat/rooms/addroom';
const CHAT_ROOM_SELECT = 'chat/rooms/select';
const CHAT_ROOM_ARCHIVE = 'chat/rooms/archive';
const CHAT_ROOM_LOADING = 'chat/rooms/room/loading';
const CHAT_ROOM_LOADED = 'chat/rooms/room/loaded';
const CHAT_ROOM_ERROR = 'chat/rooms/room/error';
const CHAT_ROOMS_CANDIDATES_UPDATE = 'chat/rooms/candidates/update';
const CHAT_ROOMS_FIELDMANAGERS_UPDATE = 'chat/rooms/fieldmanagers/update';
const CHAT_ROOM_MESSAGE_RECEIVED = 'chat/rooms/room/messagereceived';

export default {
  CHAT_ROOMS_LOADING,
  CHAT_ROOMS_LOADED,
  CHAT_ROOMS_ERROR,
  CHAT_ROOMS_ADD_ROOM,
  CHAT_ROOM_SELECT,
  CHAT_ROOM_ARCHIVE,
  CHAT_ROOM_LOADING,
  CHAT_ROOM_LOADED,
  CHAT_ROOM_ERROR,
  CHAT_ROOMS_CANDIDATES_UPDATE,
  CHAT_ROOMS_FIELDMANAGERS_UPDATE,
  CHAT_ROOM_MESSAGE_RECEIVED,
};
