import React, { ReactElement } from 'react';
import { Edit, SaveButton, SimpleForm } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { EditPropsWithLocation } from '../types';
import { RoleForm } from './RoleForm';

const EditToolbar: React.FC = (props) => {
  const { isValid } = useFormState();

  return (
    <FooterToolbar SaveButtonComponent={<SaveButton label="Save" variant="text" disabled={!isValid} />} {...props} />
  );
};

export const RoleEdit: React.FC<EditPropsWithLocation> = ({ ...props }): ReactElement => {
  return (
    <Edit {...props} actions={false} mutationMode="pessimistic">
      <SimpleForm warnWhenUnsavedChanges toolbar={<EditToolbar />} sx={commonStyles.flexVertical}>
        <RoleForm />
      </SimpleForm>
    </Edit>
  );
};
