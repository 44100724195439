import { FC } from 'react';
import { AutocompleteInput, AutocompleteInputProps, ReferenceInput } from 'react-admin';
import { RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';

type RoleInputProps = AutocompleteInputProps;

export const RoleInput: FC<RoleInputProps> = (props) => {
  return (
    <ReferenceInput source="hrRoleId" reference={RESOURCE_ROLES_RA}>
      <AutocompleteInput label="Role" sx={commonStyles.formComponentDefaultMargins} {...props} />
    </ReferenceInput>
  );
};
