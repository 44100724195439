import { FC } from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';
import { isEmpty, isNotEmpty } from '../../../provider/UtilityFunctions';

export interface CustomNumberInputProps extends NumberInputProps {
  isPositive?: boolean;
  fractionDigits?: number;
  defaultNull?: boolean;
}

const CustomNumberInput: FC<CustomNumberInputProps> = ({ isPositive, fractionDigits, defaultNull, sx, ...rest }) => (
  <NumberInput
    {...rest}
    min={isPositive ? 0 : null}
    format={(val) => {
      if (isEmpty(val) && !defaultNull) return 0;
      return fractionDigits !== undefined && isNotEmpty(val) ? Number(val).toFixed(fractionDigits) : val;
    }}
    sx={{
      ...sx,
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
    }}
  />
);

export default CustomNumberInput;
