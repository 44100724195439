import Box from '@mui/material/Box';
import React from 'react';
import { BooleanInput, email, NumberInput, required, TextInput } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import CustomDateInput from '../../CustomDateInput';

export const HeaderTab = () => {
  return (
    <>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="id" label="Candidate ID" sx={commonStyles.flexBoxItem} readOnly />
        <TextInput source="title" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <TextInput source="firstName" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <TextInput source="lastName" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <TextInput source="nickName" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="personalEmail" type="email" sx={commonStyles.flexBoxItem} validate={[required(), email()]} />
        <TextInput source="workEmail" type="email" sx={commonStyles.flexBoxItem} />
        <TextInput source="mobile" sx={commonStyles.flexBoxItem} />
        <TextInput source="homePhone" sx={commonStyles.flexBoxItem} />
        <TextInput source="otherPhone" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="birthday" sx={commonStyles.flexBoxItem} />
        <BooleanInput source="doNotHire" sx={commonStyles.flexBoxItem} />
        <BooleanInput source="hasMilitaryExperience" sx={commonStyles.flexBoxItem} />
        <BooleanInput source="completedWizard" label="Completed Wizard" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="referredBy" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput label="Availability Date" source="availabilityDate" sx={commonStyles.flexBoxItem} />
        <CustomDateInput label="Creation Date" source="creationDate" sx={commonStyles.flexBoxItem} disabled />
        <CustomDateInput label="Last Modified Date" source="lastModifiedDate" sx={commonStyles.flexBoxItem} disabled />
        <CustomDateInput
          source="lastLoginOnMobileAppDate"
          label="Last login in mobile app"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="availabilityDateLastUpdated"
          label="Availability last updated"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="createdByName" label="Created by" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="updatedByName" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
      </Box>
    </>
  );
};
