import { Filter, FilterProps, List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import FreeTextSearchInput from '../FreeTextSearchInput';
import LinkField from '../LinkField';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { ClientEdit } from './ClientEdit';

// const defaultColumns: string[] = [
//     'ID',
//     'name',
//     'phone',
//     'city',
//     'state',
//     'city',
//     'country',
//     'fieldManagedUpdatedName'
// ];

const ClientActions = () => (
  <ListActions showAction={{ selectAutoSave: true }}>
    <ClientFilter context="button" />
  </ListActions>
);

const ClientFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search" />
    </Filter>
  );
};

export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Clients"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ClientFilter />}
      actions={<ClientActions />}
      perPage={50}
      pagination={<PostPagination />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        // optimized={true}
        editComponent={<ClientEdit syncWithLocation={false} />}
        expandSingle
        bulkActionButtons={false}
      >
        <TextField source="id" label="Client ID" />
        <TextField source="name" />
        <LinkField phone source="addresses[0].phone" label="Phone" sortable={false}>
          <TextField source="addresses[0].phone" label="Phone" sortable={false} />
        </LinkField>
        <TextField source="addresses[0].address" label="Address" sortable={false} />
        <TextField source="addresses[0].city" label="City/Town" sortable={false} />
        <TextField source="addresses[0].state" label="State" sortable={false} />
        <TextField source="addresses[0].country" label="Country" sortable={false} />
        <TextField source="fieldManagerCreatedName" label="Created by" sortable={false} />
        <TextField source="fieldManagedUpdatedName" label="Updated by" sortable={false} />

        <ActionsColumn label="Actions" actionsSettings={{ show: false }} />
      </DatagridConfigurableRbac>
    </List>
  );
};
