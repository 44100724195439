import React, { ReactElement } from 'react';
import { Create, CreateProps, SaveButton, SimpleForm } from 'react-admin';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { RoleForm } from './RoleForm';

export const RoleCreate: React.FC<CreateProps> = (props): ReactElement => (
  <Create {...props} actions={false}>
    <SimpleForm
      warnWhenUnsavedChanges
      sx={commonStyles.flexVertical}
      toolbar={
        <FooterToolbar
          SaveButtonComponent={
            <SaveButton label="Save" variant="text" transform={(data) => ({ ...data, isActive: true })} />
          }
          {...props}
        />
      }
    >
      <RoleForm />
    </SimpleForm>
  </Create>
);
