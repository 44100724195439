import { FC } from 'react';
import { DeleteWithConfirmButton, DeleteWithConfirmButtonProps, useRecordContext } from 'react-admin';

type DeletePermissionButtonProps = Omit<DeleteWithConfirmButtonProps, 'confirmTitle' | 'label' | 'children'>;

export const DeletePermissionButton: FC<DeletePermissionButtonProps> = (props) => {
  const record = useRecordContext();

  return <DeleteWithConfirmButton confirmTitle={`Delete permission #${record?.id}`} label="" {...props} />;
};
