import { Datagrid, FormTabProps, ReferenceManyField, RichTextField, TextField } from 'react-admin';
import React from 'react';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import ActionsColumn from '../ActionsColumn';
import { CommentCreate } from './CommentCreate';

type CommentsTabProps = Omit<FormTabProps, 'label' | 'id'> & {
  reference: string;
};

export const CommentsTab: React.FC<CommentsTabProps> = ({ reference, ...rest }) => {
  return (
    <LazyFormTab {...rest} label="Comments" id="comments_tab">
      <ReferenceManyField
        reference={reference}
        target="comments"
        label=""
        perPage={100}
        sort={{ field: 'lastModifiedDate', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false} sx={commonStyles.subGridDefaultStyle} rowClick={false}>
          <RichTextField source="text" label="Comment" sortable={false} />
          <RichTextField source="fieldManagerName" label="Comment Author" sortable={false} />
          <TextField source="lastModifiedDate" sortable={false} />
          <ActionsColumn label="Actions" actionsSettings={{ edit: false, show: false }} />
        </Datagrid>
      </ReferenceManyField>

      <CommentCreate resource={reference} />
    </LazyFormTab>
  );
};
