import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';
import { UserForm } from './UserForm';

type UserCreateProps = Omit<CreateProps, 'children'>;

export const UserCreate: FC<UserCreateProps> = (props) => {
  return (
    <Create title="Create User" {...props}>
      <UserForm createMode />
    </Create>
  );
};
