import { FC } from 'react';
import { EmailField, List, ListProps, ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';
import { RESOURCE_CLIENTS_RA, RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';
import ActionsColumn from '../ActionsColumn';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import { UserEdit } from './UserEdit';
import { UserShow } from './UserShow';
import { DeleteUserButton } from './DeleteUserButton';
import { UserListActions } from './UserListActions';
import { UserFilter } from './UserFilter';

export const UserList: FC<ListProps> = (props) => {
  return (
    <List
      title="Users"
      actions={<UserListActions />}
      filters={<UserFilter />}
      pagination={<PostPagination />}
      sort={{ field: 'firstName', order: 'ASC' }}
      perPage={50}
      sx={commonStyles.raFilterForm}
      {...props}
    >
      <DatagridConfigurableRbac
        editComponent={<UserEdit title=" " syncWithLocation={false} />}
        showComponent={<UserShow title=" " {...props} />}
        bulkActionButtons={false}
        rowClick={false}
        expandSingle
      >
        <TextField source="id" label="ID" sortable />
        <TextField source="title" label="Title" sortable={false} />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <EmailField source="email" label="Email" sortable={false} />
        <ReferenceArrayField source="clients" reference={RESOURCE_CLIENTS_RA} label="Clients" sortable={false}>
          <SingleFieldList linkType={false} />
        </ReferenceArrayField>
        <ReferenceArrayField source="roles" reference={RESOURCE_ROLES_RA} label="Roles" sortable={false}>
          <SingleFieldList linkType="show" />
        </ReferenceArrayField>
        <TextField source="phone" label="Phone Number" sortable={false} />
        <ActionsColumn label="Actions" customDeleteButton={<DeleteUserButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
