import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import { Button, Identifier, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { AxiosResponse } from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useChatContext } from '../../components/Messaging/ChatContextProvider';
import api from '../../provider/api';
import { colors, shadows } from '../../theme';
import { AddProspectProps } from './JobEdit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: shadows.main,
  p: 4,

  '& h2': {
    color: colors.text.main,
    fontSize: '1rem',
    marginBottom: '1rem',
  },

  '& div': {
    marginBottom: '0.5rem',
  },
};

const addProspect = async (jobId: string, selectedIds: Identifier[]) => {
  const jobResponseObject = selectedIds.map((selectedCandidateId) => {
    return {
      id: 0,
      candidateName: '',
      candidateId: selectedCandidateId,
      status: 'Selected as Prospect',
      jobId,
      jobTitle: 'Job One',
      prospectStatusId: 1,
    };
  });
  try {
    const response: AxiosResponse = await api.jobresponses.create(jobResponseObject);
    if (response.status === 200) {
      const { data } = response;
      return Promise.resolve({
        data,
      });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
// window URL
const AddProspectSendNotificationButton: FC<AddProspectProps> = ({
  selectedIds = [],
  jobId,
  isRenderContentOnly = false,
  resource,
  onSuccess,
}) => {
  const [open, setOpen] = useState(isRenderContentOnly);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);
  const { sendMessage } = useChatContext();
  const [textMessage, setTextMessage] = useState('');

  const notifyCandidatesByChat = () => {
    const notifyCandidates = selectedIds.map((selectedCandidateId) => {
      return sendMessage(parseInt(selectedCandidateId as string), `${textMessage} candidate/joblink/${jobId}`);
    });

    return notifyCandidates;
  };

  return (
    <>
      {!isRenderContentOnly && (
        <Button label="Add prospects and notify" onClick={handleOpen} style={{ marginRight: '1rem' }} disabled={false}>
          <NotificationsActive />
        </Button>
      )}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter Message to notify candidate
            </Typography>
            <TextField
              id="outlined-basic"
              label="Enter Message"
              variant="outlined"
              fullWidth
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
            />

            <Button
              onClick={() => {
                addProspect(jobId, selectedIds);
                notifyCandidatesByChat();
                setTimeout(() => {
                  notify('Candidate added as prospect and notification sent', {
                    type: 'info',
                    undoable: false,
                  });
                  unselectAll();
                  refresh();
                  if (onSuccess) onSuccess();
                }, 500);
              }}
              label="Notify Candidate"
            >
              <NotificationsActive />
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

AddProspectSendNotificationButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddProspectSendNotificationButton;
