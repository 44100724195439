import * as React from 'react';
import { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { TextInputProps, useInput, useListContext } from 'react-admin';
import { debounce } from 'lodash';
import { capitalizeFirstLetter } from '../provider/UtilityFunctions';

const FilterTextInput: React.FC<TextInputProps> = (props) => {
  const { field } = useInput({ source: props.source });
  const { filterValues, setFilters } = useListContext();

  const getDefaultValue = () => {
    if (field.value == 'object') {
      return field.value.fieldValue || '';
    }
    return '';
  };
  const [value, setValue] = React.useState(getDefaultValue());

  const debouncedSetFilters = debounce(setFilters, 300);

  const updatedField = useCallback(
    (value) => {
      // field.onChange(value); //Don't call this. It will override filterValues
      const newFilters = filterValues || {};

      delete newFilters[props.source];
      if (value) {
        newFilters[props.source] = {
          fieldName: capitalizeFirstLetter(props.source),
          fieldValue: value,
          condition: '==',
        };
      }
      debouncedSetFilters(newFilters);
    },
    [filterValues]
  );

  return (
    <span style={{ marginBottom: 5 }}>
      <div>
        <TextField
          variant="outlined"
          // name={props.input.name}
          label={props.label}
          value={value}
          onChange={(ev) => {
            ev.persist();
            setValue(ev.target.value);
            updatedField(ev.target.value);
          }}
        />
      </div>
    </span>
  );
};

export default FilterTextInput;
