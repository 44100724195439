import { FieldProps } from 'ra-ui-materialui/src/field/types';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useRecordContext } from 'react-admin';

interface Props extends FieldProps {
  size?: number;
}

const AvatarColumnField: React.FC<Props> = ({ source, size }) => {
  const record = useRecordContext();
  return record && record[source] ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}
    >
      <Avatar
        src={record[source]}
        style={{ width: size, height: size }}
        //ToDo: Check this
        sx={
          {
            // marginRight: theme.spacing(1),
            // marginTop: -theme.spacing(0.5),
            // marginBottom: -theme.spacing(0.5),
          }
        }
      />
    </Box>
  ) : null;
};

export default AvatarColumnField;

AvatarColumnField.defaultProps = {
  size: 25,
};
