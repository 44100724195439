import {
  CreateButton,
  DeleteWithConfirmButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import React, { FC } from 'react';
import { PostPagination } from '../PostPagination';
import ActionsColumn from '../ActionsColumn';
import { commonStyles } from '../CommonStyles';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { renderAuditFields } from '../AuditFields/renderAuditFields';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { RoleEdit } from './RoleEdit';
import { RoleShow } from './RoleShow';

type RoleFilterProps = Omit<FilterProps, 'children'>;
const RoleFilter: FC<RoleFilterProps> = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn>
        <TextInput source="q" label="Search" />,
      </SearchInput>
    </Filter>
  );
};

const RoleActions: FC = () => {
  return (
    <ListActions showAction={{ selectAutoSave: true, export: false }}>
      <RoleFilter context="button" />
    </ListActions>
  );
};

const DeleteRoleButton: React.FC = () => {
  const record = useRecordContext();
  return <DeleteWithConfirmButton label="" confirmTitle={`Delete role #${record?.id}?`} />;
};

export const RoleList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Roles"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      exporter={false}
      perPage={50}
      pagination={<PostPagination />}
      actions={<RoleActions />}
      filters={<RoleFilter />}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        editComponent={<RoleEdit syncWithLocation={false} title=" " />}
        showComponent={<RoleShow {...props} />}
        rowClick={false}
        bulkActionButtons={false}
      >
        <TextField source="id" sortable label="ID" />
        <TextField source="name" sortable label="Role" />
        {/*{renderAuditFields()}*/}
        <ActionsColumn label="Actions" customDeleteButton={<DeleteRoleButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
