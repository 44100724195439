export const permissionTypeChoices = [
  { id: 'allow', name: 'Allow' },
  { id: 'deny', name: 'Deny' },
];

export const actionPermissions = {
  list: 'list',
  create: 'create',
  edit: 'edit',
  show: 'show',
  delete: 'delete',
  forceDelete: 'forceDelete',
  export: 'export',
  import: 'import',
};

export const actionChoices = [
  { id: '*', name: 'All' },
  { id: actionPermissions.list, name: 'List' },
  { id: actionPermissions.create, name: 'Create' },
  { id: actionPermissions.edit, name: 'Edit' },
  { id: actionPermissions.show, name: 'Show' },
  { id: actionPermissions.delete, name: 'Delete' },
  { id: actionPermissions.export, name: 'Export' },
  { id: actionPermissions.import, name: 'Import' },
];
