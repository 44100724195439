import IconClose from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import { Identifier } from 'ra-core';
import React, { useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  ReferenceManyField,
  TextField,
  useNotify,
} from 'react-admin';
import api from '../../../../provider/api';
import { RESOURCE_PLACEMENT_DOCUMENTS } from '../../../../provider/restProvider';
import DownloadButton from '../../../Candidate/DownloadButton';
import { styles } from './styles';

const showPdf = (pdf) => {
  const newBlob = new Blob([pdf], { type: 'application/pdf;base64' });
  window.open(URL.createObjectURL(newBlob));
};

type DocumentsTabProps = {
  recordId: Identifier;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ recordId }) => {
  const notify = useNotify();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [letterLink, setLetterLink] = useState('');

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    notify('Copied to clipboard!');
  };

  const getPreviewLetter = async (type, id) => {
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.getCandidatePreview;
        break;
      case 'employer':
        request = api.documents.getEmployerPreview;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        showPdf(response.data);
      } else {
        notify('Unable to fetch preview ' + response.message);
      }
    } catch (e) {
      notify('Unable to fetch preview ' + JSON.parse(new TextDecoder().decode(e.response.data)).message || e);
    }
  };

  const sendLetter = async (type, id) => {
    setIsLoading(true);
    setDialogOpen(true);
    setConfirmDialog({ open: false, type: '' });
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.sendCandidateLetter;
        break;
      case 'employer':
        request = api.documents.sendEmployerLetter;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        setLetterLink(response.data);
      } else {
        setDialogOpen(false);
        notify('Unable to send letter ' + response.message);
      }
    } catch (e) {
      setDialogOpen(false);
      notify('Unable to send letter ' + e.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.lettersInfoContainer, borderRight: '1px solid grey' }}>
          <h2>Candidate Letter</h2>
          <p>Letter sent to candidate upon offer of placement</p>
          <Box sx={styles.buttonsContainer}>
            <Button label="Preview" onClick={() => getPreviewLetter('candidate', recordId)} />
            <Button label="Send" onClick={() => setConfirmDialog({ open: true, type: 'candidate' })} color="primary" />
          </Box>
        </Box>
        <Box sx={styles.lettersInfoContainer}>
          <h2>Employer Letter</h2>
          <p>Letter sent to employer upon offer of placement</p>
          <Box sx={styles.buttonsContainer}>
            <Button label="Preview" onClick={() => getPreviewLetter('employer', recordId)} />
            <Button label="Send" onClick={() => setConfirmDialog({ open: true, type: 'employer' })} color="primary" />
          </Box>
        </Box>
        <Box sx={styles.previousLettersContainer}>
          <h2>Previously sent documents</h2>
          <p>Any unsigned documents for this placement will appear here</p>
          <ReferenceManyField
            reference={RESOURCE_PLACEMENT_DOCUMENTS}
            target="documents"
            label=""
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <Datagrid
              rowClick={false}
              bulkActionButtons={false}
              rowSx={() => ({ '& button': { backgroundColor: 'red' } })}
              sx={styles.datagrid}
            >
              <TextField source="documentType" label="Type" sx={{ textTransform: 'capitalize' }} />
              <TextField source="name" label="File Name" />
              <DateField source="creationDate" />
              <FunctionField
                label="Sign link"
                render={(record) =>
                  record.signatureURL ? (
                    <Button label="Copy link to sign" onClick={() => copyLink(record.signatureURL)} />
                  ) : (
                    'No signature link available'
                  )
                }
              />
              <DownloadButton source="url" label="Download" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Box>
      </Box>
      <Dialog open={dialogOpen}>
        <DialogTitle>{isLoading ? 'Document Sending' : 'Document Sent'}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <p>Letter Successfully sent! Click below to copy a link to sign the document.</p>
              <Button label="Copy Link" onClick={() => copyLink(letterLink)} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} label="Close" startIcon={<IconClose />} />
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={confirmDialog.open}
        title="Send Letter?"
        content={`Sending this letter is irreversible. Emails will be sent to both the ${confirmDialog.type} and the field manager.`}
        onConfirm={() => sendLetter(confirmDialog.type, recordId)}
        onClose={() => setConfirmDialog({ open: false, type: '' })}
        confirm="Send"
      />
    </>
  );
};
