import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Identifier } from 'ra-core';
import React from 'react';
import {
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_CLIENT_EXPERIENCE,
  RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE,
  RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_PROJECT_EXPERIENCE,
  RESOURCE_LOOKUP_WORK_EXPERIENCE,
} from '../../../provider/restProvider';
import { styles } from '../styles';
import { SimpleDatagrid } from '../../Common/SimpleDatagrid/SimpleDatagrid';

type ExperienceTabProps = {
  candidateId: Identifier;
};

export const ExperienceTab: React.FC<ExperienceTabProps> = ({ candidateId }) => {
  return (
    <Box sx={styles.tabContentContainer}>
      <SimpleDatagrid
        resource={RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}
        lookupResource={RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE}
        label="Oem Experience Type"
        defaultValues={{ candidateId }}
      />
      <Box sx={{ ...styles.tabContentChildContainer, flexDirection: 'column' }}>
        <Box sx={styles.tabContentChildContainer}>
          <SimpleDatagrid
            resource={RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}
            lookupResource={RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE}
            label="Primary Work Experience"
            defaultValues={{ candidateId }}
          />
          <SimpleDatagrid
            resource={RESOURCE_CANDIDATE_WORK_EXPERIENCE}
            lookupResource={RESOURCE_LOOKUP_WORK_EXPERIENCE}
            label="Work Experience"
            defaultValues={{ candidateId }}
          />
        </Box>
        <Divider />
        <Box sx={styles.tabContentChildContainer}>
          <SimpleDatagrid
            resource={RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}
            lookupResource={RESOURCE_LOOKUP_CLIENT_EXPERIENCE}
            label="Client Experience"
            defaultValues={{ candidateId }}
          />
          <SimpleDatagrid
            resource={RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}
            lookupResource={RESOURCE_LOOKUP_PROJECT_EXPERIENCE}
            label="Project Experience"
            defaultValues={{ candidateId }}
          />
        </Box>
      </Box>
    </Box>
  );
};
