import * as React from 'react';
import { Button, useRecordContext } from 'react-admin';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

type DownloadButtonProps = {
  source: string;
  label?: string;
  sortable?: boolean;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ label, source }) => {
  const record = useRecordContext();

  return record && record[source] ? (
    <a href={record[source]} target="_blank" rel="noreferrer">
      <Button label={label}>
        <CloudDownloadIcon />
      </Button>
    </a>
  ) : null;
};

export default DownloadButton;
