import React, { useState } from 'react';
import { Button, useRecordContext } from 'react-admin';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteCandidatesDialog from '../FavoriteCandidatesDialog';

export const FavoriteCandidateButton: React.FC = () => {
  const record = useRecordContext();
  const [isOpen, setIsOpen] = useState(false);
  const title = `${record?.firstName} ${record?.lastName}'s favorite candidates`;
  return (
    <>
      <Button label="" onClick={() => setIsOpen(true)}>
        <FavoriteBorderIcon />
      </Button>
      {isOpen && (
        <FavoriteCandidatesDialog clientContactId={record?.id} title={title} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};
