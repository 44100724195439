import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { DialogContent } from '@mui/material';

import {
  Button,
  Confirm,
  Datagrid,
  Identifier,
  TextField,
  useGetMany,
  useGetManyReference,
  useNotify,
  useRecordContext,
} from 'react-admin';
import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import React from 'react';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch } from 'react-redux';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { RESOURCE_CANDIDATES, RESOURCE_CLIENT_CONTACT_CANDIDATES } from '../../provider/restProvider';
import api from '../../provider/api';
import { sleep } from '../../provider/UtilityFunctions';
import { addToBasket } from '../../redux/actions/basketActions';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';

export interface FavoriteCandidatesDialogProps {
  clientContactId: Identifier;
  title: string;
  onClose: () => void;
}

interface RemoveButtonProps extends FieldProps {
  clientContactId: Identifier;
  refetch: () => void;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ refetch, clientContactId }) => {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const record = useRecordContext();

  const handleConfirm = async () => {
    try {
      await api.clientContacts.removeFavoriteCandidate(clientContactId, record?.id);
      setOpen(false);
      refetch();
    } catch (e) {
      notify("Couldn't remove candidate from list", {
        type: 'warning',
        undoable: false,
      });
    }
  };

  return (
    <>
      <Button onClick={handleClick}>
        <RemoveCircleIcon fontSize="small" />
      </Button>
      <Confirm
        isOpen={open}
        title="Remove confirmation"
        content={`Are you sure remove ${record ? record.firstName : ''} from favorite list`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

interface AddToBasketButtonProps {
  ids: Identifier[];
}

const AddToBasketButton: React.FC<AddToBasketButtonProps> = ({ ids }) => {
  const notify = useNotify();
  const { data, isLoading, error } = useGetMany(RESOURCE_CANDIDATES, { ids });

  const dispatch = useDispatch();

  const onClicked = async () => {
    while (isLoading) {
      await sleep(100);
      console.log('Waiting for getMany resource is ready:', RESOURCE_CANDIDATES);
    }
    if (!error && data.length > 0) {
      dispatch(addToBasket(RESOURCE_CANDIDATES, data));
      notify('Added to Basket', { type: 'info', undoable: false });
    }
  };

  return (
    <Button label="Add to basket" onClick={onClicked} disabled={isLoading || ids == undefined || ids.length == 0}>
      <ShoppingBasketIcon />
    </Button>
  );
};

const FavoriteCandidatesDialog: React.FC<FavoriteCandidatesDialogProps> = ({ clientContactId, title, onClose }) => {
  const { data, total, refetch } = useGetManyReference(RESOURCE_CLIENT_CONTACT_CANDIDATES, {
    target: RESOURCE_CLIENT_CONTACT_CANDIDATES,
    id: clientContactId,
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
    sort: { field: 'firstName', order: 'ASC' },
  });

  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Datagrid data={data} total={total} optimized empty={<div> No candidate found </div>} rowClick={false}>
          <TextField source="firstName" label="First Name" sortable={false} />
          <TextField source="lastName" label="Last Name" sortable={false} />
          <RemoveButton clientContactId={clientContactId} refetch={refetch} />
        </Datagrid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        {data && <AddToBasketButton ids={data.map((x) => x.id)} />}
        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FavoriteCandidatesDialog;
