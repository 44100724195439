import { FC } from 'react';
import { BooleanInput, Filter, FilterProps } from 'react-admin';
import CustomDateInput from '../CustomDateInput';
import FreeTextSearchInput from '../FreeTextSearchInput';

type PlacementFilterProps = Omit<FilterProps, 'children'>;

export const PlacementFilter: FC<PlacementFilterProps> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search" />
      <BooleanInput source="IsArchived" label="Archived" alwaysOn />
      <BooleanInput source="isPending" label="Pending" alwaysOn />
      <CustomDateInput source="startDate" label="Start Date" />
      <CustomDateInput source="endDate" label="End Date" />
    </Filter>
  );
};
