import { BooleanInput, useListContext } from 'react-admin';
import * as React from 'react';

export const PendingCheckboxInput = () => {
  const { filterValues, setFilters } = useListContext();

  const onChange = (e) => {
    const newFilter = {
      isPending: {
        fieldName: 'IsPending',
        fieldValue: e.target.checked.toString(),
        condition: '==',
      },
    };
    setFilters({ ...filterValues, ...newFilter });
  };

  return (
    <BooleanInput
      label="Pending"
      source="IsPending"
      defaultValue={filterValues.isPending?.fieldValue === 'true'}
      onChange={onChange}
    />
  );
};
