import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';
import { RESOURCE_PERMISSIONS_RA } from '../../provider/restProvider';
import { PermissionForm } from './PermissionForm';

type PermissionCreateProps = Omit<CreateProps, 'children'>;

export const PermissionCreate: FC<PermissionCreateProps> = (props) => {
  return (
    <Create title="Role Permission Create" resource={RESOURCE_PERMISSIONS_RA} {...props}>
      <PermissionForm />
    </Create>
  );
};
