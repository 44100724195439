import { useDispatch, useSelector } from 'react-redux';
import { Button, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { AxiosResponse } from 'axios';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import React from 'react';
import { RootState } from '../../redux/reducers/initialState';
import api from '../../provider/api';
import { ROOM_CHAT_MESSAGES_INITIAL_STATE } from '../../redux/reducers/chatkitReducer';
import { sleep } from '../../provider/UtilityFunctions';
import types from '../../redux/actions/types';
import { updateChatCandidates } from '../../redux/actions/chatkitActions';

type CandidateMessageButtonProps = {
  label?: string;
};

const CandidateMessageButton: React.FC<CandidateMessageButtonProps> = ({ label }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const record = useRecordContext();

  const candidateId = record?.id;

  const rooms = useSelector((state: RootState) => state.chatkit.rooms);
  const chatRooms = Object.values(rooms).filter((room) => room.candidateId === candidateId);
  let roomId = chatRooms.length > 0 ? chatRooms[0].id : undefined;

  const onClick = async () => {
    if (!roomId) {
      const response: AxiosResponse = await api.chat.createChatRoom(candidateId);
      if (response.status === 200 && response.data.isSuccess) {
        const room = response.data.model;
        roomId = room.id;
        dispatch({
          type: types.chatkit.CHAT_ROOMS_ADD_ROOM,
          room: { ...room, ...ROOM_CHAT_MESSAGES_INITIAL_STATE },
        });
        // @ts-ignore
        dispatch(updateChatCandidates(candidateId, notify));
      }
    }
    await sleep(100);
    redirect(`/messaging/${roomId}`);
  };
  return (
    <Button label={label} variant="text" disabled={!record?.lastLoginOnMobileAppDate} onClick={onClick}>
      <ChatBubbleIcon />
    </Button>
  );
};

export default CandidateMessageButton;
