import * as React from 'react';
import Box from '@mui/material/Box';
import { DeleteWithConfirmButton, EditButton, FieldProps, ShowButton, usePermissions } from 'react-admin';
import { useResourceContext } from 'ra-core';
import { canAccess, Permissions } from '@react-admin/ra-rbac';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { commonStyles } from './CommonStyles';

type ActionsSettings = {
  show?: boolean;
  edit?: boolean;
  delete?: boolean;
};

interface ActionsColumnProps extends Omit<FieldProps, 'source'> {
  children?: ReactNode;
  actionsSettings?: ActionsSettings;
  customDeleteButton?: ReactElement;
  customEditButton?: ReactElement;
  renderChildrenOnRightSide?: boolean;
}

const defaultActionsSettings: ActionsSettings = {
  show: true,
  edit: true,
  delete: true,
};

const filterActions = (actionsSettings: ActionsSettings, permissions: Permissions, resource: string) =>
  Object.entries({
    ...defaultActionsSettings,
    ...actionsSettings,
  }).reduce((acc, [action, isVisible]) => {
    acc[action] =
      isVisible &&
      canAccess({
        permissions,
        action,
        resource,
      });
    return acc;
  }, {} as ActionsSettings);

const ActionsColumn: FC<ActionsColumnProps> = ({
  children,
  actionsSettings,
  customDeleteButton,
  customEditButton,
  renderChildrenOnRightSide = false,
}) => {
  const { permissions } = usePermissions();
  const resource: string = useResourceContext();

  const filteredActions = useMemo(
    () => filterActions(actionsSettings, permissions, resource),
    [actionsSettings, permissions, resource]
  );

  return (
    <Box sx={commonStyles.columnActionsBox}>
      {!renderChildrenOnRightSide && children}
      {filteredActions.show && <ShowButton label="" />}
      {filteredActions.edit && (customEditButton || <EditButton label="" />)}
      {filteredActions.delete && (customDeleteButton || <DeleteWithConfirmButton redirect={false} label="" />)}
      {renderChildrenOnRightSide && children}
    </Box>
  );
};

export default ActionsColumn;
