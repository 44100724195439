import { Identifier } from 'ra-core';
import React from 'react';
import { Datagrid, DateField, ReferenceManyField, TextField } from 'react-admin';
import { RESOURCE_CANDIDATE_DOCUMENTS } from '../../../provider/restProvider';
import ActionsColumn from '../../ActionsColumn';
import { commonStyles } from '../../CommonStyles';
import ImportSkillsDialog from '../../Skills/ImportSkillsDialog';
import DownloadButton from '../DownloadButton';
import ParseCVButton from '../ParseCVButton';
import { UploadDocument } from '../UploadDocument';

type DocumentsTabProps = {
  candidateId: Identifier;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ candidateId }) => {
  const [skillsDialogOpen, setSkillsDialogOpen] = React.useState(false);
  const [document, setDocument] = React.useState(false);

  const setDocumentId = (document) => {
    setDocument(document);
    setSkillsDialogOpen(true);
  };

  const closeDialog = () => {
    setSkillsDialogOpen(!skillsDialogOpen);
    setDocument(false);
  };

  return (
    <>
      <ReferenceManyField
        reference={RESOURCE_CANDIDATE_DOCUMENTS}
        target="documents"
        label=""
        perPage={100}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick={false} bulkActionButtons={false} sx={commonStyles.subGridDefaultStyle}>
          <TextField source="documentType" label="Type" sx={{ textTransform: 'capitalize' }} />
          <TextField source="name" label="File Name" />
          <DateField source="creationDate" />
          <ActionsColumn actionsSettings={{ edit: false, show: false }} label="Actions">
            <ParseCVButton label="Parse CV" source="id" onClick={setDocumentId} className="parse-cv-button" />
            <DownloadButton source="url" label="" />
          </ActionsColumn>
        </Datagrid>
      </ReferenceManyField>

      <UploadDocument idField="candidateId" idValue={'' + candidateId} resource={RESOURCE_CANDIDATE_DOCUMENTS} />
      <ImportSkillsDialog isOpen={skillsDialogOpen} onClose={closeDialog} document={document} />
    </>
  );
};
