import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Identifier } from 'ra-core';
import React from 'react';
import { DeleteWithConfirmButton, ReferenceManyField, TextField } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { LookupRowForm } from '../../Candidate/CandidateCommon';

type SimpleDatagridProps = {
  resource: string;
  lookupResource: string;
  defaultValues: object;
  label: string;
  target?: string;
};

export const SimpleDatagrid: React.FC<SimpleDatagridProps> = ({
  resource,
  lookupResource,
  defaultValues,
  label,
  target = resource,
}) => {
  return (
    <ReferenceManyField reference={resource} target={target} perPage={100}>
      <EditableDatagrid
        bulkActionButtons={false}
        editForm={<LookupRowForm lookupResource={lookupResource} />}
        createForm={<LookupRowForm lookupResource={lookupResource} defaultValues={defaultValues} />}
        actions={<DeleteWithConfirmButton redirect={false} label="" />}
        sx={commonStyles.formTabSimpleDatagrid}
        rowClick={false}
      >
        <TextField source="name" label={label} sortable={false} />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};
