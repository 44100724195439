import {
  Button,
  Datagrid,
  EmailField,
  Link,
  List,
  ReferenceManyField,
  TextField,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import { RESOURCE_CLIENT_CONTACTS, RESOURCE_PROJECT_CONTACTS } from '../../provider/restProvider';
import ActionsColumn from '../ActionsColumn';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import LinkField from '../LinkField';
import { PostPagination } from '../PostPagination';
import ListActions from '../Common/ListActions/ListActions';
import { EditButton } from './components/EditButton';
import { EmptyContactsView } from './components/EmptyContactsView';
import { FavoriteCandidateButton } from './components/FavoriteCandidateButton';
import { AddProjectContacts } from './components/AddProjectContacts';

interface Props {
  clientId?: string | number;
  projectId?: string | number;
}

const ClientContactListActions = ({ clientId }) => {
  const refresh = useRefresh();

  return (
    <ListActions
      showAction={{ export: false }}
      showActionProps={{ create: { targetId: clientId, targetField: 'clientId' } }}
    >
      <Button
        label="Refresh"
        onClick={() => {
          refresh();
        }}
      >
        <RefreshIcon />
      </Button>
    </ListActions>
  );
};

const renderDataGrid = (clientId, projectId) => {
  return (
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <TextField source="firstName" label="First Name" sortable={clientId && true} />
      <TextField source="lastName" label="Last Name" sortable={clientId && true} />
      <TextField source="jobTitle" label="Job Title" sortable={clientId && true} />
      <EmailField source="addresses[0].email" label="Email" sortable={false} />
      <LinkField phone source="addresses[0].phone" label="Phone" sortable={false}>
        <TextField source="addresses[0].phone" label="Phone" sortable={false} />
      </LinkField>
      <EmailField source="addresses[0].alternativeEmail" label="Alt Email" sortable={false} />
      <TextField source="addresses[0].alternativePhone" label="Alt Phone" sortable={false} />
      <TextField source="addresses[0].address" label="Address" sortable={false} />
      <TextField source="addresses[0].country" label="Country" sortable={false} />
      <TextField source="addresses[0].state" label="State" sortable={false} />
      <TextField source="addresses[0].city" label="City" sortable={false} />

      <TextField source="fieldManagedUpdatedName" label="Updated By" sortable={false} />
      <ActionsColumn
        label="Actions"
        actionsSettings={{ show: false }}
        customEditButton={<EditButton clientId={clientId} projectId={projectId} />}
      >
        <FavoriteCandidateButton />
      </ActionsColumn>
    </Datagrid>
  );
};

const renderClientContacts = (clientId) => {
  return (
    <List
      title=" "
      resource={RESOURCE_CLIENT_CONTACTS}
      sort={{ field: 'firstName', order: 'ASC' }}
      disableSyncWithLocation
      actions={<ClientContactListActions clientId={clientId} />}
      empty={<EmptyContactsView clientId={clientId} />}
      perPage={100}
      pagination={<PostPagination />}
      filter={{
        clientId: {
          fieldName: 'clientId',
          fieldValue: '' + clientId,
          condition: '==',
        },
      }}
    >
      {renderDataGrid(clientId, undefined)}
    </List>
  );
};

const renderProjectContacts = (projectId, clientId) => {
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <AddProjectContacts projectId={projectId} />
      </Box>
      <ReferenceManyField
        reference={RESOURCE_PROJECT_CONTACTS}
        target="projectcontacts"
        label=""
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'firstName', order: 'ASC' }}
      >
        {renderDataGrid(clientId, projectId)}
      </ReferenceManyField>
    </>
  );
};

export const ClientContactList: React.FC<Props> = ({ clientId, projectId }) => {
  const record = useRecordContext();

  if (!clientId && !projectId) {
    return <div> Do not render alone</div>;
  } else {
    return projectId ? renderProjectContacts(projectId, record?.clientId) : renderClientContacts(clientId);
  }
};
