import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';
import { DateInputProps, useInput, ValidationError } from 'react-admin';
import { DEFAULT_DATE_FORMAT } from '../provider/constants';
import { capitalizeFirstLetter, convertDateToStr } from '../provider/UtilityFunctions';

type CustomDateInputProps = DateInputProps & {
  label: string;
  sx?: SxProps<Theme>;
};

const CustomDateInput: FC<CustomDateInputProps> = ({ source, sx, disabled, label, validate }) => {
  const {
    field: { value, onChange },
    fieldState: { error: fieldError, invalid },
    isRequired,
  } = useInput({ source, validate });

  const handleChange = (newVal: Dayjs, keyboardInputValue: string) => {
    let value = newVal;
    // If date is not valid, try setting the month in the correct format and then try again with a new string
    if (newVal && !newVal.isValid()) {
      const formattedString = capitalizeFirstLetter(keyboardInputValue.toLowerCase());
      value = dayjs(formattedString, DEFAULT_DATE_FORMAT);
    }
    onChange(convertDateToStr(value));
  };

  return (
    <DatePicker
      disableMaskedInput
      label={label + (isRequired ? ' *' : '')}
      mask="aaa/99/9999"
      inputFormat={DEFAULT_DATE_FORMAT}
      value={value || null}
      onChange={handleChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          error={invalid}
          // `zero-width space` is needed to leave space for helperText and create a permanent bottom padding. The same approach is used in TextInput
          helperText={<ValidationError error={fieldError?.message || '​'} />}
        />
      )}
    />
  );
};

export default CustomDateInput;
