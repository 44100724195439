import { FC, ReactNode } from 'react';
import { ListButton, ListButtonProps, TopToolbar } from 'react-admin';

export type ShowActionsProps = Omit<ListButtonProps, 'children'> & {
  children?: ReactNode | ReactNode[];
};

const ShowActions: FC<ShowActionsProps> = ({ children, ...rest }) => (
  <TopToolbar>
    {children}
    <ListButton label="Back" icon={null} {...rest} />
  </TopToolbar>
);

export default ShowActions;
