import Box from '@mui/material/Box';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Edit,
  EditProps,
  NumberInput,
  required,
  TabbedForm,
  TextInput,
  useGetList,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import {
  RESOURCE_CLIENT_COMMENTS,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_CLIENTS,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
} from '../../provider/restProvider';
import { ClientContactList } from '../ClientContacts/ClientContactList';
import { CommentsTab } from '../Comment/CommentsTab';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { SimpleDatagrid } from '../Common/SimpleDatagrid/SimpleDatagrid';
import { commonStyles } from '../CommonStyles';
import CustomAutoCompleteLookup from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { DocumentsTab } from './tabs/DocumentsTab';
import { ProjectsTab } from './tabs/ProjectsTab';
import '../CommonCss.css';

export const CLIENT_CONTACTS_TAB_IND = 2;

export interface ClientEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export const ClientEdit: React.FC<ClientEditProps> = ({ syncWithLocation, ...props }) => {
  const { id } = useParams();
  const record = useRecordContext();
  const [clientId, setClientId] = useState(id || record?.id);

  const { data } = useGetOne(RESOURCE_CLIENTS, { id: id || record?.id });
  const [country, setCountry] = useState(get(data, 'addresses[0].country', undefined) || 'NoCountry');
  const [state, setState] = useState(get(data, 'addresses[0].state', undefined) || 'NoState');

  const { data: countryData, isSuccess: countryLoaded } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  useEffect(() => {
    if (data) {
      setCountry(get(data, 'addresses[0].country', undefined) || 'NoCountry');
      setState(get(data, 'addresses[0].state', undefined) || 'NoState');
      setClientId(data.id);
    }
  }, [data]);

  return (
    <Edit {...props} title={syncWithLocation === undefined ? '' : ' '} actions={false} mutationMode="pessimistic">
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        style={{ width: '100%' }}
        syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header" sx={commonStyles.formTabHeader}>
          <Box sx={commonStyles.flexBox}>
            <NumberInput source="id" label="Client ID" sx={commonStyles.flexBoxItem} readOnly />
            <TextInput source="name" validate={[required()]} sx={commonStyles.flexBoxItem} />
            <TextInput source="addresses[0].phone" label="Phone" sx={commonStyles.flexBoxItem} />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="addresses[0].address" label="Address" sx={commonStyles.flexBoxLongItem} />
            <TextInput source="addresses[0].zip" label="zip" sx={commonStyles.flexBoxItem} />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <AutocompleteInput
              validate={[required()]}
              optionValue="name"
              optionText="name"
              source="addresses[0].country"
              label="Country"
              onChange={(item: any) => setCountry(item)}
              choices={countryLoaded ? countryData : []}
              sx={commonStyles.flexBoxItem}
            />
            <CustomAutoCompleteLookup
              validate={[required()]}
              lookupResource={RESOURCE_LOOKUP_STATE}
              source="addresses[0].state"
              label="State/Province"
              onChange={(item: any) => setState(item)}
              parentName={country}
              sx={commonStyles.flexBoxItem}
            />
            <CustomAutoCompleteLookup
              lookupResource={RESOURCE_LOOKUP_CITY}
              source="addresses[0].city"
              label="City/Town"
              parentName={state}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="fieldManagerCreatedName" label="Created by" disabled sx={commonStyles.flexBoxItem} />
            <TextInput source="fieldManagedUpdatedName" label="Updated by" disabled sx={commonStyles.flexBoxItem} />
          </Box>
        </LazyFormTab>
        <LazyFormTab label="Projects">
          <ProjectsTab clientId={clientId} />
        </LazyFormTab>
        <LazyFormTab label="Contacts">
          <ClientContactList clientId={clientId} />
        </LazyFormTab>

        <CommentsTab reference={RESOURCE_CLIENT_COMMENTS} />

        <LazyFormTab label="Documents">
          <DocumentsTab clientId={clientId} />
        </LazyFormTab>
        <LazyFormTab label="Industries" sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%' }}>
            <SimpleDatagrid
              resource={RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES}
              target="highlevelindustry"
              lookupResource={RESOURCE_HIGH_LEVEL_INDUSTRY}
              label="Industry"
              defaultValues={{ clientId }}
            />
          </Box>
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
